import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo, useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import gsap from 'gsap';
import { Button, Spacer } from '@nextui-org/react';
import { Slide, Zoom } from '@mui/material';
import { CallbackListener, PlayerRef } from '@remotion/player';
import NotesIcon from '@mui/icons-material/Notes';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import useFetch from '@/hooks/useFetch';

function PodcastNew({ data, contentId }: any) {
  const authContext = useContext(AuthContext);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const playerRef = useRef<PlayerRef>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [showTranscript, setShowTranscript] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isShuffling, setIsShuffling] = useState(false);

  const [podcastData, setPodcastData] = useState<any>(data.output);
  const { fetchData: downloadFetch } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/download/`,
    { method: 'POST' },
  );
  const { fetchData: shuffleFetch } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${contentId}/update`,
    { method: 'POST' },
  );

  useEffect(() => {
    if (!playerRef.current) {
      return;
    }
    const onPlay: CallbackListener<'play'> = () => {
      setIsPlaying(true);
    };
    const onPause: CallbackListener<'pause'> = () => {
      setIsPlaying(false);
    };

    playerRef.current.addEventListener('play', onPlay);
    playerRef.current.addEventListener('pause', onPause);

    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener('play', onPlay);
        playerRef.current.removeEventListener('pause', onPause);
      }
    };
  }, []);

  const buttonStyleB = showTranscript
    ? 'font-xs rounded-full text-[#faebd7] bg-[#463de1]'
    : 'font-xs rounded-full text-[#463de1] bg-white';

  function TitleCard() {
    return (
      <div className="flex gap-5 p-0 mb-5 w-full">
        <img
          alt="Main visual representation"
          src={podcastData.image}
          className="h-32 w-32 rounded-lg"
        />
        <div className="flex-col">
          <div className="text-2xl sm:text-lg font-bold">
            {podcastData.title}
          </div>
          <Spacer y={4} />
          <div className="flex gap-5 items-center">
            <div className="flex gap-1 items-start justify-left">
              <h4 className="text-small font-semibold leading-none text-default-600">
                {data.user_info?.name}
              </h4>
              <div className="mt-1 mx-3 w-2 h-2 rounded-full bg-[#c0c0c0]" />
              <h4 className="text-small font-semibold leading-none text-default-600">
                {dayjs(data.created_at).format('DD MMM YYYY')}
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleEditClick = async () => {
    navigate(`/content/${contentId}/edit`);
  };

  const handleShuffleClick = async () => {
    if (isShuffling) {
      return;
    }

    setIsShuffling(true);

    const payload = {
      action: 'shuffle_styles',
    };

    try {
      const responseData = await shuffleFetch({
        body: JSON.stringify(payload),
      });
      setPodcastData(responseData.data.output);
      setIsShuffling(false);
    } catch (err) { /* empty */ }
  };

  async function shareHandler() {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    urlObj.search = '';

    if (typeof navigator.share === 'function') {
      await navigator.share({
        title: 'Lica World',
        text: 'Check out what I created on Lica!',
        url: urlObj.toString(),
      });
    } else {
      gsap.set('.clipboard-message-control-bar', {
        opacity: 1,
      });
      gsap.to('.clipboard-message-control-bar', {
        opacity: 0,
        delay: 1,
      });
      await navigator.clipboard.writeText(urlObj.toString());
    }
  }

  function TranscriptButton() {
    function onClick() {
      setShowTranscript(!showTranscript);
    }

    return (
      <button
        className={`${buttonStyleB} flex p-2 text-xs justify-center`}
        onClick={onClick}
      >
        <NotesIcon />
        <div className="m-1">Transcript</div>
      </button>
    );
  }

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);

    let downloadURL = '';

    if (podcastData.download_links) {
      if (showTranscript) {
        downloadURL = podcastData.download_links.portrait;
      } else {
        downloadURL = podcastData.download_links.square;
      }

      try {
        const response = await fetch(downloadURL);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', 'lica-podcast.mp4');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        window.URL.revokeObjectURL(url);
      } catch (err) {
        setIsDownloading(false);
      }
    } else {
      let payload = {
        composition: 'Template',
        inputProps: podcastData.audio_data,
      };
      if (!showTranscript) {
        payload = {
          composition: 'Template',
          inputProps: podcastData.orientations.square,
        };
      }

      try {
        const response = await downloadFetch({
          body: JSON.stringify(payload),
        });
        downloadURL = response.outputFile;
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadURL;
        downloadLink.setAttribute('download', 'lica-podcast.mp4');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) { /* empty */ }
    }
    setIsDownloading(false);
  };

  function ControlBar() {
    const durationMinutes = useMemo(
      () => Math.ceil(podcastData.duration / 60),
      [podcastData.duration],
    );
    const listenText = `${durationMinutes}-Minute Listen`;

    const playPodcast = useCallback(() => {
      const player = playerRef.current;
      if (!player) return;

      if (player.isPlaying()) {
        player.pause();
      } else {
        player.play();
      }
    }, [playerRef]);

    return (
      <div className="w-full bg-[#dce3ff] justify-between rounded-lg p-1 flex gap-2 sm:gap-0 items-center">
        <div className="p-2 flex items-center sm:flex-wrap sm:flex-col-reverse sm:items-start gap-3 sm:gap-1">
          <Icon
            icon={
              isPlaying ? 'solar:pause-circle-bold' : 'solar:play-circle-bold'
            }
            className="w-10 h-10 cursor-pointer text-[#3c31c3]"
            onClick={playPodcast}
            aria-label={isPlaying ? 'Pause Podcast' : 'Play Podcast'}
          />
          <div className="p-2 font-normal sm:text-small">{listenText}</div>
        </div>
        <div className="p-2 flex gap-3 sm:flex-wrap sm:justify-end">
          <div className="flex gap-2 relative">
            <Button
              isIconOnly
              size="md"
              color="primary"
              radius="full"
              variant="flat"
              onClick={() => { shareHandler(); }}
            >
              <Icon className="!text-tertiary hover:!text-secondary" width={25} icon="material-symbols:share" />
            </Button>
            <div
              className="absolute text-xs font-medium clipboard-message-control-bar opacity-0 z-50 bg-tertiary text-primary top-12 -left-9 px-2 text-nowrap py-1 rounded-full"
            >
              Copied
              to clipboard
            </div>
            {data.user_info.email === authContext.currentUser?.email && (
              <>
                <Button
                  isLoading={isShuffling}
                  isIconOnly
                  size="md"
                  color="primary"
                  radius="full"
                  variant="flat"
                  onClick={handleShuffleClick}
                >
                  <Icon width={20} icon="ph:shuffle-bold" color="#5046E5" />
                </Button>
                <Button
                  isIconOnly
                  size="md"
                  color="primary"
                  radius="full"
                  variant="flat"
                  onClick={handleEditClick}
                >
                  <Icon width={20} icon="iconamoon:edit" color="#5046E5" />
                </Button>
              </>
            )}
          </div>
          <Button
            className="text-small"
            radius="full"
            startContent={
              !isDownloading ? (
                <Icon icon="mdi:download" className="text-base" />
              ) : null
            }
            style={{
              color: '#ffffff',
              backgroundColor: '#463de1',
            }}
            isLoading={isDownloading}
            onClick={handleDownloadClick}
          >
            Download
          </Button>
          {width > 700 && <TranscriptButton />}
        </div>
      </div>
    );
  }

  const Transcript = React.memo(({ text }: { text: string | any[] }) => {
    let localText = '';

    if (typeof text !== 'string') {
      text.forEach((item: any) => {
        const speakerName = Object.keys(item)[0]
          .split('_')
          .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(' ');

        localText += `${speakerName}: ${Object.values(item)[0]}\r\n\n`;
      });
    } else {
      localText = text;
    }

    return (
      <textarea
        value={localText}
        readOnly
        className="w-full py-4 h-auto min-h-52 bg-transparent resize-none outline-none focus:outline-none"
      />
    );
  });

  return (
    <div className="flex flex-col items-center gap-10">
      <div
        className={`max-w-[50rem] max-h-[100%] m-auto flex w-full gap-3 justify-center items-center ${(!showTranscript || width < 850) && 'flex-col-reverse'
        }`}
      >
        {showTranscript && width < 850 && (
          <Zoom in={showTranscript} mountOnEnter>
            <div className="w-full mt-6">
              <h1 className="text-xl font-semibold">Transcript</h1>
              <Transcript text={podcastData.transcript} />
            </div>
          </Zoom>
        )}
        <Slide direction="right" in mountOnEnter className="h-2/4">
          <div className="rounded-lg p-4 flex justify-center items-center flex-col">
            <div style={{ aspectRatio: 1 }} className="w-full br-5 rounded-md">
              {(
                data.user_info.email === authContext.currentUser?.email
                || !podcastData.download_links
              ) ? (
                  showTranscript ? (
                    <div className="min-w-[300px] aspect-[9/16]">
                      <RemotionPlayer ref={playerRef} data={podcastData.audio_data} />
                    </div>
                  ) : (
                    <div className="min-w-[300px] aspect-[1]">
                      <RemotionPlayer ref={playerRef} data={podcastData.orientations.square} />
                    </div>
                  )
                ) : (
                  <div className="flex items-center justify-center">
                    <video
                      className={
                      showTranscript
                        ? 'w-5/6 h-4/5'
                        : 'w-4/5 h-4/5 sm:w-full sm:h-full'
                    }
                      src={
                      showTranscript
                        ? podcastData.download_links.portrait
                        : podcastData.download_links.square
                    }
                      controls
                    />
                  </div>
                )}
            </div>
          </div>
        </Slide>
        <div className="h-2/4 rounded-lg p-4 sm:p-0 flex justify-center items-center flex-col">
          <TitleCard />
          <ControlBar />
          {showTranscript && width > 850 && (
            <Zoom in={showTranscript} mountOnEnter>
              <div className="w-full mt-6">
                <h1 className="text-xl font-semibold">Transcript</h1>
                <Transcript text={podcastData.transcript} />
              </div>
            </Zoom>
          )}
        </div>
      </div>
    </div>
  );
}

export default PodcastNew;
