import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { Spinner } from "@nextui-org/react";

import ProgressList from "../../components/ProgressList";

const GroupChild = styled.div`
    background-color: #f5f7fa;
    border-top: 1px solid #e9edf1;
    box-sizing: border-box;
    height: 56px;
`;
const AllRightsReserved = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    text-shadow: 1px 0 0 #e9edf1, 0 1px 0 #e9edf1, -1px 0 0 #e9edf1,
    0 -1px 0 #e9edf1;
`;
const RectangleParent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    font-size: 14px;
    color: #626262;
`;
const LicaWorld = styled.div`
    letter-spacing: -0.02em;
    line-height: 94px;
    font-weight: 600;
`;
const Share03Icon = styled.img`
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
`;
const Button = styled.div`
    position: relative;
    font-weight: 600;
`;
const Button1 = styled.div`
    width: 17.86%;
    top: calc(50% - 25px);
    right: 5.42%;
    left: 79.72%;
    border-radius: 1000px;
    background-color: #5046e5;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 9px 28px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
`;
const NavBar = styled.div`
    top: 0;
    left: calc(50% - 720px);
    text-align: center;
    color: #121212;
    background-color: #f5f7fa;
    border-bottom: 1px solid #e9edf1;
`;
const NavContainer = styled.div`
    width: 1278px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
`;
const WebinarParent = styled.div`
    position: absolute;
    top: 117px;
    left: calc(50% - 639px);
    width: 1278px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 137px;
    font-size: 24px;
`;
const AutoGeneratedTitle = styled.div`
    width: 810px;
    position: relative;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-weight: 500;
    display: none;
`;
const GenerativeAiAnd = styled.div`
    align-self: stretch;
    position: relative;
    font-size: 50px;
    line-height: 48px;
    font-weight: 600;
    color: #000;
`;
const AutoGeneratedTitleParent = styled.div`
    width: 810px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
`;
const FrameParent = styled.div`
    position: absolute;
    width: 1272px;
    top: 260px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 56px;
    text-align: center;
    font-size: 18px;
    color: #5046e5;
`;
const HighlightsReels = styled.div`
    width: 810px;
    position: relative;
    line-height: 125%;
    font-weight: 600;
    display: inline-block;
`;
const GroupChild1 = styled.div`
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    border-radius: 100px;
    background-color: #e9edf1;
    width: 1176px;
    height: 2px;
`;
const GroupChild2 = styled.div`
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    border-radius: 100px;
    background-color: #5046e5;
    width: 72px;
    height: 4px;
`;
const RectangleContainer = styled.div`
    width: 1176px;
    position: relative;
    height: 4px;
`;
const HighlightsReelsParent = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 18px;
`;
const HighlightReelVideo = styled.video`
    width: 376px;
    position: relative;
    height: 654px;
`;
const RectangleParent1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;
const GroupContainer = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
const SpinnerContainer = styled.div`
    width: 1176px;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
const FrameParent1 = styled.div`
    border-radius: 16px;
    background-color: #f5f7fa;
    border: 1px solid #e9edf1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 48px;
    gap: 36px;
`;
const CheckCircleIcon1 = styled.img`
    width: 24px;
    position: relative;
    height: 24px;
    display: none;
`;
const Button4 = styled.div`
    border-radius: 1000px;
    background-color: #5046e5;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 28px;
    box-sizing: border-box;
    gap: 8px;
    font-size: 18px;
    color: #fff;
`;
const FrameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 48px;
`;
const FrameChild1 = styled.img`
    flex: 1;
    position: relative;
    border-radius: 16px;
    max-width: 100%;
    overflow: hidden;
    height: 401px;
    object-fit: cover;
    mix-blend-mode: normal;
`;
const RectangleParent2 = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 36px;
`;
const FrameGroup = styled.div`
    position: absolute;
    top: 548px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 72px;
`;
const LightVersionUpdateRoot = styled.div`
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 5150px;
    overflow: hidden;
    text-align: left;
    font-size: 28px;
    color: #000;
    font-family: "Inter";
`;
const Subtitle = styled.div`
    font-size: 16px;
    text-align: left;
    color: rgb(51, 51, 51);
    font-style: italic;
`;

const Demo6: FunctionComponent = () => {
	const [reels, setReels] = useState(false);
	const [cards, setCards] = useState(false);
	const [content, setContent] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setReels(true);
		}, 24000);
		setTimeout(() => {
			setCards(true);
		}, 20000);
		setTimeout(() => {
			setContent(true);
		}, 10000);
	}, []);

	return (
		<LightVersionUpdateRoot>
			<RectangleParent>
				<GroupChild />
				<AllRightsReserved>
					©2024, All rights reserved by Lica World
				</AllRightsReserved>
			</RectangleParent>
			<NavBar>
				<NavContainer>
					<LicaWorld>Lica World</LicaWorld>
					<Button1>
						<Share03Icon alt="" src="/share-1.svg" />
						<Button>Share this project</Button>
					</Button1>
				</NavContainer>
			</NavBar>
			<WebinarParent>
				<img style={{ margin: "auto", height: "72px" }} src="https://framerusercontent.com/images/M8x601RrDXJKphU6kiKjdZvpCo.png" />
			</WebinarParent>
			<FrameParent>
				<AutoGeneratedTitleParent>
					<AutoGeneratedTitle>Auto Generated Title</AutoGeneratedTitle>
					<GenerativeAiAnd>
						Multimodal Machine Learning
					</GenerativeAiAnd>
					<Subtitle>This course by Purvanshi Mehta, an applied scientist at Microsoft, teaches multimodal machine
						learning, combining image and text data for applications like classification and image generation. It's
						ideal for ML practitioners familiar with PyTorch and deep learning, looking to advance in processing
						multiple data types and exploring state-of-the-art models.</Subtitle>
				</AutoGeneratedTitleParent>
			</FrameParent>
			<FrameGroup>
				<FrameContainer>
					<FrameParent1>
						<HighlightsReelsParent>
							<HighlightsReels>Highlights Reels</HighlightsReels>
							<RectangleContainer>
								<GroupChild1 />
								<GroupChild2 />
							</RectangleContainer>
						</HighlightsReelsParent>
						{reels ? (
							<GroupContainer>
								<HighlightReelVideo
									src="https://framerusercontent.com/assets/kGkV2X9KKsg4OVCRnn42x1xYrs.mp4#t=1"
									controls
								/>
								<RectangleParent1>
									<HighlightReelVideo
										src="https://framerusercontent.com/assets/qAZC6cCaillN9rPPDygr6hQ3iyw.mp4#t=1"
										controls
									/>
								</RectangleParent1>
								<RectangleParent1>
									<HighlightReelVideo
										src="https://framerusercontent.com/assets/k2ERgW0sYdYEnlfz3JV0VmuHPM.mp4#t=1"
										controls
									/>
								</RectangleParent1>
							</GroupContainer>
						) : (
							<SpinnerContainer>
								<ProgressList
									items={[
										"Watching the webinar",
										"Generating the transcript",
										"Choosing engaging clips",
										"Editing for clarity and impact",
										"Adding titles and captions",
										"Using transitions for smooth flow",
										"Designing custom thumbnails",
										"Exporting to mp4",
									]}
									onFinish={() => { }}
								/>
								{/*<Spinner label="Generating highlights..." size="lg" color="primary"/>*/}
							</SpinnerContainer>
						)}
					</FrameParent1>
					<Button4>
						<CheckCircleIcon1 alt="" src="/checkcircle.svg" />
						<Button>Download this report</Button>
						<Share03Icon alt="" src="/download-icon.svg" />
					</Button4>
				</FrameContainer>
				<FrameParent1>
					<HighlightsReelsParent>
						<HighlightsReels>Highlighted Cards</HighlightsReels>
						<RectangleContainer>
							<GroupChild1 />
							<GroupChild2 />
						</RectangleContainer>
					</HighlightsReelsParent>
					{cards ? (
						<RectangleParent2>
							<FrameChild1 alt="" src="/hc-1.png" />
							<FrameChild1 alt="" src="/hc-2.png" />
							<FrameChild1 alt="" src="/hc-3.png" />
						</RectangleParent2>
					) : (
						<SpinnerContainer>
							<ProgressList
								items={[
									"Identifying key",
									"Composing outline",
									"Extracting quotes from summarization",
									"Setting up the canvas and layout",
									"Adding the right imagery and fill",
									"Incorporating images and icons",
									"Ensuring readability",
									"Exporting to JPEG ",
								]}
								onFinish={() => { }}
							/>
						</SpinnerContainer>
					)}
				</FrameParent1>
				<FrameParent1>
					<HighlightsReelsParent>
						<HighlightsReels>Section Highlights</HighlightsReels>
						<RectangleContainer>
							<GroupChild1 />
							<GroupChild2 />
						</RectangleContainer>
					</HighlightsReelsParent>
					{content ? (
						<RectangleParent2>
							<GroupContainer style={{ justifyContent: "center" }}>
								<HighlightReelVideo
									src="https://framerusercontent.com/assets/yiMBh0QLKTO970WWQiqKZ4SMEI.mp4"
									controls
								/>
							</GroupContainer>
						</RectangleParent2>
					) : (
						<SpinnerContainer className="text-secondary flex flex-col gap-5">
							<Spinner size="lg" color="secondary" />
							Generating Content...
						</SpinnerContainer>
					)}
				</FrameParent1>
				<FrameParent1>
					<HighlightsReelsParent>
						<HighlightsReels>Multimodal Machine Learning -
							Insights and Innovations</HighlightsReels>
						<RectangleContainer>
							<GroupChild1 />
							<GroupChild2 />
						</RectangleContainer>
					</HighlightsReelsParent>
					{content ? (
						<div style={{ width: "1176px" }}>
							<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
								<img
									src="https://framerusercontent.com/images/qMLYmwY6yHVSQLeLCC82XAbvOHE.png?scale-down-to=1024"
									style={{ width: "512px", height: "204px", objectFit: "cover" }}
								/>
								<div style={{ fontWeight: 600, marginTop: "20px" }}>Bridging Human Sensory Experience with AI</div>
								<div style={{ fontSize: "16px", marginTop: "8px" }}>The advent of multimodal machine learning stands as a
									testament to the
									progress we&apos;ve made in the quest to imbue machines with a semblance of human sensory experience. By
									harmonizing disparate data streams — from the visual splendor captured in images to the intricate
									patterns found in textual data — this emerging field promises a revolution in how artificial
									intelligence perceives and interacts with the world around it. At the heart of this transformative
									journey is the pursuit to endow AI with a multidimensional understanding, akin to our own multifaceted
									sensory processing.
								</div>
							</div>
							<br />
							<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
								<img
									style={{ width: "512px", height: "204px", objectFit: "cover" }}
									src="https://framerusercontent.com/images/BWYW7NCHGr8q20vMSBiH4tOzHE.png?scale-down-to=1024"
								/>
								<div style={{ fontWeight: 600, marginTop: "20px" }}>The Evolution of AI Sensory Processing: From Single to
									Multiple Modalities
								</div>
								<div style={{ fontSize: "16px", marginTop: "8px" }}>Our exploration begins with an understanding of how AI
									has evolved from processing single modalities to handling complex, multimodal inputs. Traditional
									models, designed with a focus on specific types of data, are making way for more versatile
									architectures capable of digesting a rich array of inputs — be it the visual context provided by
									images, the nuances carried by text, or the dynamics encapsulated in video data. This shift toward a
									holistic data integration strategy mirrors the human ability to synthesize information from sight,
									sound, and touch to form a coherent understanding of our environment.
								</div>
							</div>
							<br />
							<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
								<img
									style={{ width: "512px", height: "204px", objectFit: "cover" }}
									src="https://framerusercontent.com/images/HevvbkoKUFFawT3paxMb89cM5r4.png?scale-down-to=1024"
								/>
								<div style={{ fontWeight: 600, marginTop: "20px" }}>Innovations Leading the Charge: Perceiver Models and
									Beyond
								</div>
								<div style={{ fontSize: "16px", marginTop: "8px" }}>Central to the advancements in multimodal learning are
									groundbreaking models like Perceiver and its successor, Perceiver IO. These models challenge the
									conventional norms by demonstrating an astonishing flexibility in handling diverse data types. By
									leveraging Transformer architecture, they process and integrate inputs across modalities, setting new
									benchmarks for AI&apos;s interpretative and generative capabilities. Whether it's generating vivid images
									from textual descriptions or classifying human actions in videos, these models encapsulate the essence
									of multimodal learning&apos;s potential to redefine the boundaries of machine intelligence.
								</div>
							</div>
							<br />
							<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
								<img
									style={{ width: "512px", height: "204px", objectFit: "cover" }}
									src="https://framerusercontent.com/images/iEWLBKnFHiWQyXg1YzcoVhhJS5I.png?scale-down-to=1024"
								/>
								<div style={{ fontWeight: 600, marginTop: "20px" }}>Practical Applications: Bridging Theory and Reality
								</div>
								<div style={{ fontSize: "16px", marginTop: "8px" }}>The practical implications of these innovations are
									profound, extending far beyond the theoretical. From enhancing content discovery on platforms like
									YouTube to revolutionizing social media interactions through automated caption generation for the
									visually impaired, the applications of multimodal machine learning are as diverse as they are
									impactful. Moreover, the advent of tools like DALL-E and CLIP heralds a new era in AI, where the
									creation and classification of content become more intuitive, opening up new avenues for creative
									expression and accessibility.
								</div>
							</div>
						</div>
					) : (
						<SpinnerContainer>
							<Spinner label="Generating summary..." size="lg" color="primary" />
						</SpinnerContainer>
					)}
				</FrameParent1>
			</FrameGroup>
		</LightVersionUpdateRoot>
	);
};

export default Demo6;
