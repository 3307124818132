import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Spacer } from '@nextui-org/react';
import { Slide } from '@mui/material';
import { CallbackListener, PlayerRef } from '@remotion/player';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';

import RemotionPlayer from '../../../../remotion/RemotionPlayer';
import useFetch from '../../../../hooks/useFetch';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { isHyperlink } from '../../../../utils/common';

export function EditPodcastNew({ data, podcastId, responseData }: any) {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const playerRef = useRef<PlayerRef>(null);

  const [podcastData, setPodcastData] = useState<any>(data);
  const [showTranscript] = useState(true);
  const [openEditImageModal, setOpenEditImageModal] = useState(false);
  const [editImageItem, setEditImageItem] = useState<any>();
  const [titleComponent, setTitleComponent] = useState<any>(podcastData.title);
  const [imageComponent, setImageComponent] = useState<any>(podcastData.image);
  const [transcriptComponent, setTranscriptComponent] = useState<any>(podcastData.transcript);
  const [textHeights, setTextHeights] = useState<number[]>([]);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { fetchData: saveData } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${podcastId}/update`,
    { method: 'POST' },
  );
  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${podcastId}/`,
    { method: 'GET' },
  );
  const { fetchData: downloadImage } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/images/`,
    { method: 'POST' },
  );

  const onSetTitleChange = ((event: any) => {
    setTitleComponent(event.target.value);
  });

  const toggleModal = () => setOpenEditImageModal(!openEditImageModal);

  const onOpenEditImage = (src: string | undefined): void => {
    setOpenEditImageModal(true);
    setEditImageItem({ src });
  };

  const onSaveEditImage = async () => {
    setOpenEditImageModal(false);
    try {
      const downloadResponse = await downloadImage({ body: JSON.stringify(editImageItem.src) });
      setImageComponent(downloadResponse.data.url);
    } catch (err) {
      console.log(err);
      setImageComponent(editImageItem.src);
    }
    setEditImageItem({});
  };

  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    index: number | null = null,
    key: string | null = null,
  ) => {
    if (index !== null && key !== null) {
      const newTranscript = [...podcastData.transcript];
      newTranscript[index][key] = e.target.value;
      setTranscriptComponent(newTranscript);
    } else {
      setTranscriptComponent(e.target.value);
    }

    // Calculate the new height
    const newHeight = e.target.scrollHeight;
    setTextHeights((prevHeights) => {
      const updatedHeights = [...prevHeights];
      if (index !== null) {
        updatedHeights[index] = newHeight;
      }
      return updatedHeights;
    });
  };

  const textAreasRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  useEffect(() => {
    const newHeights = textAreasRefs.current.map(
      (textarea) => (textarea ? textarea.scrollHeight : 0),
    );
    setTextHeights(newHeights);
  }, [podcastData.transcript]);

  const fetchContent = async () => {
    try {
      const contentResponseData = await getContent();

      if (contentResponseData?.data?.update_status === 'completed') {
        setPodcastData(contentResponseData.data.output);
        setIsProcessing(false);
      } else if (contentResponseData?.data?.update_status === 'failed' || !contentResponseData?.data?.update_status) {
        setIsProcessing(false);
      } else if (['initiated', 'processing'].includes(contentResponseData?.data?.update_status)) {
        setTimeout(() => {
          fetchContent();
        }, 10000);
      }
    } catch (err) { /* empty */ }
  };

  useEffect(() => {
    if (['initiated', 'processing'].includes(responseData?.update_status)) {
      setIsProcessing(true);

      setTimeout(() => {
        fetchContent();
      }, 5000);
    }

    if (!playerRef.current) return;

    const onPlay: CallbackListener<'play'> = () => {
      setIsPlaying(true);
    };
    const onPause: CallbackListener<'pause'> = () => {
      setIsPlaying(false);
    };

    playerRef.current.addEventListener('play', onPlay);
    playerRef.current.addEventListener('pause', onPause);

    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener('play', onPlay);
        playerRef.current.removeEventListener('pause', onPause);
      }
    };
  }, []);

  const isSaveButtonEnabled = () => {
    if (imageComponent !== podcastData.image) {
      return true;
    }

    if (titleComponent !== podcastData.title) {
      return true;
    }

    return transcriptComponent !== podcastData.transcript;
  };

  const handleSaveClick = async () => {
    if (isProcessing) return;

    setIsProcessing(true);

    const actions = [];
    const actionPayload = {
      id: 'update_content',
      attributes: {},
    };

    if (imageComponent !== podcastData.image) {
      Object.assign(actionPayload.attributes, { image: imageComponent });
    }

    if (titleComponent !== podcastData.title) {
      Object.assign(actionPayload.attributes, { title: titleComponent });
    }

    if (transcriptComponent !== podcastData.transcript) {
      Object.assign(actionPayload.attributes, { transcript: transcriptComponent });
    }

    actions.push(actionPayload);

    try {
      const saveResponse = await saveData({
        body: JSON.stringify({ actions }),
      });

      if (['initiated', 'processing'].includes(saveResponse.data.update_status)) {
        await fetchContent();
      } else {
        setIsProcessing(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadClick = async () => {
    if (isDownloading) return;

    setIsDownloading(true);

    const payload = {
      composition: 'Template',
      inputProps: podcastData.audio_data,
    };

    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/download/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();

      const downloadLink = document.createElement('a');
      downloadLink.href = result.outputFile;
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      setIsDownloading(false);
    } catch (err) { /* empty */ }
  };

  function ControlBar() {
    const durationMinutes = useMemo(
      () => Math.ceil(podcastData.duration / 60),
      [podcastData.duration],
    );
    const listenText = `${durationMinutes}-Minute Listen`;

    const playPodcast = useCallback(() => {
      const player = playerRef.current;
      if (!player) return;

      if (player.isPlaying()) {
        player.pause();
      } else {
        player.play();
      }
    }, []);

    return (
      <div
        className="xsm:w-80 mb-2 w-full border border-secondary border-opacity-40 justify-between rounded-full sm:px-7 px-2 py-1 flex gap-2 sm:gap-0 items-center"
      >
        <div className="p-2 flex items-center xsm:flex-wrap xsm:flex-col-reverse xsm:items-start gap-3 sm:gap-1">
          <Icon
            icon={isPlaying ? 'solar:pause-circle-bold' : 'solar:play-circle-bold'}
            className="w-10 h-10 cursor-pointer text-tertiary"
            onClick={playPodcast}
            aria-label={isPlaying ? 'Pause Podcast' : 'Play Podcast'}
          />
          <div className="p-2 font-normal sm:text-small">{listenText}</div>
        </div>
        <div className="p-2 flex gap-3 xsm:flex-wrap xsm:justify-end">
          <Button
            className="text-small bg-tertiary text-primary"
            radius="full"
            startContent={!isDownloading ? <Icon icon="mdi:download" className="text-base" /> : null}
            isLoading={isDownloading}
            onClick={handleDownloadClick}
          >
            Download
          </Button>
          <Button
            radius="full"
            size="md"
            className="bg-tertiary text-primary"
            onClick={handleSaveClick}
            startContent={isProcessing ? null
              : <Icon icon="material-symbols:check-circle-outline" style={{ fontSize: '1.5rem' }} />}
            isLoading={isProcessing}
            isDisabled={!isSaveButtonEnabled() || isProcessing}
          >
            {isProcessing ? 'Updating' : 'Save'}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 sm:p-4 h-or-w-sm:h-full h-[calc(100vh-128px)]">
      <div className="bg-white flex flex-col justify-center items-center p-8 sm:p-4 rounded-2xl" style={{ minHeight: 'inherit' }}>
        <div
          className={`max-w-[50rem] m-auto flex w-full gap-3 justify-center items-center ${(!showTranscript || width < 850) && 'flex-col-reverse'}`}
        >
          {width < 850 && (
          <div className="w-full mt-5 flex flex-col gap-5">
            <h1 className="text-xl font-semibold">Transcript</h1>
            {Array.isArray(podcastData.transcript)
              ? (
                <div className="flex flex-col h-60 gap-5 overflow-y-auto">
                  {podcastData.transcript.map((text: any, index: any) => (
                    Object.entries(text).map(([key, value]) => {
                      let speaker = '';
                      const speakerName = key.split('_')
                        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
                        .join(' ');
                      speaker += `${speakerName}: `;

                      return (
                        <div
                          key={`${index}-${key}`}
                        >
                          <p className="text-small font-semibold mb-1">{speaker}</p>
                          <textarea
                            value={`${value as string}`}
                            className="w-full border border-secondary px-3 rounded-[2rem] border-opacity-20 text-secondary resize-none outline-none focus:outline-none bg-transparent overflow-hidden"
                            onChange={(e) => handleTextChange(e, index, key)}
                            ref={(el) => { textAreasRefs.current[index] = el; }}
                            style={{ minHeight: `${textHeights[index]}px`, background: 'transparent' }}
                          />
                        </div>
                      );
                    })
                  ))}
                </div>
              )
              : (
                <textarea
                  value={transcriptComponent}
                  className="w-full py-4 h-auto border border-secondary px-3 rounded-[2rem] border-opacity-20 text-secondary min-h-52 resize-none outline-none focus:outline-none"
                  onChange={(e) => handleTextChange(e)}
                  style={{ background: 'transparent' }}
                />
              )}
          </div>
          )}
          <Slide direction="right" in mountOnEnter>
            <div className="rounded-lg flex justify-center items-center flex-col">
              <div
                style={{ aspectRatio: 1 }}
                className="w-full br-5 rounded-md"
              >
                <div className="min-w-[300px] aspect-[9/16]">
                  <RemotionPlayer ref={playerRef} data={podcastData.audio_data} />
                </div>
              </div>
            </div>
          </Slide>
          <div className="rounded-lg p-4 sm:p-0 flex justify-center items-center flex-col">
            <div className="flex gap-5 p-0 mb-5 xsm:w-80 w-full">
              {openEditImageModal && (
                <div
                  className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center animate-fadeIn z-20 rounded-xl"
                  onClick={toggleModal}
                >
                  <div
                    className="bg-secondary p-5 rounded-lg animate-slideIn w-4/5 max-w-xs"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Input
                      type="text"
                      radius="sm"
                      label="Media URL"
                      placeholder="https://media.com/image"
                      labelPlacement="outside"
                      startContent={<Icon icon="ph:link-bold" />}
                      value={editImageItem.src}
                      isInvalid={!isHyperlink(editImageItem.src)}
                      onValueChange={(value) => setEditImageItem({ ...editImageItem, src: value })}
                    />
                    <div className="w-full mt-1 flex justify-end items-center gap-1">
                      <Button
                        radius="sm"
                        size="md"
                        color="primary"
                        className="text-primary-color"
                        variant="light"
                        onClick={toggleModal}
                      >
                        Cancel
                      </Button>
                      <Button radius="full" size="md" color="primary" className="text-white" onClick={onSaveEditImage}>
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="relative flex gap-2 overflow-hidden">
                <div className="h-32 w-32 relative">
                  <img className="h-32 w-32 rounded-lg object-cover" src={imageComponent} />
                  <div
                    onClick={() => onOpenEditImage(imageComponent)}
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 md:p-4 rounded cursor-pointer hover:opacity-100 opacity-0 transition-opacity duration-300 w-full h-full flex justify-center items-center"
                  >
                    <Button
                      onClick={() => onOpenEditImage(imageComponent)}
                      size="sm"
                      color="primary"
                      className="text-primary"
                      isIconOnly
                      style={{
                        borderRadius: '50%',
                        padding: '0',
                        background: 'white',
                      }}
                    >
                      <Icon icon="iconamoon:edit" className="text-primary" style={{ fontSize: '1rem' }} />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex-col">
                <div className="text-2xl sm:text-lg font-bold">
                  <textarea
                    value={titleComponent}
                    className="resize-none outline-none focus:outline-none"
                    onChange={onSetTitleChange}
                    style={{ background: 'transparent' }}
                  />
                </div>
                <Spacer y={3} />
                <div className="flex gap-5 items-center">
                  <div className="flex items-start justify-left">
                    <h4 className="text-small font-semibold leading-none text-secondary opacity-70">
                      {responseData.user_info?.name}
                    </h4>
                    <div className="mt-1 mx-3 w-2 h-2 rounded-full bg-[#c0c0c0]" />
                    <h4 className="text-small font-semibold leading-none text-secondary opacity-70">
                      {dayjs(data.created_at).format('DD MMM YYYY')}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <ControlBar />
            {width > 850 && (
            <div className="w-full mt-5 flex flex-col gap-5">
              <h1 className="text-xl font-semibold">Transcript</h1>
              {Array.isArray(podcastData.transcript)
                ? (
                  <div className="flex flex-col h-60 gap-5 overflow-y-auto">
                    {podcastData.transcript.map((text: any, index: any) => (
                      Object.entries(text).map(([key, value]) => {
                        let speaker = '';
                        const speakerName = key.split('_')
                          .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
                          .join(' ');
                        speaker += `${speakerName}: `;

                        return (
                          <div
                            key={`${index}-${key}`}
                          >
                            <p className="text-small font-semibold mb-1">{speaker}</p>
                            <textarea
                              key={`${index}-${key}`}
                              value={`${value as string}`}
                              className="w-full border border-secondary border-opacity-20 p-3 rounded-[2rem] resize-none outline-none focus:outline-none bg-transparent overflow-hidden"
                              onChange={(e) => handleTextChange(e, index, key)}
                              ref={(el) => (textAreasRefs.current[index] = el)}
                              style={{ minHeight: `${textHeights[index]}px`, background: 'transparent' }}
                            />
                          </div>
                        );
                      })
                    ))}
                  </div>
                )
                : (
                  <textarea
                    value={transcriptComponent}
                    className="w-full border border-secondary border-opacity-20 px-3 rounded-[2rem] py-4 h-auto min-h-52 resize-none outline-none focus:outline-none"
                    onChange={(e) => handleTextChange(e)}
                    style={{ background: 'transparent' }}
                  />
                )}
            </div>
            )}
          </div>
        </div>
        <Button
          className="text-small mt-5 !bg-tertiary !text-primary"
          radius="full"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
    </div>
  );
}

export default EditPodcastNew;
