import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from '@nextui-org/react';

const GroupChild = styled.div`
    background-color: #f5f7fa;
    border-top: 1px solid #e9edf1;
    box-sizing: border-box;
    height: 56px;
`;
const AllRightsReserved = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    text-shadow: 1px 0 0 #e9edf1, 0 1px 0 #e9edf1, -1px 0 0 #e9edf1,
    0 -1px 0 #e9edf1;
`;
const RectangleParent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    font-size: 14px;
    color: #626262;
`;
const LicaWorld = styled.div`
    letter-spacing: -0.02em;
    line-height: 94px;
    font-weight: 600;
`;
const Share03Icon = styled.img`
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
`;
const Button2 = styled.div`
    position: relative;
    font-weight: 600;
`;
const Button1 = styled.div`
    width: 10.86%;
    top: calc(50% - 25px);
    right: 5.42%;
    left: 79.72%;
    border-radius: 1000px;
    background-color: #04a0f4;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 9px 28px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
`;
const NavBar = styled.div`
    top: 0;
    left: calc(50% - 720px);
    text-align: center;
    color: #121212;
    background-color: #f5f7fa;
    border-bottom: 1px solid #e9edf1;
`;
const NavContainer = styled.div`
    width: 1278px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
`;
const WebinarParent = styled.div`
    position: absolute;
    left: calc(50% - 639px);
    width: 1278px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 137px;
    font-size: 24px;
`;
const AutoGeneratedTitle = styled.div`
    width: 810px;
    position: relative;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-weight: 500;
    display: none;
`;
const GenerativeAiAnd = styled.div`
    align-self: stretch;
    position: relative;
    font-size: 35px;
    line-height: 48px;
    font-weight: 600;
    color: #000;
`;
const AutoGeneratedTitleParent = styled.div`
    width: 810px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
`;
const FrameParent = styled.div`
    position: absolute;
    width: 1272px;
    top: 320px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 56px;
    text-align: center;
    font-size: 18px;
    color: #5046e5;
`;
const HighlightsReels = styled.div`
    width: 810px;
    position: relative;
    line-height: 125%;
    font-weight: 600;
    display: inline-block;
`;
const GroupChild1 = styled.div`
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    border-radius: 100px;
    background-color: #e9edf1;
    width: 1176px;
    height: 2px;
`;
const GroupChild2 = styled.div`
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    border-radius: 100px;
    background-color: #04a0f4;
    width: 72px;
    height: 4px;
`;
const RectangleContainer = styled.div`
    width: 1176px;
    position: relative;
    height: 4px;
`;
const HighlightsReelsParent = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 18px;
`;
const HighlightReelVideo = styled.video`
    width: 376px;
    position: relative;
    height: 654px;
`;
const HighlightReelVideoLandscape = styled.video`
    width: 640px;
    position: relative;
    height: 360px;
`;
const RectangleParent1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;
const GroupContainer = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;
const SpinnerContainer = styled.div`
    width: 1176px;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
const FrameParent1 = styled.div`
    border-radius: 16px;
    background-color: #f5f7fa;
    border: 1px solid #e9edf1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 48px;
    gap: 36px;
`;
const FrameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 48px;
`;
const RectangleParent2 = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 36px;
`;
const FrameGroup = styled.div`
    position: absolute;
    top: 240px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 72px;
`;

const FrameGroup1 = styled.div`
    position: absolute;
    top: 1090px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 72px;
`;

const FrameGroup2 = styled.div`
    position: absolute;
    top: 1940px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 72px;
`;

const FrameChild1 = styled.img`
    flex: 1;
    position: relative;
    border-radius: 16px;
    max-width: 100%;
    overflow: hidden;
    height: 601px;
    object-fit: cover;
    mix-blend-mode: normal;
`;
const LightVersionUpdateRoot = styled.div`
    width: 100%;
    position: relative;
    height: 2900px;
    overflow: hidden;
    text-align: left;
    font-size: 28px;
    color: #000;
    font-family: "Inter";
    background: url("https://cdn.prod.website-files.com/65d79b481b4c5692f91141de/65d79b481b4c5692f91141f8_hero-background.svg");
    background-size: cover;
`;
const Subtitle = styled.div`
    font-size: 16px;
    text-align: left;
    color: rgb(51, 51, 51);
    font-style: italic;
`;

const Demo11: FunctionComponent = () => {
  const [reels, setReels] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setReels(true);
    }, 1000);
  }, []);

  return (
    <LightVersionUpdateRoot>
      <RectangleParent>
        <GroupChild />
        <AllRightsReserved>
          ©2024, All rights reserved by Lica World
        </AllRightsReserved>
      </RectangleParent>
      <NavBar>
        <NavContainer>
          <LicaWorld>Lica World</LicaWorld>
          <Button1>
            <Share03Icon alt="" src="/share-1.svg" />
            <Button2>Share</Button2>
          </Button1>
        </NavContainer>
      </NavBar>
      <WebinarParent>
        <img style={{ margin: 'auto', height: '100px', marginTop: '20px' }} src="https://storage.googleapis.com/lica-assets/deepscribe-logo.png" />
      </WebinarParent>
      <FrameGroup>
        <FrameContainer>
          <FrameParent1>
            <HighlightsReelsParent>
              <HighlightsReels>
                DeepScribe Deepens Integration with Epic
              </HighlightsReels>
              <RectangleContainer>
                <GroupChild1 />
                <GroupChild2 />
              </RectangleContainer>
            </HighlightsReelsParent>
            {reels ? (
              <div>
                <GroupContainer>
                  <HighlightReelVideoLandscape
                    src="https://storage.googleapis.com/lica-video/DeepScribe_Epic.mp4"
                    controls
                  />
                </GroupContainer>
                <br />
                <textarea
                  className=" min-h-[150px] text-lg w-[1176px]"
                  style={{
                    background: 'transparent', resize: 'none', border: 'none', outline: 'none',
                  }}
                >
                  We're excited to announce key updates to our integration with Epic, featuring our new Customization Studio, which allows for custom models tailored to unique specialties. Developed with Covenant HealthCare, this integration enables personalized documentation directly into patient charts, reducing after-hours work by up to 75%. "Our enhanced integration streamlines workflows and improves the clinician-patient experience," said Frank Fear, CIO of Covenant HealthCare. We've laid the groundwork for future ambient solutions by adopting Epic's SmartData standard API, enhancing our Customization Studio for a seamless user experience.
                </textarea>
              </div>
            ) : (
              <SpinnerContainer>
                <Spinner label="Loading Video" size="lg" color="primary" />
              </SpinnerContainer>
            )}
          </FrameParent1>
        </FrameContainer>
      </FrameGroup>
      <FrameGroup1>
        <FrameContainer>
          <FrameParent1>
            <HighlightsReelsParent>
              <HighlightsReels>
                DeepScribe Unveils ‘Real-Time’ Feature at HIMSS!
              </HighlightsReels>
              <RectangleContainer>
                <GroupChild1 />
                <GroupChild2 />
              </RectangleContainer>
            </HighlightsReelsParent>
            {reels ? (
              <div>
                <GroupContainer>
                  <HighlightReelVideoLandscape
                    src="https://storage.googleapis.com/lica-video/deepscribe_HIMMS.mp4"
                    controls
                  />
                </GroupContainer>
                <br />
                <textarea
                  className=" min-h-[150px] text-lg w-[1176px]"
                  style={{
                    background: 'transparent', resize: 'none', border: 'none', outline: 'none',
                  }}
                >
                  We're thrilled to introduce “Real-Time,” our groundbreaking feature that generates AI-powered clinical notes as patient visits unfold. This allows clinicians to clarify details directly in their EHR during the encounter, significantly enhancing workflow efficiency. "Real-Time is the biggest breakthrough in ambient clinical documentation since large language models," said Akilesh Bapu, CEO and Co-Founder of DeepScribe. With our HealAI model, trained on over 3 million medical conversations, notes are generated instantaneously with zero latency. Visit us at the HIMSS 2024 Global Conference to see how Real-Time is revolutionizing healthcare documentation!
                </textarea>
              </div>
            ) : (
              <SpinnerContainer>
                <Spinner label="Loading Video" size="lg" color="primary" />
              </SpinnerContainer>
            )}
          </FrameParent1>
        </FrameContainer>
      </FrameGroup1>
      <FrameGroup2>
        <FrameContainer>
          <FrameParent1>
            <HighlightsReelsParent>
              <HighlightsReels style={{ width: '100%' }}>
                DeepScribe Adds Customization Studio to Fully Automated AI Scribe!
              </HighlightsReels>
              <RectangleContainer>
                <GroupChild1 />
                <GroupChild2 />
              </RectangleContainer>
            </HighlightsReelsParent>
            {reels ? (
              <div>
                <GroupContainer>
                  <HighlightReelVideoLandscape
                    src="https://storage.googleapis.com/lica-video/deepscribe_Customization_Studio.mp4"
                    controls
                  />
                </GroupContainer>
                <br />
                <textarea
                  className=" min-h-[150px] text-lg w-[1176px]"
                  style={{
                    background: 'transparent', resize: 'none', border: 'none', outline: 'none',
                  }}
                >
                  At DeepScribe, we are introducing the Customization Studio, a suite of tools allowing clinicians to tailor the AI scribe to their specialized workflows. The fully automated AI Scribe, outperforming GPT-4 by over 32% in medical documentation accuracy, captures natural conversations and converts them into customizable documentation within seconds. "Customization Studio, along with human-level accuracy, is key to building the first enterprise-grade AI scribe," said CEO Akilesh Bapu. This tool empowers healthcare systems to standardize workflows, ensure compliance, and accelerate adoption across multiple specialties. Request a demo at deepscribe.ai/contact!
                </textarea>
              </div>
            ) : (
              <SpinnerContainer>
                <Spinner label="Loading Video" size="lg" color="primary" />
              </SpinnerContainer>
            )}
          </FrameParent1>
        </FrameContainer>
      </FrameGroup2>
    </LightVersionUpdateRoot>
  );
};

export default Demo11;
