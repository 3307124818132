import React from 'react';
import { Audio } from 'remotion';
import { useAudioData } from '@remotion/media-utils';

import {
  AudioViz as AudioComp, Image, PaginatedSubtitles, Video,
} from './comps';

interface InputProps {
  src?: string;
  srt_file?: string;
  authorName?: string;
  bg_image?: string;
  bg_video?: string;
  music?: any;
  styles?: any;
  width?: any;
  height?: any;
}

function AudioViz({
  src, srt_file, bg_image, bg_video, authorName, music, styles, width, height,
}: InputProps) {
  const audioData = useAudioData(src || '');
  const currentStyles = {
    subtitle_color: styles ? styles.subtitle_color : '#FFFFFF',
    active_subtitle_color: styles ? styles.active_subtitle_color : '#C4C0F7',
    wave_color: styles ? styles.wave_color : '#BFA8F280',
    background_color: styles ? styles.background_color : 'linear-gradient(154deg, #6D41FF -12.95%, #A679FF 63.49%)',
    author_color: styles ? styles.author_color : '#FFFFFF',
  };
  if (!audioData) {
    return null;
  }

  const isPortraitMode = width != height;

  return (
    <div className="relative w-full h-full">
      {bg_image && (
        <div className="absolute  w-full h-full opacity-60 z-10">
          <Image src={bg_image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      )}
      {bg_video && (
        <div className="absolute w-full h-full opacity-60 z-10">
          <Video loop muted src={bg_video} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      )}
      <div
        className={`w-full h-full flex items-center relative z-30 flex-col justify-between ${isPortraitMode ? 'pb-10' : 'pb-4'}`}
        style={bg_image || bg_video ? {} : { background: currentStyles.background_color }}
      >
        <AudioComp
          audioSrc={src!}
          style={{ flex: 1 }}
          waveColor={currentStyles.wave_color}
          freqRangeStartIndex={4}
          waveLinesToDisplay={29}
          numberOfSamples={256}
          mirrorWave
        />
        <div className="flex gap-4 px-4">
          {music ? <Audio loop src={music.src} volume={music.volume} /> : null}
          <div className="flex-auto pb-5">
            <PaginatedSubtitles
              subtitlesFileName={srt_file || ''}
              linesPerPage={4}
              subtitlesTextColor={currentStyles.subtitle_color}
              subtitlesBackgroundColor={currentStyles.background_color}
              subtitlesActiveTextColor={currentStyles.active_subtitle_color}
              subtitlesZoomMeasurerSize={1}
              subtitlesLineHeight={25}
              audioOffsetInSeconds={0}
              style={{
                fontWeight: 600,
                fontSize: '20px',
                textAlign: 'center',
              }}
            />
          </div>
        </div>
        <div
          style={{
            backgroundColor: currentStyles.author_color,
          }}
          className="w-full h-0.5"
        />
        <h1
          style={{
            fontFamily: 'Geist Regular',
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: '600',
            color: currentStyles.author_color,
            backgroundColor: currentStyles.background_color,
          }}
          className="p-2 rounded-full w-fit my-2 mx-2"
        >
          {authorName || 'Lica'}
        </h1>
      </div>
    </div>

  );
}

export default AudioViz;
