import React from 'react';
import { Easing, interpolate } from 'remotion';

export const Word: React.FC<{
  item: any;
  frame: number;
  transcriptionColor: string;
  isActive: boolean;
}> = ({
  item, frame, transcriptionColor, isActive,
}) => {
  const opacity = interpolate(frame, [item.start, item.start + 15], [0, 1], {
    extrapolateLeft: 'clamp',
    extrapolateRight: 'clamp',
  });

  const translateY = interpolate(
    frame,
    [item.start, item.start + 10],
    [0.25, 0],
    {
      easing: Easing.out(Easing.quad),
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp',
    },
  );

  return (
    <span
      style={{
        display: 'inline-block',
        opacity: Number.isNaN(opacity) ? 1 : opacity,
        translate: `0 ${translateY}em`,
        color: transcriptionColor,
        fontWeight: isActive ? 600 : 400,
      }}
    >
      {item.text}
    </span>
  );
};
