import React from 'react';
import { Button } from '@nextui-org/react';
import gsap from 'gsap';

export default function ShareButton(props: any) {
  async function onClick() {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    urlObj.search = '';

    if (typeof navigator.share === 'function') {
      await navigator.share({
        title: 'Lica World',
        text: 'Check out what I created on Lica!',
        url: props.url || urlObj.toString(),
      });
    } else {
      gsap.set('.clipboard-message', {
        opacity: 1,
      });
      gsap.to('.clipboard-message', {
        opacity: 0,
        delay: 1,
      });
      await navigator.clipboard.writeText(props.url || urlObj.toString());
    }
  }
  return (
    <div className="relative sm:hidden">
      <Button
        color="primary"
        size="md"
        radius="full"
        className="text-primary bg-secondary hover:!bg-tertiary text-base z-10"
        onClick={onClick}
      >
        Share
      </Button>
      <div className="absolute font-medium text-xs clipboard-message opacity-0 bg-tertiary text-primary top-12 -left-5 px-2 text-nowrap py-1 rounded-full">Copied to clipboard</div>
    </div>
  );
}
