import {
  forwardRef, useMemo, useRef,
} from 'react';
import { CSSProperties } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

import Text from './text';
import { useGsapTimeline } from '../animation/gsap';
import { AnimationFactory } from '../animation/animation';

gsap.registerPlugin(SplitText);

const SHAPES = ['oval', 'rectangle'];
const BACKGROUND_COLORS = ['#F1FF53', '#9EB6FC', '#40EF7B', '#FF8282'];

interface ContentItem {
  items: any;
  itemStyles: any;
  itemAnimations: any;
}

interface ListTextProps {
  data: ContentItem;
  style: CSSProperties;
  slideDuration: number;
}

const useItemAnimations = (
  textRef: any,
  backgroundRef: any,
  animations: any,
  duration: number,
  delay: number,
) => {
  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.title.type);
    const { gsapTimeline } = animationFactory.createTimeline(textRef.current, {
      delay: delay + 1,
      ...animations.title.attributes,
      duration,
    });
    return gsapTimeline;
  });

  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.background.type);
    const { gsapTimeline } = animationFactory.createTimeline(backgroundRef.current, {
      ...animations.background.attributes,
      duration,
      delay,
    });

    return gsapTimeline;
  });
};

function AnimatedItem({
  item, delay, style, animations, duration, height, shape, backgroundColor,
}: any) {
  const textRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useItemAnimations(textRef, backgroundRef, animations, duration, delay);

  return (
    <div style={{ height }} className="text-center flex items-center justify-center px-14 relative">
      <div className="overflow-hidden z-20">
        <Text ref={textRef} text={item.title} style={style.titleStyle} />
      </div>
      <div
        ref={backgroundRef}
        className={`${
          shape === 'oval'
            ? 'rounded-full' : shape === 'rectangle' ? 'rounded-xl' : ''
        } w-full h-full absolute`}
        style={{ ...style.backgroundStyle, backgroundColor }}
      />
    </div>
  );
}

const TagsListicle = forwardRef<HTMLDivElement, ListTextProps>(
  ({ data, style, slideDuration }, ref) => {
    const { items, itemStyles, itemAnimations } = data;
    const singularDuration = slideDuration / items.length;
    const singularHeight = typeof style.height === 'string' ? (parseFloat(style.height) / items.length) : 0;

    const delays = useMemo(() => {
      let currentDelay = 0;
      return items.map(() => {
        const delayForThisItem = currentDelay;
        currentDelay += singularDuration;
        return delayForThisItem;
      });
    }, [data]);

    return (
      <div ref={ref} style={style}>
        {items.map((item: any, index: any) => (
          <AnimatedItem
            key={index}
            item={item}
            height={singularHeight}
            duration={singularDuration}
            style={itemStyles}
            animations={itemAnimations}
            delay={delays[index]}
            shape={SHAPES[index % SHAPES.length]}
            backgroundColor={BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}
          />
        ))}
      </div>
    );
  },
);

TagsListicle.displayName = 'TagsListicle';
export default TagsListicle;
