import { useState } from 'react';
import { Icon } from '@iconify/react';
import { Button } from '@nextui-org/react';

export default function ChatBox(
  {
    messages, handleSend, isProcessing,
  }: any,
) {
  const [
    localChatInputValue,
    setLocalChatInputValue,
  ] = useState<string>('');

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setLocalChatInputValue(value);
  };

  const handleKeyPress = (e: any) => {
    if (e.code === 'Enter') {
      handleSend(localChatInputValue);
      setLocalChatInputValue('');
    }
  };

  const handleSendClick = () => {
    handleSend(localChatInputValue);
    setLocalChatInputValue('');
  };

  return (
    <div className="h-full bg-black border border-[#EAECF0] rounded-lg w-full flex justify-center items-center">
      <div className="w-full h-full rounded-lg overflow-hidden flex flex-col justify-between">
        <div className="max-h-60vh lg:p-5 lg:py-7 p-7 flex flex-col overflow-y-auto">
          {messages.map((message: any) => (
            <div
              key={message.id}
              className="lg:text-sm mb-6 flex items-center justify-end w-full h-fit"
            >
              <span
                className={`w-20 mr-5 font-medium ${message.receiver ? 'text-white' : 'text-[#0B62E0]'}`}
              >
                {message.sender || message.receiver}
              </span>
              <span className="w-full text-gray-600">{message.text}</span>
            </div>
          ))}
        </div>
        <div className="mt-4 flex">
          <input
            className="flex-auto outline-none px-7 h-12 bg-[#e6e6e6] text-[#000000] rounded-l-lg"
            value={localChatInputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            disabled={isProcessing}
          />
          <div>
            <Button
              radius="none"
              size="md"
              className="h-full rounded-r-lg bg-tertiary text-primary"
              onClick={handleSendClick}
              startContent={isProcessing ? null
                : <Icon icon="fluent:send-clock-20-filled" style={{ fontSize: '2.5rem' }} />}
              isLoading={isProcessing}
              isDisabled={isProcessing || !localChatInputValue}
            >
              {isProcessing ? 'Generating' : 'Send'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
