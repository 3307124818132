import {
  useState, useEffect, useCallback, useContext,
} from 'react';

import AuthContext from '@/auth/context';

interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
}

interface UseFetchResult<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
  fetchData: (fetchOptions?: FetchOptions) => Promise<T | null>;
  triggerFetch: () => void;
}

const useFetch = <T = any>(url: string, options: FetchOptions = {}): UseFetchResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const authContext = useContext(AuthContext);

  const fetchData = useCallback(async (fetchOptions: FetchOptions = {}) => {
    setLoading(true);
    setError(null);
    try {
      const token = await authContext.currentUser?.getIdToken();
      const response = await fetch(url, {
        ...options,
        ...fetchOptions,
        headers: {
          ...options.headers,
          ...fetchOptions.headers,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json() as T;
      setData(result);
      return result; // Return the result directly
    } catch (err) {
      setError(err as Error);
      return null; // Return null in case of error
    } finally {
      setLoading(false);
    }
  }, [url, options, authContext]);

  useEffect(() => {
    if (shouldFetch && authContext) {
      fetchData();
    }
  }, [shouldFetch, authContext]);

  const triggerFetch = () => setShouldFetch(true);

  return {
    data, loading, error, fetchData, triggerFetch,
  };
};

export default useFetch;
