import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';

import * as Sentry from '@sentry/react';
import App from './App';

Sentry.init({
  dsn: 'https://4c2f54531b7f3d5ef035d2829e2a7591@o4507805896146944.ingest.us.sentry.io/4507810788474880',
  enabled: process.env.NODE_ENV === 'production',
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
});

const options = {
  api_host: import.meta.env.VITE_POSTHOG_API_HOST,
};

const root = document.getElementById('root');
const rootElement = createRoot(root!);

rootElement.render(
  <PostHogProvider
    apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
    options={options}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </PostHogProvider>,
);
