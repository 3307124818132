export const VIDEO_DATA = {
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-video/music-library/piano.mp3',
      type: 'MUSIC',
      volume: 0.4,
    },
    {
      src: 'https://storage.googleapis.com/lica-video/8b3322be-a759-465f-858f-0bae1f0c9f86_audio.mp3',
      type: 'VOICEOVER',
      volume: 1,
    },
  ],
  duration: 31.79,
  fps: 30,
  height: 576,
  slides: [
    {
      components: [
        {
          components: [],
          from: 0,
          id: '0-0',
          style: {
            backgroundColor: '#221549',
            height: '576px',
            left: '0px',
            position: 'absolute',
            top: '0px',
            width: '324px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          components: [
            {
              id: '0-1-0',
              style: {
                color: '#FFFFFF',
                display: 'inline-block',
                fontFamily: 'DM Mono',
                fontSize: '50px',
                fontWeight: 'bold',
                height: '143px',
                left: '0px',
                position: 'absolute',
                textAlign: 'center',
                top: '0px',
                width: '256px',
              },
              text: 'SkyHigh Innovations',
              type: 'TEXT',
            },
            {
              id: '0-1-1',
              style: {
                color: '#FFFFFF',
                display: 'inline-block',
                fontFamily: 'Roboto Flex',
                fontSize: '17px',
                height: '40px',
                left: '0px',
                position: 'absolute',
                top: '184px',
                width: '256px',
              },
              text: 'At SkyHigh Innovations, we create solutions as limitless as the sky.',
              type: 'TEXT',
            },
          ],
          from: 0,
          id: '0-1',
          style: {
            height: '224px',
            left: '36px',
            position: 'absolute',
            top: '317px',
            width: '256px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          components: [],
          from: 0,
          id: '0-2',
          style: {
            backgroundColor: '#654BAB',
            borderRadius: '50%',
            height: '139px',
            left: '126px',
            position: 'absolute',
            top: '41px',
            width: '139px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '0-3',
          src: 'https://storage.googleapis.com/lica-video/143d3b7f-544d-469b-b092-c8926f832619_img.svg',
          style: {
            bottom: '68.66%',
            height: '24.05%',
            left: '13.27%',
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
            overflow: 'hidden',
            position: 'absolute',
            right: '43.83%',
            top: '7.29%',
            width: '42.9%',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          components: [],
          from: 0,
          id: '0-4',
          style: {
            backgroundColor: '#FFFFFF',
            borderRadius: '50%',
            height: '35px',
            left: '43px',
            position: 'absolute',
            top: '228px',
            width: '35px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '0-5',
          src: 'https://storage.googleapis.com/lica-video/abb69dc6-0edf-4269-9150-8bf26431f320_img.svg',
          style: {
            bottom: '50.1%',
            height: '20.73%',
            left: '35.19%',
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'cover',
            overflow: 'hidden',
            position: 'absolute',
            right: '10.25%',
            top: '29.17%',
            width: '54.57%',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '0-6',
          src: 'https://storage.googleapis.com/lica-video/350ddaa6-144e-4ce6-8bf8-6ea5917f208d_img.svg',
          style: {
            bottom: '83.87%',
            height: '5.19%',
            left: '64.2%',
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
            position: 'absolute',
            right: '10.28%',
            top: '10.94%',
            width: '25.52%',
          },
          type: 'SVG',
        },
      ],
      duration: 7.406,
      layout_id: '8',
      style: {
        color: '#FFFFFF',
        fontFamily: 'DM Sans',
        fontSize: '50px',
        height: '576px',
        position: 'relative',
        textAlign: 'left',
        width: '100%',
      },
      voiceover: 'Welcome to SkyHigh Innovations, where our passion soars beyond the ordinary, reaching the heights of creativity and technology.',
    },
    {
      components: [
        {
          components: [],
          from: 0,
          id: '1-0',
          style: {
            backgroundColor: '#221549',
            height: '576px',
            left: '0px',
            position: 'absolute',
            top: '0px',
            width: '100%',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          components: [
            {
              id: '1-1-0',
              style: {
                color: '#FFFFFF',
                fontFamily: 'DM Mono',
                fontSize: '17px',
                fontWeight: '300',
                height: '40.6px',
                left: '0px',
                position: 'absolute',
                top: '105.4px',
                whiteSpace: 'pre-wrap',
                width: '234px',
              },
              text: 'SkyHigh enables aerial advancements for a better tomorrow.',
              type: 'TEXT',
            },
            {
              id: '1-1-1',
              style: {
                color: '#FFFFFF',
                fontFamily: 'DM Sans',
                fontSize: '40px',
                fontWeight: '500',
                height: '93.3px',
                left: '27px',
                position: 'absolute',
                textAlign: 'center',
                top: '0px',
                width: '165px',
              },
              text: 'Aerial Pioneers',
              type: 'TEXT',
            },
          ],
          from: 0,
          id: '1-1',
          style: {
            height: '146px',
            left: '46px',
            position: 'absolute',
            top: '52px',
            width: '234px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          components: [],
          from: 0,
          id: '1-2',
          style: {
            backgroundColor: '#654BAB',
            borderRadius: '50%',
            height: '210px',
            left: '58px',
            position: 'absolute',
            top: '269px',
            width: '210px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '1-3',
          src: 'https://storage.googleapis.com/lica-video/010b9f03-b182-4db1-942d-35857493747d_img.svg',
          style: {
            height: '112px',
            left: '117px',
            objectFit: 'cover',
            position: 'absolute',
            top: '318px',
            width: '93px',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '1-4',
          src: 'https://storage.googleapis.com/lica-video/f8ba335d-469b-427e-a044-800ebd8c91d3_img.svg',
          style: {
            bottom: '41.84%',
            height: '3.73%',
            left: '7.31%',
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
            position: 'absolute',
            right: '74.29%',
            top: '54.43%',
            width: '18.4%',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '1-5',
          src: 'https://storage.googleapis.com/lica-video/560bc841-4d33-4343-9114-c7e41487b458_img.svg',
          style: {
            bottom: '14.95%',
            height: '11.81%',
            left: '7.96%',
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
            overflow: 'hidden',
            position: 'absolute',
            right: '65.03%',
            top: '73.25%',
            width: '27.01%',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '1-6',
          src: 'https://storage.googleapis.com/lica-video/2bd7ab1e-39a2-4008-88d0-04fe605f67bd_img.svg',
          style: {
            bottom: '41.49%',
            height: '11.81%',
            left: '63.58%',
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
            overflow: 'hidden',
            position: 'absolute',
            right: '9.41%',
            top: '46.7%',
            width: '27.01%',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '1-7',
          src: 'https://storage.googleapis.com/lica-video/575302a6-e38d-43a8-9e1d-f0ee58f0db0b_img.svg',
          style: {
            bottom: '13.23%',
            height: '12.95%',
            left: '65.52%',
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
            overflow: 'hidden',
            position: 'absolute',
            right: '11.2%',
            top: '73.82%',
            width: '23.27%',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          components: [],
          from: 0,
          id: '1-8',
          style: {
            backgroundColor: '#FFFFFF',
            borderRadius: '50%',
            height: '27px',
            left: '104px',
            position: 'absolute',
            top: '264px',
            width: '27px',
          },
          type: 'GROUP',
        },
      ],
      duration: 8.104,
      layout_id: '9',
      style: {
        color: '#FFFFFF',
        fontFamily: 'DM Mono',
        fontSize: '17px',
        height: '576px',
        position: 'relative',
        textAlign: 'left',
        width: '100%',
      },
      voiceover: 'At SkyHigh, our focus is on pushing the boundaries of aerial technology, providing solutions that elevate industries and improve lives.',
    },
    {
      components: [
        {
          components: [],
          from: 0,
          id: '2-0',
          style: {
            backgroundColor: '#221549',
            height: '576px',
            left: '0px',
            position: 'absolute',
            top: '0px',
            width: '324px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          components: [
            {
              id: '2-1-0',
              style: {
                color: '#FFFFFF',
                display: 'inline-block',
                fontFamily: 'DM Mono',
                fontSize: '17px',
                fontWeight: '300',
                height: '80px',
                left: '2px',
                position: 'absolute',
                top: '112px',
                width: '240px',
              },
              text: 'Our drones redefine efficiency and innovation in aerial tasks.',
              type: 'TEXT',
            },
            {
              id: '2-1-1',
              style: {
                display: 'inline-block',
                fontFamily: 'DM Sans',
                fontSize: '30px',
                fontWeight: 'bold',
                height: '70px',
                left: '0px',
                position: 'absolute',
                top: '0px',
                width: '244px',
              },
              text: 'Revolutionary Drones',
              type: 'TEXT',
            },
            {
              id: '2-1-2',
              src: 'https://storage.googleapis.com/lica-video/Group%20(17).svg',
              style: {
                bottom: '49.38%',
                height: '6.35%',
                left: '34.43%',
                maxHeight: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
                position: 'absolute',
                right: '35.66%',
                top: '44.27%',
                width: '29.92%',
              },
              type: 'SVG',
            },
          ],
          from: 0,
          id: '2-1',
          style: {
            height: '192px',
            left: '42px',
            position: 'absolute',
            top: '54px',
            width: '244px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '2-2',
          src: 'https://storage.googleapis.com/lica-video/9f21bc41-8f98-47c2-85c6-a80b571912eb.mp4',
          style: {
            borderRadius: '50%',
            height: '267px',
            left: '29px',
            objectFit: 'cover',
            position: 'absolute',
            top: '276px',
            width: '267px',
          },
          type: 'VIDEO',
        },
      ],
      duration: 6.016000000000002,
      layout_id: '10',
      style: {
        color: '#FFFFFF',
        fontFamily: 'DM Mono',
        fontSize: '17px',
        height: '576px',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
      },
      voiceover: 'Our flagship drones are engineered to perform complex tasks with unprecedented precision and reliability.',
    },
    {
      components: [
        {
          animation: {
            type: 'pan',
          },
          components: [],
          from: 0,
          id: '3-0',
          style: {
            backgroundColor: '#221549',
            height: '576px',
            left: '0px',
            position: 'absolute',
            top: '0px',
            width: '324px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '3-1',
          src: 'https://storage.googleapis.com/lica-video/0b5dd3f3-82c5-4148-a2ff-714d92949120_img.svg',
          style: {
            height: '122.6px',
            left: '29px',
            objectFit: 'contain',
            position: 'absolute',
            top: '37px',
            width: '262px',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '3-2',
          src: 'https://storage.googleapis.com/lica-video/4d186659-8676-4c6a-bd73-e97f85181ef4_img.svg',
          style: {
            height: '111px',
            left: '29px',
            objectFit: 'contain',
            position: 'absolute',
            top: '425px',
            width: '262px',
          },
          type: 'SVG',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '3-3',
          style: {
            display: 'inline-block',
            fontSize: '39px',
            fontWeight: 500,
            height: '94px',
            left: '0px',
            position: 'absolute',
            top: '203px',
            width: '323px',
          },
          text: 'Our Thrilled Clients',
          type: 'TEXT',
        },
        {
          animation: {
            type: 'pan',
          },
          from: 0,
          id: '3-4',
          style: {
            display: 'inline-block',
            fontFamily: 'DM Mono',
            fontSize: '17px',
            fontWeight: 300,
            height: '65px',
            left: '44px',
            position: 'absolute',
            top: '311px',
            width: '237px',
          },
          text: 'Serving tech enthusiasts, environmentalists, and innovators.',
          type: 'TEXT',
        },
      ],
      duration: 11.303999999999998,
      layout_id: '11',
      style: {
        color: '#FFFFFF',
        fontFamily: 'DM Sans',
        fontSize: '40px',
        height: '576px',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
      },
      voiceover: 'SkyHigh Innovations garners praise from a diverse customer base, from hobbyists to corporate giants, who all value our commitment to excellence.',
    },
  ],
  width: 324,
};

export const NIUM_NEW_SCENE = {
  duration: 10,
  components: [
    {
      components: [],
      style: {
        backgroundColor: '#000',
        top: '78.6px',
        borderRadius: '50px',
        left: '80px',
        width: '920px',
        position: 'absolute',
        height: '360px',
      },
      from: 0,
      type: 'GROUP',
      animation: {
        attributes: {
          position: 'center',
        },
        type: 'expand',
      },
    },
    {
      src: 'https://storage.googleapis.com/lica-assets/nium-3.mp4',
      style: {
        objectFit: 'cover',
        top: '438.6px',
        borderRadius: '450px',
        left: '80.2px',
        width: '920.4px',
        position: 'absolute',
        height: '1401.4px',
      },
      from: 0,
      type: 'VIDEO',
      volume: 0,
      animation: {
        attributes: {
          position: 'center',
        },
        type: 'expand',
      },
    },
    {
      style: {
        color: '#9eb6fc',
        top: '110.6px',
        left: '105px',
        display: 'inline-block',
        width: '870px',
        letterSpacing: '-0.05em',
        lineHeight: '98px',
        fontSize: '90px',
        position: 'absolute',
        fontFamily: 'Haffer',
      },
      from: 0,
      text: 'Great for capturing everyday moments hands-free',
      type: 'TEXT',
      animation: {
        attributes: {
          duration: 1.5,
          divider: 'lines',
          stagger: 0.8,
        },
        type: 'rise',
      },
    },
  ],
  style: {
    backgroundColor: '#9eb6fc',
    overflow: 'hidden',
    fontFamily: 'Geist',
    color: '#9eb6fc',
    textAlign: 'center',
    width: '100%',
    fontSize: '90px',
    position: 'relative',
    height: '1920px',
  },
};

export const NIUM_NEW_SCENE_LISTICLE = {
  duration: 10,
  components: [{
    data: {
      itemAnimations: {
        background: { attributes: { position: 'left' }, type: 'expand' },
        title: { attributes: { divider: 'lines', stagger: 0.1 }, type: 'rise' },
      },
      itemStyles: {
        backgroundStyle: {
          backgroundColor: '#40EF7B', borderRadius: '100px', width: '170px', height: '200px',
        },
        titleStyle: { fontFamily: 'Haffer', color: '#000000', fontSize: '80px' },
      },
      items: [{ title: 'Capture Hands-Free' }, { title: 'Stay Connected text' }, { title: 'Enhanced AI, Stylish Designs, Easy Connectivity' }],
    },
    style: {
      top: '872px', left: '50px', display: 'flex', flexDirection: 'column', width: '950px', position: 'absolute', gap: '40px', height: '800px',
    },
    from: 0,
    type: 'TAGS_BULLETS',
  }, {
    src: 'https://storage.googleapis.com/lica-assets/nium-3.mp4',
    style: {
      objectFit: 'cover', top: '120.1px', borderRadius: '418.54px', left: '89.7px', width: '908.8px', position: 'absolute', height: '635.8px',
    },
    from: 0,
    type: 'VIDEO',
    animation: { attributes: { position: 'center' }, type: 'expand' },
  }],
  style: {
    backgroundColor: '#E8E8E8', overflow: 'hidden', fontFamily: 'Haffer', color: '#000000', textAlign: 'left', width: '100%', fontSize: '70px', position: 'relative', height: '1920px',
  },
};

export const NIUM_DEMO = {
  fps: 30,
  width: 1080,
  height: 1920,
  slides: [
    {
      duration: 7,
      components: [{
        componentStyle: 'TITLE_POPUP',
        itemStyles: {
          alignContent: 'center', backgroundColor: '#9EB6FC', borderRadius: '157.55px', padding: '20px 80px', fontSize: '100px',
        },
        from: 0,
        style: {
          top: '80px', left: '80px', width: '920px', height: '800px', position: 'absolute',
        },
        text: 'Meta Unveils Smart Glasses & AI Updates',
        type: 'TEXT',
      }, {
        src: 'https://storage.googleapis.com/lica-assets/nium-4.mp4',
        style: {
          objectFit: 'cover', top: '1184.8px', borderRadius: '250px', left: '80px', width: '920px', position: 'absolute', height: '655.2px',
        },
        from: 0,
        type: 'VIDEO',
        volume: 0,
        animation: { attributes: { direction: 'right' }, type: 'pan' },
      }],
      style: {
        backgroundColor: '#E8E8E8', overflow: 'hidden', fontFamily: 'Haffer', color: '#000000', textAlign: 'center', width: '100%', fontSize: '120px', position: 'relative', height: '100%',
      },
    },
    {
      duration: 3,
      components: [{
        src: 'https://storage.googleapis.com/lica-assets/nium-1.mp4',
        volume: 0,
        style: {
          top: '0px', objectFit: 'cover', left: '0px', width: '100%', position: 'absolute', height: '100%',
        },
        from: 0,
        type: 'VIDEO',
      }],
    },
    {
      duration: 2,
      components: [{
        text: 'PRE ORDER NOW',
        style: {
          top: '800px',
          left: '65px',
          position: 'absolute',
          width: '954px',
          color: '#000000',
          alignContent: 'center',
          padding: '20px 60px',
          backgroundColor: '#9EB6FC',
          borderRadius: '157.55px',
          mixBlendMode: 'normal',
          letterSpacing: '-0.05em',
          fontSize: '100px',
          fontWeight: '500',
          zIndex: 999,
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
        },
        from: 0,
        type: 'TEXT',
        link: 'https://www.ray-ban.com/usa/ray-ban-meta-smart-glasses',
        animation: {
          type: 'expand',
        },
      }, {
        src: 'https://storage.googleapis.com/lica-assets/nium-3.mp4',
        volume: 0,
        style: {
          top: '0px', objectFit: 'cover', left: '0px', width: '100%', position: 'absolute', height: '100%',
        },
        from: 0,
        type: 'VIDEO',
      }],
      style: {
        backgroundColor: 'white', width: '100%', position: 'absolute', height: '100%',
      },
    },
  ],
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-assets/nium-audio.mp3',
      volume: 1,
    },
    {
      src: 'https://storage.googleapis.com/lica-assets/new-horizon.mp3',
      volume: 0.1,
    },
  ],
};
