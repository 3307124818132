import { GoogleAuthProvider, signInWithPopup, signOut as firebaseSignOut } from 'firebase/auth';

import { auth } from './firebase';

const provider = new GoogleAuthProvider();

const userLogin = async (idToken: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/users/login/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.json();
};

export async function signOut(): Promise<void> {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    console.error('Error during sign-out:', error);
  }
}

export async function signInWithGoogle(): Promise<void> {
  try {
    const result = await signInWithPopup(auth, provider);
    const userEmail = result.user?.email;

    if (userEmail) {
      try {
        const idToken = await result.user.getIdToken();
        await userLogin(idToken);
      } catch (error) {
        await signOut();
      }
    } else {
      await signOut();
    }
  } catch (error) {
    console.error('Error during sign-in:', error);
  }
}
