import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Spacer, Button } from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';

function PageCreate() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [prompt, setPrompt] = useState('');
  const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);

  const onGenerateVideoClick = async () => {
    if (!prompt || prompt.length < 8) return;

    const token = await authContext.currentUser?.getIdToken();
    if (!token) return;

    setIsGeneratingVideo(true);

    const data: any = {
      prompt,
      content_types: [{
        type: 'VIDEO',
        params: {

        },
      }, {
        type: 'PODCAST',
        params: {

        },
      }, {
        type: 'BLOG',
        params: {

        },
      }],
    };

    fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        navigate(`/lp/${responseData.data.page_id}`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsGeneratingVideo(false);
      });
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="h-full w-full px-4">
      <div
        className="flex flex-col items-center justify-between h-full"
        style={{
          maxWidth: '1080px',
          margin: 'auto',
        }}
      >
        <div>
          {/* DO NOT REMOVE THIS EMPTY DIV */}
        </div>
        <div className="p-3 rounded-xl w-full" style={{ backgroundColor: '#2B2B2B' }}>
          <Button
            className="p-2 rounded-md text-base font-medium"
            startContent={<Icon icon="solar:text-bold" />}
            style={{ color: '#BABABA', backgroundColor: '#333' }}
          >
            Story
          </Button>
          <Spacer y={3} />
          <div>
            <textarea
              ref={inputRef}
              id="prompt"
              name="prompt"
              rows={6}
              className="w-full"
              style={{
                resize: 'none', outline: 'none', color: '#BABABA', backgroundColor: '#2B2B2B',
              }}
              placeholder="Enter your story here (min. 8 characters)"
              onChange={(e) => setPrompt(e.target.value.trim())}
            />
          </div>
        </div>
        <div className="flex items-center justify-center py-6">
          <Button
            className="p-2 rounded-md text-base font-medium"
            startContent={<Icon icon="solar:videocamera-linear" />}
            style={{
              color: (!prompt || prompt.length < 8) ? '#656565' : 'rgba(0, 0, 0, 0.90)',
              backgroundColor: (!prompt || prompt.length < 8) ? '#2B2B2B' : 'rgba(255, 255, 255, 0.90)',
            }}
            disabled={!prompt || prompt.length < 8}
            onClick={onGenerateVideoClick}
            isLoading={isGeneratingVideo}
          >
            Generate Content
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PageCreate;
