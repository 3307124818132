import React, {
  useContext,
  useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spacer } from '@nextui-org/react';
import { CallbackListener, PlayerRef } from '@remotion/player';
import { Slide, Zoom } from '@mui/material';
import dayjs from 'dayjs';

import useWindowDimensions from '@/hooks/useWindowDimensions';
import useFetch from '@/hooks/useFetch';
import AuthContext from '@/auth/context';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import ControlBar from '@/components/control-bar';

function TitleCard({ podcastData, data }: { podcastData: any, data: any }) {
  return (
    <div className="flex gap-5 p-0 mb-5 w-full">
      <img
        alt="Main visual representation"
        src={podcastData.image}
        className="h-32 w-32 rounded-lg object-cover"
      />
      <div className="flex-col">
        <div className="text-2xl text-secondary sm:text-lg font-semibold">
          {podcastData.title}
        </div>
        <Spacer y={3} />
        <div className="flex gap-5 items-center">
          <div className="flex items-start justify-left">
            <h4 className="text-small leading-none text-secondary opacity-70">
              {data.user_info?.name}
            </h4>
            <div className="mt-1 mx-3 w-2 h-2 rounded-full bg-[#c0c0c0]" />
            <h4 className="text-small leading-none text-secondary opacity-70">
              {dayjs(data.created_at).format('DD MMM YYYY')}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

const Transcript = React.memo(({ text }: { text: string | any[] }) => {
  let localText = '';

  if (typeof text !== 'string') {
    text.forEach((item: any) => {
      const speakerName = Object.keys(item)[0]
        .split('_')
        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');

      localText += `${speakerName}: ${Object.values(item)[0]}\r\n\n`;
    });
  } else {
    localText = text;
  }

  return (
    <div className="w-full h-auto border border-secondary mt-2 overflow-hidden p-6 rounded-[2rem] border-opacity-20 ">
      <textarea
        value={localText}
        readOnly
        className="w-full overflow-auto text-secondary h-auto min-h-52 resize-none outline-none focus:outline-none"
        style={{
          backgroundColor: 'transparent',
        }}
      />
    </div>
  );
});

function PodcastNew({ data, contentId }: { data: any, contentId: string }) {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const playerRef = useRef<PlayerRef>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [showTranscript, setShowTranscript] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isShuffling, setIsShuffling] = useState(false);

  const [podcastData, setPodcastData] = useState<any>(data.output);

  const { fetchData: downloadFetch } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/download/`,
    { method: 'POST' },
  );
  const { fetchData: shuffleFetch } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${contentId}/update`,
    { method: 'POST' },
  );
  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${contentId}/`,
    { method: 'GET' },
  );

  useEffect(() => {
    if (!playerRef.current && !videoRef.current) {
      return;
    }

    const remotionPlayer = playerRef.current;
    const videoPlayer = videoRef.current;

    const onPlay: CallbackListener<'play'> = () => {
      setIsPlaying(true);
    };
    const onPause: CallbackListener<'pause'> = () => {
      setIsPlaying(false);
    };

    const onVideoPlay = () => {
      setIsPlaying(true);
    };
    const onVideoPause = () => {
      setIsPlaying(false);
    };

    remotionPlayer?.addEventListener('play', onPlay);
    remotionPlayer?.addEventListener('pause', onPause);

    videoPlayer?.addEventListener('play', onVideoPlay);
    videoPlayer?.addEventListener('pause', onVideoPause);

    return () => {
      remotionPlayer?.removeEventListener('play', onPlay);
      remotionPlayer?.removeEventListener('pause', onPause);

      videoPlayer?.removeEventListener('play', onVideoPlay);
      videoPlayer?.removeEventListener('pause', onVideoPause);
    };
  }, []);

  const fetchContent = async () => {
    try {
      const contentResponseData = await getContent();

      if (contentResponseData?.data?.update_status === 'completed') {
        setPodcastData(contentResponseData.data.output);
        setIsShuffling(false);
      } else if (contentResponseData?.data?.update_status === 'failed') {
        setIsShuffling(false);
      } else {
        setTimeout(() => {
          fetchContent();
        }, 5000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleShuffleClick = async () => {
    if (isShuffling) {
      return;
    }

    setIsShuffling(true);

    const actions = [];

    const actionPayload = {
      id: 'shuffle_styles',
      attributes: null,
    };
    actions.push(actionPayload);

    try {
      const responseData = await shuffleFetch({
        body: JSON.stringify({ actions }),
      });
      if (['initiated', 'processing'].includes(responseData.data.update_status)) {
        await fetchContent();
      } else {
        setIsShuffling(false);
      }
    } catch (err) { /* empty */ }
  };

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);

    let downloadURL = '';

    if (podcastData.download_links) {
      downloadURL = showTranscript
        ? podcastData.download_links.portrait
        : podcastData.download_links.square;

      try {
        const response = await fetch(downloadURL);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', 'lica-podcast.mp4');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        window.URL.revokeObjectURL(url);
      } catch (err) {
        setIsDownloading(false);
      }
    } else {
      let payload = {
        composition: 'Template',
        inputProps: podcastData.audio_data,
      };
      if (!showTranscript) {
        payload = {
          composition: 'Template',
          inputProps: podcastData.orientations.square,
        };
      }

      try {
        const response = await downloadFetch({
          body: JSON.stringify(payload),
        });
        downloadURL = response.outputFile;
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadURL;
        downloadLink.setAttribute('download', 'lica-podcast.mp4');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) { /* empty */ }
    }
    setIsDownloading(false);
  };

  return (
    <div className="flex flex-col items-center gap-10">
      <div
        className={`max-w-[50rem] sm:px-4 gap-8 flex w-full justify-center ${!showTranscript ? 'items-center' : width < 850 ? 'items-center' : 'items-start'} ${(!showTranscript || width < 850) && 'flex-col-reverse'}`}
      >
        {!showTranscript && (
          <Button
            className="text-small mt-5 !bg-tertiary !text-primary"
            radius="full"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        )}
        {showTranscript && width < 850 && (
          <Zoom in={showTranscript} mountOnEnter>
            <div className="w-full mt-6 flex flex-col items-center justify-center">
              <h1 className="text-xl font-semibold mb-1 text-secondary">Transcript</h1>
              <Transcript text={podcastData.transcript} />
            </div>
          </Zoom>
        )}
        <Slide direction="right" in mountOnEnter>
          <div className="rounded-lg flex justify-center items-center flex-col">
            <div className="w-full br-5 rounded-md">
              {(
                data.user_info.email === authContext.currentUser?.email
                || !podcastData.download_links
              ) ? (
                <div className={`min-w-[320px] ${(showTranscript && width > 500) ? 'aspect-[9/16]' : 'aspect-[1]'}`}>
                  <RemotionPlayer
                    ref={playerRef}
                    data={
                      (showTranscript && width > 500)
                        ? podcastData.audio_data : podcastData.orientations.square
                    }
                  />
                </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <video
                      ref={videoRef}
                      className={`min-w-[320px] max-w-[400px] ${showTranscript ? 'w-5/6 h-4/5' : 'w-4/5 h-4/5 sm:w-full sm:h-full'}`}
                      src={
                      (showTranscript && width > 500)
                        ? podcastData.download_links.portrait
                        : podcastData.download_links.square
                    }
                      controls
                    />
                  </div>
                )}
            </div>
          </div>
        </Slide>
        <div className="rounded-lg my-1 flex justify-center items-center w-full flex-col">
          <TitleCard podcastData={podcastData} data={data} />
          <ControlBar
            podcastData={podcastData}
            playerRef={playerRef}
            videoRef={videoRef}
            isPlaying={isPlaying}
            isShuffling={isShuffling}
            handleShuffleClick={handleShuffleClick}
            isDownloading={isDownloading}
            handleDownloadClick={handleDownloadClick}
            showTranscript={showTranscript}
            setShowTranscript={setShowTranscript}
            data={data}
            contentId={contentId}
            width={width}
            navigate={navigate}
          />
          {showTranscript && width > 850 && (
            <Zoom in={showTranscript} mountOnEnter>
              <div className="w-full mt-6 flex flex-col justify-center items-center">
                <h1 className="text-xl font-semibold">Transcript</h1>
                <Transcript text={podcastData.transcript} />
              </div>
            </Zoom>
          )}
        </div>
      </div>
      {showTranscript && (
        <Button
          className="text-small bg-tertiary text-primary"
          radius="full"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      )}
    </div>
  );
}

export default PodcastNew;
