import zIndex from '@mui/material/styles/zIndex';
import { staticFile } from 'remotion';

export const LISTICLES_JSON = {
  fps: 30,
  width: 324,
  height: 578,
  components: [{
    type: 'SUBTITLE',
    src: 'https://storage.googleapis.com/lica-video/test/subtitles.srt',
    audioOffsetInSeconds: 0,
    onlyDisplayCurrentSentence: true,
    subtitlesTextColor: 'rgba(255, 255, 255, 0.93)',
    subtitlesActiveTextColor: 'rgba(255, 255, 255, 1)',
    subtitlesLinePerPage: 4,
    subtitlesZoomMeasurerSize: 1,
    subtitlesLineHeight: 25,
    style: {
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      fontSize: '20px',
      lineHeight: 'normal',
      textAlign: 'center',
      display: 'inline-block',
      color: '#f3ffa8',
      width: '100%',
      padding: 0,
      textShadow: `
      -2px -2px 2px #000,
      2px -2px 2px #000,
      -2px 2px 2px #000,
      2px 2px 2px #000,
      0 0 5px #000,
      0 0 10px #000,
      0 0 15px #000,
      0 0 20px #000,
      0 0 25px #000,
      0 0 30px #000
    `,
    },
    textStyle: {
    },
  }],
  slides: [
    {
      components: [
        {
          type: 'TEXT',
          text: 'Roger Federer Roger Federer Roger Federer Roger Federer',
          maxHeight: 100,
          style: {
            textAlign: 'center',
            fontSize: '24px',
            color: 'white',
          },
          animations: [
            {
              type: 'translateY',
              from: -100,
              to: 0,
            },
            {
              type: 'opacity',
              from: 0,
              to: 1,
              durationInFrames: 30,
            },
          ],
          from: 0,
        },
      ],
      duration: 30,
      style: {
        display: 'uniform',
        padding: '20px',
        background: {
          video: 'https://storage.googleapis.com/lica-video/background.mp4',
        },
      },
    },
    {
      components: [
        {
          type: 'TEXT',
          text: 'Roger Federer Roger Federer Roger Federer Roger Federer',
          maxHeight: 100,
          style: {
            textAlign: 'center',
            fontSize: '24px',
            color: 'white',
          },
          animations: [
            {
              type: 'translateY',
              from: -100,
              to: 0,
            },
            {
              type: 'opacity',
              from: 0,
              to: 1,
              durationInFrames: 30,
            },
          ],
          from: 0,
        },
        {
          type: 'LIST',
          list: [
            'Common English name',
            "Polite way of saying 'yes'",
            "Slang term for 'received and understood'",
          ],
          style: {
            align: 'center',
            size: '24px',
            color: 'white',
          },
          animations: [
            {
              type: 'translateX',
              from: -100,
              to: 0,
            },
          ],
          from: 0,
        },
        {
          type: 'VIDEO',
          src: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
          startFrom: 301,
          from: 0,
        },
      ],
      duration: 10,
      style: {
        display: 'uniform',
        padding: '20px',
        background: {
          video: 'https://storage.googleapis.com/lica-video/background.mp4',
        },
      },
    },
  ],
};

export const PODCAST_JSON = {
  fps: 30,
  width: 1080,
  height: 1920,
  slides: [
    {
      components: [
        {
          type: 'IMAGE',
          src: 'https://storage.googleapis.com/lica-video/5487ae0b-eb8c-49d3-9f97-d7363fdf9896.png',
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '1080.2px',
            height: '1920.4px',
            objectFit: 'cover',
          },
          from: 0,
        },
        {
          type: 'AUDIO',
          src: staticFile('audio.mp3'),
          waveColor: '#F3FFA7',
          waveFreqRangeStartIndex: 7,
          waveLinesToDisplay: 29,
          waveNumberOfSamples: '256', // This is string for Remotion controls and will be converted to a number
          mirrorWave: true,
          audioOffsetInSeconds: 6.9,
          style: {
            position: 'absolute',
            top: '1133.4px',
            left: '-204px',
            width: '1488.1px',
            height: '197.4px',
          },
          from: 0,
        },
        {
          type: 'GROUP',
          components: [
            {
              type: 'SUBTITLE',
              src: 'https://storage.googleapis.com/lica-video/test/subtitles.srt',
              audioOffsetInSeconds: 6.9,
              onlyDisplayCurrentSentence: true,
              subtitlesTextColor: 'rgba(255, 255, 255, 0.93)',
              subtitlesLinePerPage: 1,
              subtitlesZoomMeasurerSize: 10,
              subtitlesLineHeight: 98,
              style: {
                position: 'absolute',
                top: '1401.9px',
                left: '80.2px',
                fontSize: '80px',
                lineHeight: 'normal',
                textAlign: 'center',
                display: 'inline-block',
                width: '920px',
                color: '#f3ffa8',
              },
            },
            {
              type: 'TEXT',
              text: 'Shiyan Koh',
              style: {
                position: 'absolute',
                top: '1658px',
                left: '80.2px',
                lineHeight: 'normal',
                display: 'inline-block',
                width: '920px',
                height: '180px',
                color: '#f3ffa8',
                fontSize: '80px',
              },
            },
            {
              type: 'TEXT',
              text: 'General Partner, Hustle Fund',
              style: {
                position: 'absolute',
                top: '1753px',
                left: '80.2px',
                lineHeight: 'normal',
                display: 'inline-block',
                width: '920px',
                height: '180px',
                color: '#f3ffa8',
                fontSize: '60px',
              },
            },
          ],
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '1080.2px',
            height: '1920.4px',
            overflow: 'hidden',
          },
          from: 0,
        },
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '1578px',
            left: '-3.8px',
            borderTop: '8px solid #f3ffa8',
            boxSizing: 'border-box',
            width: '1088px',
            height: '8px',
          },
          components: [],
          from: 0,
          animation: {
            type: 'panreverse',
            attributes: {
              animate: 'onEnterTwice',
              direction: 'right',
            },
          },
        },
      ],
      duration: '30',
      style: {
        width: '1080px',
        backgroundColor: '#f8d0ff',
        maxWidth: '100%',
        height: '1920px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '110px',
        color: '#d238eb',
        fontFamily: 'Haffer',
      },
    },
  ],
};

export const AUDIO_JSON = {
  fps: 30,
  width: 1920,
  height: 1080,
  slides: [
    {
      components: [
        {
          type: 'SUBTITLE',
          audioOffsetInSeconds: 6.9,
          subtitlesFileName:
            'https://storage.googleapis.com/lica-video/test/subtitles.srt',
          onlyDisplayCurrentSentence: true,
          subtitlesTextColor: 'rgba(255, 255, 255, 0.93)',
          subtitlesLinePerPage: 4,
          subtitlesZoomMeasurerSize: 10,
          subtitlesLineHeight: 98,
        },
      ],
      duration: 29.5,
    },
  ],
};

export const CAROUSEL_JSON_2 = {
  fps: 30,
  width: 1920,
  height: 1080,
  slides: [
    {
      components: [
        {
          type: 'CAROUSEL',
          items: [
            {
              image:
                'https://storage.googleapis.com/lica-video/test/carousel-3.png',
              title: 'Sift, streamlined payments worldwide',
            },
            {
              image:
                'https://storage.googleapis.com/lica-video/test/carousel-1.png',
              title: 'Onboarding Platform: A New Era of Hiring',
            },
            {
              title: 'Master local compliance effortlessly',
              image:
                'https://storage.googleapis.com/lica-video/845bc893-b824-4e8e-9894-b0c12d6c177a.png',
            },
            {
              image:
                'https://storage.googleapis.com/lica-video/test/carousel-3.png',

              title: 'Simplify your HR workflow with an integrated hub',
            },
            {
              image:
                'https://storage.googleapis.com/lica-video/test/carousel-1.png',
              title: 'Simplify your HR workflow with an integrated hub',
            },
          ],
          imageStyle: {
            position: 'absolute',
            width: '1760px',
            height: '580px',
          },
          titleStyle: {
            position: 'absolute',
            top: '737px',
            left: '80.1px',
            fontWeight: '300',
            display: 'inline-block',
            width: '1720px',
            height: '200px',
            mixBlendMode: 'normal',
            fontSize: '90px',
            color: '#000',
            fontFamily: 'Lexend',
          },
          indicatorStyle: { top: '975px', left: '80px' },
        },

        {
          type: 'GROUP',
          components: [],
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            background: 'linear-gradient(180deg, #70ffdd, rgba(112, 255, 221, 0))',
            width: '1920px',
            height: '773.3px',
          },
          from: 0,
        },
      ],
      duration: '15',
      style: {
        width: '100%',
        position: 'relative',
        backgroundColor: '#e8e8e8',
        height: '1920px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '90px',
        color: '#000',
        fontFamily: 'Lexend',
      },
    },
  ],
};

export const CAROUSEL_JSON = {
  fps: 30,
  width: 1080,
  height: 1920,
  slides: [
    {
      components: [
        {
          type: 'WIPE_CAROUSEL',
          items: [
            {
              image:
                'https://storage.googleapis.com/lica-video/22981a2b-4b7b-4e67-b196-ba17b15055a2.png',

              title: 'Sift, streamlined payments worldwide',
            },
            {
              image: 'https://storage.googleapis.com/lica-video/test/carousel-frame-2.png',
              title: 'Onboarding Platform: A New Era of Hiring',
            },
            {
              image: 'https://storage.googleapis.com/lica-video/test/carousel-frame-3.png',
              title: 'Master local compliance effortlessly',
            },
            {
              image: 'https://storage.googleapis.com/lica-video/test/carousel-frame-4.png',
              title: 'Simplify your HR workflow with an integrated hub',
            },
            {
              image: 'https://storage.googleapis.com/lica-video/test/carousel-frame-5.png',
              title: 'Simplify your HR workflow with an integrated hub',
            },
          ],
          imageStyle: {
            width: '920px',
            height: '1200px',
          },
          titleStyle: {
            position: 'absolute',
            top: '1389.5px',
            left: '80.1px',
            lineHeight: '80px',
            fontWeight: '300',
            display: 'inline-block',
            width: '919.8px',
            mixBlendMode: 'normal',
            fontSize: '90px',
            color: '#000',
            fontFamily: 'Lexend',
          },
          indicatorStyle: { top: '1300px', left: '80px' },
        },
      ],
      duration: '15',
      style: {
        width: '100%',
        position: 'relative',
        backgroundColor: '#e8e8e8',
        height: '1920px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '90px',
        color: '#000',
        fontFamily: 'Lexend',
      },
    },
  ],
};

export const CAROUSEL_JSON_3 = {
  fps: 30,
  width: 1080,
  height: 1080,
  slides: [
    {
      components: [
        {
          type: 'CAROUSEL',
          items: [
            {
              image: 'https://storage.googleapis.com/lica-video/test/carousel-3.png',
              title: 'Sift, streamlined payments worldwide',
            },
            {
              image: 'https://storage.googleapis.com/lica-video/test/carousel-1.png',
              title: 'Onboarding Platform: A New Era of Hiring',
            },
            {
              title: 'Master local compliance effortlessly',
              image: 'https://storage.googleapis.com/lica-video/845bc893-b824-4e8e-9894-b0c12d6c177a.png',
            },
            {
              image: 'https://storage.googleapis.com/lica-video/test/carousel-3.png',
              title: 'Simplify your HR workflow with an integrated hub',
            },
            {
              image: 'https://storage.googleapis.com/lica-video/test/carousel-1.png',
              title: 'Simplify your HR workflow with an integrated hub',
            },
          ],
          imageStyle: {
            position: 'absolute',
            width: '920px',
            height: '580px',
          },
          titleStyle: {
            position: 'absolute',
            top: '710px',
            left: '80.1px',
            lineHeight: '80px',
            fontWeight: '300',
            display: 'inline-block',
            width: '920px',
            mixBlendMode: 'normal',
            fontSize: '90px',
            color: '#000',
            fontFamily: 'Lexend',
          },
          indicatorStyle: { top: '975px', left: '80px' },
        },
      ],
      duration: '15',
      style: {
        width: '100%',
        position: 'relative',
        backgroundColor: '#e8e8e8',
        height: '1920px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '90px',
        color: '#000',
        fontFamily: 'Lexend',
      },
    },
    {
      components: [
        {
          type: 'IMAGE',
          src: 'https://i.imgur.com/rvVtvJe.jpeg',
          animation: {
            type: 'breathe',
            attributes: {
              duration: 5,
            },
          },
          style: {
            position: 'absolute',
            width: '100%',
            height: '57%',
          },
          from: 0,
        },
      ],
      duration: '15',
      style: {
        width: '100%',
        position: 'relative',
        backgroundColor: '#e8e8e8',
        height: '1920px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '90px',
        color: '#000',
        fontFamily: 'Lexend',
      },
    },
  ],
};

export const AFRIBLOCKS_JSON = {
  fps: 30,
  width: 324,
  height: 578,
  slides: [
    {
      components: [
        {
          type: 'IMAGE',
          src: 'https://storage.googleapis.com/lica-video/test/afriblocks-logo.png',
          style: {
            position: 'absolute',
            width: '52px',
            height: '27px',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Trends African Freelancers need to Watch in 2024',
          style: {
            position: 'absolute',
            top: 116,
            left: 16,
            width: 100,
            height: 100,
            overflow: 'hidden',
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '36px',
            lineHeight: '40px',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'We take a look at five industry trends that freelancers should keep an eye on in 2024:',
          style: {
            position: 'absolute',
            top: 316,
            left: 16,
            width: 100,
            height: 100,
            overflow: 'hidden',
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '20px',
          },
          from: 0,
        },
      ],
      duration: 3,
      style: {
        display: 'flex',
        'flex-direction': 'column',
        justifyContent: 'space-between',
        padding: '16px',
        background: {
          image:
            'https://storage.googleapis.com/lica-video/test/afriblocks-background.png',
          video: 'https://storage.googleapis.com/lica-video/background.mp4',
          mask: 'rgba(25, 7, 12, 0.70)',
        },
      },
    },
    {
      components: [
        {
          type: 'IMAGE',
          src: 'https://storage.googleapis.com/lica-video/test/afriblocks-logo.png',
          style: {
            width: '52px',
            height: '27px',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Trends African Freelancers need to Watch in 2024',
          style: {
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '36px',
            lineHeight: '40px',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'We take a look at five industry trends that freelancers should keep an eye on in 2024:',
          style: {
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '20px',
          },
          from: 0,
        },
        {
          type: 'VIDEO',
          src: 'https://storage.googleapis.com/lica-video/test/afriblocks-video.mp4',
          from: 0,
        },
      ],
      duration: 3,
      style: {
        display: 'flex',
        'flex-direction': 'column',
        justifyContent: 'space-between',
        padding: '16px',
        background: {
          image:
            'https://storage.googleapis.com/lica-video/test/afriblocks-background.png',
          mask: 'rgba(25, 7, 12, 0.70)',
        },
      },
    },
    {
      components: [
        {
          type: 'IMAGE',
          src: 'https://i.imgur.com/rvVtvJe.jpeg',
          style: {
            width: '52px',
            height: '27px',
          },
          from: 0,
        },
        {
          type: 'QUOTE',
          text: '"We take a look at five industry trends that freelancers should keep an eye on in 2024:"',
          style: {
            color: '#FFF',
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '20px',
          },
          from: 0,
        },
        {
          type: 'VIDEO',
          src: 'https://storage.googleapis.com/lica-video/test/afriblocks-video.mp4',
          from: 0,
        },
      ],
      duration: 3,
      style: {
        display: 'flex',
        'flex-direction': 'column',
        justifyContent: 'space-between',
        padding: '16px',
        background: {
          image:
            'https://storage.googleapis.com/lica-video/test/afriblocks-background.png',
          mask: 'rgba(25, 7, 12, 0.70)',
        },
      },
    },
    {
      components: [
        {
          type: 'IMAGE',
          src: 'https://storage.googleapis.com/lica-video/test/afriblocks-logo.png',
          style: {
            width: '52px',
            height: '27px',
          },
          from: 0,
        },
        {
          type: 'GROUP',
          components: [
            {
              type: 'TEXT',
              text: 'How to sell your shares',
              style: {
                color: 'white',
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '24px',
              },
              from: 0,
            },
            {
              type: 'TEXT',
              text: 'The evolution of remote work tools is empowering freelancers in unprecedented ways.',
              style: {
                marginTop: '8px',
                color: 'rgba(255, 255, 255, 0.90)',
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '16px',
              },
              from: 0,
            },
          ],
          from: 0,
        },
        {
          type: 'CARD_LIST',
          list: [
            'The evolution of remote work tools is empowering freelancers in unprecedented ways.',
            'Freelancing requires a delicate balance of expertise in cutting-edge fields and adaptability to remote work dynamics.',
            'African freelancers encounter a myriad of legal intricacies related to cross-border transactions, taxation, and compliance.',
          ],
          src: 'https://storage.googleapis.com/lica-video/test/afriblocks-video.mp4',
          from: 0,
        },
        {
          type: 'VIDEO',
          src: 'https://storage.googleapis.com/lica-video/test/afriblocks-video.mp4',
          from: 0,
        },
      ],
      duration: 3,
      style: {
        display: 'flex',
        'flex-direction': 'column',
        justifyContent: 'space-between',
        padding: '24px',
        background: {
          image:
            'https://storage.googleapis.com/lica-video/test/afriblocks-background.png',
          mask: 'rgba(25, 7, 12, 0.70)',
        },
      },
    },
  ],
};

export const TEST_JSON = {
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          from: 0,
          style: {
            color: 'Black',
            fontFamily: 'Roboto',
            fontSize: '36px',
            fontWeight: '600',
            lineHeight: '40px',
          },
          text: 'The Power of AI',
          type: 'TEXT',
        },
        {
          from: 0,
          style: {
            color: 'Black',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
          },
          text: 'Revolutionizing Industries',
          type: 'TEXT',
        },
      ],
      duration: 3.5,
      style: {
        background: {
          mask: 'green',
        },
        display: 'uniform',
        padding: '16px',
      },
    },
    {
      components: [
        {
          from: 0,
          style: {
            color: 'Black',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '20px',
          },
          text: 'AI is unlocking new possibilities every day.',
          type: 'QUOTE',
        },
      ],
      duration: 3.5,
      style: {
        background: {
          mask: 'green',
        },
        padding: '16px',
        display: 'uniform',
      },
    },
    {
      components: [
        {
          from: 0,
          style: {
            color: 'Black',
            fontFamily: 'Roboto',
            fontSize: '36px',
            fontWeight: '600',
            lineHeight: '40px',
          },
          text: 'Machine Learning Innovation',
          type: 'TEXT',
        },
        {
          from: 0,
          style: {
            color: 'Black',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
          },
          text: 'Creating Smarter Solutions',
          type: 'TEXT',
        },
      ],
      duration: 3.5,
      style: {
        display: 'uniform',
        padding: '16px',
      },
    },
    {
      components: [
        {
          from: 0,
          style: {
            color: 'Black',
            fontFamily: 'Roboto',
            fontSize: '36px',
            fontWeight: '600',
            lineHeight: '40px',
          },
          text: 'Machine Learning Innovation',
          type: 'TEXT',
        },
        {
          from: 0,
          style: {
            color: 'Black',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
          },
          text: 'Creating Smarter Solutions',
          type: 'TEXT',
        },
      ],
      duration: 3.5,
      style: {
        display: 'uniform',
        padding: '16px',
      },
    },
  ],
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-video/music-library/piano.mp3',
      volume: 0.4,
    },
  ],
  video: {
    src: 'https://storage.googleapis.com/lica-video/7ed85cb8-7a72-473d-9740-a9765c883a78_presenter_video.mp4',
    style: {
      position: 'absolute',
      top: 116,
      left: 16,
      width: 100,
      height: 100,
      zIndex: 1000,
    },
  },
  background: {
    image:
      'https://storage.googleapis.com/lica-video/test/afriblocks-background.png',
    mask: 'rgba(25, 7, 12, 0.70)',
  },
};

export const LAYOUT_1_JSON = {
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          type: 'TEXT',
          text: 'Autoregressive Generation',
          style: {
            color: '#FFE0B2',
            fontFamily: 'Inter',
            fontSize: '36px',
            lineHeight: '40px',
            fontWeight: '500',
          },
          from: 0,
        },
      ],
      style: {
        display: 'uniform',
        padding: '16px',
      },
      duration: 3.5,
    },
    {
      components: [
        {
          type: 'TEXT',
          text: 'The Future of Text Generation',
          style: {
            color: '#FFE0B2',
            fontFamily: 'Inter',
            fontSize: '36px',
            lineHeight: '40px',
            fontWeight: '500',
          },
          from: 0,
        },
      ],
      style: {
        display: 'uniform',
        padding: '16px',
      },
      duration: 3.5,
    },
    {
      components: [
        {
          type: 'TEXT',
          text: 'The Generate Method',
          style: {
            color: '#FFE0B2',
            fontFamily: 'Inter',
            fontSize: '36px',
            lineHeight: '40px',
            fontWeight: '500',
          },
          from: 0,
        },
      ],
      style: {
        display: 'uniform',
        padding: '16px',
      },
      duration: 3.5,
    },
    {
      components: [
        {
          type: 'TEXT',
          text: 'Token Selection',
          style: {
            color: '#FFE0B2',
            fontFamily: 'Inter',
            fontSize: '36px',
            lineHeight: '40px',
            fontWeight: '500',
          },
          from: 0,
        },
      ],
      style: {
        display: 'uniform',
        padding: '16px',
      },
      duration: 3.5,
    },
  ],
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-video/music-library/piano.mp3',
      volume: 0.4,
    },
  ],
  video: {
    src: 'https://storage.googleapis.com/lica-video/7ed85cb8-7a72-473d-9740-a9765c883a78_presenter_video.mp4',
    style: {
      position: 'absolute',
      top: 396,
      left: 20,
      width: 284,
      height: 160,
      zIndex: 1000,
    },
  },
  background: {
    mask: 'linear-gradient(180deg, #FF9800 0%, #F57C00 100%)',
    image: 'https://storage.googleapis.com/lica-video/mohit-bg-test.png',
    maskBlendMode: 'multiply',
  },
};

export const LAYOUT_2_JSON = {
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          type: 'CARD',
          title: {
            text: 'Levels',
            style: {
              color: '#FFE0B2',
              fontFamily: 'Inter',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '500',
              letterSpacing: '0.36px',
            },
          },
          subtitle: {
            text: 'Improving Metabolic Health',
            style: {
              marginTop: '4px',
              color: '#FFF',
              fontFamily: 'Inter',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '500',
              letterSpacing: '0.32px',
            },
          },
          image: {
            type: 'IMAGE',
            // "src": "https://storage.googleapis.com/lica-video/test/afriblocks-logo.png",
            src: 'https://www.thedesignwork.com/wp-content/uploads/2011/10/Random-Pictures-of-Conceptual-and-Creative-Ideas-01.jpg',
            style: {
              marginTop: '8px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            },
          },
          style: {
            backgroundColor: '#3D1F00',
            width: '292px',
            height: '240px',
            borderRadius: '8px',
            padding: '8px',
          },
          from: 0,
        },
        {
          type: 'GROUP',
          components: [
            {
              type: 'TEXT',
              text: 'How to sell your shares',
              style: {
                position: 'absolute',
                top: 70,
                color: 'white',
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '24px',
              },
              from: 0,
            },
          ],
          style: {
            position: 'absolute',
            top: 200,
            width: '292px',
            height: '240px',
            color: 'rgba(255, 255, 255, 0.90)',
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
          },
          from: 0,
        },
      ],
      style: {
        display: 'uniform',
        padding: '16px',
      },
      duration: 3.5,
    },
  ],
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-video/music-library/piano.mp3',
      volume: 0.4,
    },
  ],
  video: {
    src: 'https://storage.googleapis.com/lica-video/7ed85cb8-7a72-473d-9740-a9765c883a78_presenter_video.mp4',
    style: {
      position: 'absolute',
      top: 396,
      left: 20,
      width: 284,
      height: 160,
      zIndex: 1000,
    },
  },
  background: {
    mask: 'linear-gradient(180deg, #FF9800 0%, #F57C00 100%)',
    image: 'https://storage.googleapis.com/lica-video/mohit-bg-test.png',
    maskBlendMode: 'multiply',
  },
};

export const DIVYAM = {
  fps: 30,
  width: 304,
  height: 540,
  slides: [
    {
      components: [
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            backgroundColor: '#fbfbfb',
            width: '304px',
            height: '540px',
          },
          components: [
            {
              type: 'GROUP',
              style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '12px',
                // "padding":"12px",
                width: '280px',
                height: '516px',
                position: 'absolute',
                top: '12px',
                left: '12px',
              },
              components: [
                {
                  type: 'GROUP',
                  style: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px',
                    flex: '1',
                    overflow: 'hidden',
                  },
                  components: [
                    {
                      type: 'IMAGE',
                      src: 'https://storage.googleapis.com/lica-video/mohit-bg-test.png',
                      style: {
                        borderRadius: '4px',
                        objectFit: 'cover',
                        flex: '1',
                        maxWidth: '100%',
                        maxHeight: '100%',
                      },
                      from: 0,
                    },
                    {
                      type: 'TEXT',
                      text: 'Roots',
                      style: {
                        color: '#371d1a',
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        fontSize: '14px',
                      },
                      from: 0,
                    },
                    {
                      type: 'TEXT',
                      text: 'Revolutionizing Home Ownership',
                      style: {
                        color: '#454545',
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        fontSize: '13px',
                      },
                      from: 0,
                    },
                  ],
                  from: 0,
                },
                {
                  type: 'GROUP',
                  style: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px',
                    flex: '1',
                    overflow: 'hidden',
                  },
                  components: [
                    {
                      type: 'TEXT',
                      text: 'Loyal',
                      style: {
                        color: '#371d1a',
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        fontSize: '14px',
                      },
                      from: 0,
                    },
                    {
                      type: 'TEXT',
                      text: 'Pioneering Longevity for Dogs and Humans',
                      style: {
                        color: '#454545',
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        fontSize: '13px',
                      },
                      from: 0,
                    },
                    {
                      type: 'IMAGE',
                      src: 'https://storage.googleapis.com/lica-video/mohit-bg-test.png',
                      style: {
                        borderRadius: '4px',
                        objectFit: 'cover',
                        flex: '1',
                        maxWidth: '100%',
                        maxHeight: '100%',
                      },
                      from: 0,
                    },
                  ],
                  from: 0,
                },
                {
                  type: 'GROUP',
                  style: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '12px',
                    flex: '1',
                    overflow: 'hidden',
                  },
                  components: [
                    {
                      type: 'IMAGE',
                      src: 'https://storage.googleapis.com/lica-video/mohit-bg-test.png',
                      style: {
                        borderRadius: '4px',
                        objectFit: 'cover',
                        flex: '1',
                        maxWidth: '100%',
                        maxHeight: '100%',
                      },
                      from: 0,
                    },
                    {
                      type: 'TEXT',
                      text: 'Primer',
                      style: {
                        color: '#371d1a',
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        fontSize: '14px',
                      },
                      from: 0,
                    },
                    {
                      type: 'TEXT',
                      text: 'Reimagining Education',
                      style: {
                        color: '#454545',
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        fontSize: '13px',
                      },
                      from: 0,
                    },
                  ],
                  from: 0,
                },
                {
                  type: 'GROUP',
                  components: [],
                  style: {
                    backgroundColor: '#000',
                    borderRadius: '4px',
                    width: '279px',
                    height: '151px',
                  },
                  from: 0,
                },
              ],
              from: 0,
            },
          ],
          from: 0,
        },
      ],
      duration: '3',
      style: {
        padding: '0',
        background: {
          mask: '#fff',
        },
        height: '540px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '12px',
        color: '#fff4b7',
        fontFamily: 'Poppins',
      },
    },
  ],
};

export const DIVYAM_2 = {
  title: 'The Elon Musk Story',
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          type: 'TEXT',
          text: 'Visionary Entrepreneur',
          style: {
            color: '#CCCCCC',
            fontFamily: 'Merriweather',
            fontSize: '36px',
            lineHeight: '40px',
            fontWeight: '500',
          },
          link: 'https://vercel.com/try/AI-Night-By-Vercel-SF',
          animation: {
            type: 'typewriter',
            attributes: {
              divider: 'chars',
            },
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'The Story of Elon Musk',
          style: {
            color: '#CDE6FE',
            fontFamily: 'Source Sans Pro',
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: '500',
          },
          from: 0,
          animation: {
            type: 'spread',
            attributes: {
              divider: 'chars',
            },
          },
        },
      ],
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '16px',
      },
      duration: 6.6,
    },
    {
      components: [
        {
          type: 'CARD',
          title: {
            text: 'Innovation',
            style: {
              color: '#CDE6FE',
              fontFamily: 'Merriweather',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '500',
              letterSpacing: '0.36px',
            },
          },
          subtitle: {
            text: 'Revolutionizing technology',
            style: {
              marginTop: '4px',
              color: '#9BCDFD',
              fontFamily: 'Source Sans Pro',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '500',
              letterSpacing: '0.32px',
            },
          },
          image: {
            type: 'IMAGE',
            src: 'https://storage.googleapis.com/lica-video/3642df0a-f68d-45d6-9065-55a24a549744.jpeg',
            style: {
              marginTop: '8px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            },
          },
          style: {
            background: '#033563',
            width: '284px',
            height: '240px',
            borderRadius: '8px',
            padding: '8px',
          },
          from: 0,
          animation: {
            type: 'pan',
            attributes: {
              divider: 'chars',
            },
          },
        },
      ],
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px',
      },
      duration: 6.4,
    },
    {
      components: [
        {
          type: 'CARD',
          title: {
            text: 'Impact',
            style: {
              color: '#CDE6FE',
              fontFamily: 'Merriweather',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '500',
              letterSpacing: '0.36px',
            },
          },
          subtitle: {
            text: 'Changing the world',
            style: {
              marginTop: '4px',
              color: '#9BCDFD',
              fontFamily: 'Source Sans Pro',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '500',
              letterSpacing: '0.32px',
            },
          },
          image: {
            type: 'IMAGE',
            src: 'https://storage.googleapis.com/lica-video/b3b20265-c9c9-4866-b029-6e3771a15cb5.jpeg',
            style: {
              marginTop: '8px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            },
          },
          style: {
            background: '#033563',
            width: '284px',
            height: '240px',
            borderRadius: '8px',
            padding: '8px',
          },
          from: 0,
          animate: 'wipe',
        },
      ],
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px',
      },
      duration: 8.0,
    },
  ],
  duration: 20.51,
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-video/90a80720-4023-4271-ad2a-92221d8c236a_audio.mp3',
      volume: 1,
    },
    {
      src: 'https://storage.googleapis.com/lica-video/music-library/piano.mp3',
      volume: 0.4,
    },
  ],
  background: {
    image:
      'https://storage.googleapis.com/lica-video/0bb831b8-1e3b-43ee-9f58-62cf47fd82dd.jpeg',
    imageStyle: {
      tint: 'linear-gradient(180deg, #0F0A0A 0%, #3D2929 100%)',
      blendMode: 'soft-light',
      exposure: '50%',
      contrast: '30%',
    },
  },
  // "slideTransition": "flip",
};

export const TEST = {
  title: 'Exploring Elon Musk',
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          type: 'TEXT',
          text: 'The Mind of Musk',
          style: {
            color: '#EDF7EF',
            fontFamily: '{font_1}',
            fontSize: '36px',
            lineHeight: '40px',
            fontWeight: '500',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Unraveling the enigma of Elon Musk',
          style: {
            color: '#DCEFE0',
            fontFamily: '{font_2}',
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: '500',
          },
          from: 0,
        },
      ],
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '16px',
      },
      duration: 5.4,
    },
    {
      components: [
        {
          type: 'CARD',
          title: {
            text: 'Visionary',
            style: {
              color: '#DCEFE0',
              fontFamily: '{font_1}',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '500',
              letterSpacing: '0.36px',
            },
          },
          subtitle: {
            text: 'Revolutionary',
            style: {
              marginTop: '4px',
              color: '#CAE7D0',
              fontFamily: '{font_2}',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '500',
              letterSpacing: '0.32px',
            },
          },
          image: {
            type: 'IMAGE',
            src: 'https://storage.googleapis.com/lica-video/1747aef7-c1cd-40b5-b53d-4712f8ab030a.jpeg',
            style: {
              marginTop: '8px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            },
          },
          style: {
            background: 'linear-gradient(180deg, #19381F 0%, #1F4727 100%)',
            width: '284px',
            height: '240px',
            borderRadius: '8px',
            padding: '8px',
          },
          from: 0,
        },
      ],
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px',
      },
      duration: 7.0,
    },
    {
      components: [
        {
          type: 'CARD',
          title: {
            text: 'Trailblazer',
            style: {
              color: '#DCEFE0',
              fontFamily: '{font_1}',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '500',
              letterSpacing: '0.36px',
            },
          },
          subtitle: {
            text: 'Pioneering',
            style: {
              marginTop: '4px',
              color: '#CAE7D0',
              fontFamily: '{font_2}',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '500',
              letterSpacing: '0.32px',
            },
          },
          image: {
            type: 'IMAGE',
            src: 'https://storage.googleapis.com/lica-video/1b07fd81-d780-4a33-87af-f19639d9867e.jpeg',
            style: {
              marginTop: '8px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            },
          },
          style: {
            backgroundColor:
              'linear-gradient(180deg, #19381F 0%, #1F4727 100%)',
            width: '284px',
            height: '240px',
            borderRadius: '8px',
            padding: '8px',
          },
          from: 0,
        },
      ],
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px',
      },
      duration: 7.700000000000001,
    },
    {
      components: [
        {
          type: 'CARD',
          title: {
            text: 'Innovative',
            style: {
              color: '#DCEFE0',
              fontFamily: '{font_1}',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '500',
              letterSpacing: '0.36px',
            },
          },
          subtitle: {
            text: 'Influential',
            style: {
              marginTop: '4px',
              color: '#CAE7D0',
              fontFamily: '{font_2}',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '500',
              letterSpacing: '0.32px',
            },
          },
          image: {
            type: 'IMAGE',
            src: 'https://storage.googleapis.com/lica-video/911f3196-a27b-451f-a72e-c4f3b06aa3ce.jpeg',
            style: {
              marginTop: '8px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            },
          },
          style: {
            backgroundColor:
              'linear-gradient(180deg, #19381F 0%, #1F4727 100%)',
            width: '284px',
            height: '240px',
            borderRadius: '8px',
            padding: '8px',
          },
          from: 0,
        },
      ],
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px',
      },
      duration: 9.2,
    },
    {
      components: [
        {
          type: 'CARD',
          title: {
            text: 'Entrepreneur',
            style: {
              color: '#DCEFE0',
              fontFamily: '{font_1}',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: '500',
              letterSpacing: '0.36px',
            },
          },
          subtitle: {
            text: 'Enterprising',
            style: {
              marginTop: '4px',
              color: '#CAE7D0',
              fontFamily: '{font_2}',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: '500',
              letterSpacing: '0.32px',
            },
          },
          image: {
            type: 'IMAGE',
            src: 'https://storage.googleapis.com/lica-video/ec1756e0-3c7e-4c73-a120-aa2a86f283be.jpeg',
            style: {
              marginTop: '8px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            },
          },
          style: {
            backgroundColor:
              'linear-gradient(180deg, #19381F 0%, #1F4727 100%)',
            width: '284px',
            height: '240px',
            borderRadius: '8px',
            padding: '8px',
          },
          from: 0,
        },
      ],
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px',
      },
      duration: 9.599999999999998,
    },
  ],
  duration: 38.27,
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-video/e9c3adb9-b799-4dbf-acf8-153cf717983e_audio.mp3',
      volume: 1,
    },
    {
      src: 'https://storage.googleapis.com/lica-video/music-library/piano.mp3',
      volume: 0.4,
    },
  ],
  background: {
    mask: 'linear-gradient(180deg, #FF9800 0%, #F57C00 100%)',
    image:
      'https://storage.googleapis.com/lica-video/32e0ad1d-fad6-46ce-9bd8-633e38a4f4e3.jpeg',
    maskBlendMode: 'multiply',
  },
  slideTransition: 'fade',
};

export const TEST_2 = {
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          animation: {
            attributes: {
              divider: 'lines',
              duration: 2,
              stagger: 0.6,
            },
            type: 'rise',
          },
          id: '0-0-0',
          style: {
            alignContent: 'center',
            backgroundColor: '#f1ff53',
            borderRadius: '157.55px',
            display: 'inline-block',
            fontSize: '145px',
            height: '150.9px',
            left: '0px',
            mixBlendMode: 'normal',
            padding: '20px 80px',
            position: 'absolute',
            textAlign: 'center',
            top: '15.4px',
            width: '300px',
          },
          text: 'Video Generation',
          type: 'TEXT',
        },
      ],
      duration: 6.6,
      style: {
        backgroundColor: '#000000',
        color: '#000000',
        fontFamily: 'Haffer',
        fontSize: '180px',
        height: '1920px',
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
      },
    },
    {
      components: [
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            backgroundColor: '#E0FFF7',
            width: '324px',
            height: '576px',
          },
          components: [],
          from: 0,
        },
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '196px',
            left: '0px',
            borderRadius: '0px 133px 133px 0px',
            backgroundColor: '#CBFFB6',
            width: '295px',
            height: '272px',
          },
          components: [],
          from: 0,
        },
        {
          type: 'SVG',
          src: 'https://storage.googleapis.com/lica-video/a12fefe2-30c7-4f82-901a-452043af8e35_img.svg',
          style: {
            position: 'absolute',
            top: '400px',
            left: '203px',
            borderRadius: '7px',
            width: '92px',
            height: '92px',
          },
          from: 0,
        },
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '91px',
            left: '67px',
            borderRadius: '50%',
            backgroundColor: '#c2bcde',
            width: '165px',
            height: '165px',
          },
          components: [],
          from: 0,
        },
        {
          type: 'IMAGE',
          src: 'https://storage.googleapis.com/lica-video/ca69ebd2-b035-4b1b-a224-90d8c52a0fc3.jpeg',
          style: {
            position: 'absolute',
            top: '91px',
            left: '67px',
            borderRadius: '50%',
            width: '165px',
            height: '165px',
            objectFit: 'cover',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: "Facebook's approach to data privacy has sparked debates and controversies, shaping the narrative around online security and personal information protection.",
          style: {
            position: 'absolute',
            top: '282px',
            left: '51px',
            letterSpacing: '0.02em',
            lineHeight: 'normal',
            fontWeight: '300',
            display: 'inline-block',
            width: '211px',
            height: '99px',
            color: '#000000',
            fontFamily: 'DM Mono',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Data Privacy',
          style: {
            position: 'absolute',
            top: '35px',
            left: '0px',
            letterSpacing: '0.02em',
            lineHeight: '50px',
            fontWeight: '500',
            textAlign: 'center',
            display: 'inline-block',
            width: '324px',
            heigt: '20px',
            color: '#000000',
            fontFamily: 'DM Mono',
            fontSize: '20px',
          },
          from: 0,
        },
      ],
      duration: 8.5,
      style: {
        width: '100%',
        position: 'relative',
        height: '576px',
        textAlign: 'left',
        fontSize: '15px',
        color: '#0e0034',
        fontFamily: 'DM Mono',
      },
    },
    {
      components: [
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            backgroundColor: '#E0FFF7',
            width: '324px',
            height: '576px',
          },
          components: [],
          from: 0,
        },
        {
          type: 'SVG',
          style: {
            position: 'absolute',
            top: '340px',
            left: '0px',
            width: '324.5px',
            height: '236px',
          },
          src: 'https://storage.googleapis.com/lica-video/b43177dc-ab61-4453-964d-0bed919eb562_img.svg',
          from: 0,
        },
        {
          type: 'IMAGE',
          style: {
            position: 'absolute',
            top: '300px',
            left: '78px',
            borderRadius: '50%',
            width: '166px',
            height: '166px',
            objectFit: 'cover',
          },
          src: 'https://storage.googleapis.com/lica-video/2a3d4d72-60b9-42b7-a454-0c04a34e53be.jpeg',
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Innovative Technology',
          style: {
            position: 'absolute',
            top: 'calc(50% - 232px)',
            left: 'calc(50% - 146px)',
            lineHeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '292px',
            height: '119px',
            fontSize: '50px',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Pioneering digital advancements.',
          style: {
            position: 'absolute',
            top: '200px',
            left: 'calc(50% - 146px)',
            fontSize: '16px',
            lineHeight: 'normal',
            fontWeight: '300',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '292px',
            height: '21px',
          },
          from: 0,
        },
      ],
      duration: 8.1,
      style: {
        width: '100%',
        position: 'relative',
        height: '576px',
        overflow: 'hidden',
        textAlign: 'center',
        fontSize: '50px',
        color: '#000000',
        fontFamily: 'DM Mono',
      },
    },
    {
      components: [
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            backgroundColor: '#E0FFF7',
            width: '324px',
            height: '576px',
          },
          components: [],
          from: 0,
        },
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '196px',
            left: '0px',
            borderRadius: '0px 133px 133px 0px',
            backgroundColor: '#CBFFB6',
            width: '295px',
            height: '272px',
          },
          components: [],
          from: 0,
        },
        {
          type: 'SVG',
          src: 'https://storage.googleapis.com/lica-video/d7abb4b7-9440-4d9b-acb3-c1d35512c651_img.svg',
          style: {
            position: 'absolute',
            top: '400px',
            left: '203px',
            borderRadius: '7px',
            width: '92px',
            height: '92px',
          },
          from: 0,
        },
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '91px',
            left: '67px',
            borderRadius: '50%',
            backgroundColor: '#c2bcde',
            width: '165px',
            height: '165px',
          },
          components: [],
          from: 0,
        },
        {
          type: 'IMAGE',
          src: 'https://storage.googleapis.com/lica-video/a4f576d2-8ed9-48a4-8663-06165abb7b82.jpeg',
          style: {
            position: 'absolute',
            top: '91px',
            left: '67px',
            borderRadius: '50%',
            width: '165px',
            height: '165px',
            objectFit: 'cover',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Facebook serves as a thriving ecosystem for community building and engagement, enabling diverse groups to connect and interact on a global scale.',
          style: {
            position: 'absolute',
            top: '282px',
            left: '51px',
            letterSpacing: '0.02em',
            lineHeight: 'normal',
            fontWeight: '300',
            display: 'inline-block',
            width: '211px',
            height: '99px',
            color: '#000000',
            fontFamily: 'DM Mono',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Community Engagement',
          style: {
            position: 'absolute',
            top: '35px',
            left: '0px',
            letterSpacing: '0.02em',
            lineHeight: '50px',
            fontWeight: '500',
            textAlign: 'center',
            display: 'inline-block',
            width: '324px',
            heigt: '20px',
            color: '#000000',
            fontFamily: 'DM Mono',
            fontSize: '20px',
          },
          from: 0,
        },
      ],
      duration: 9.000000000000004,
      style: {
        width: '100%',
        position: 'relative',
        height: '576px',
        textAlign: 'left',
        fontSize: '15px',
        color: '#0e0034',
        fontFamily: 'DM Mono',
      },
    },
    {
      components: [
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            backgroundColor: '#E0FFF7',
            width: '324px',
            height: '576px',
          },
          components: [],
          from: 0,
        },
        {
          type: 'SVG',
          style: {
            position: 'absolute',
            top: '340px',
            left: '0px',
            width: '324.5px',
            height: '236px',
          },
          src: 'https://storage.googleapis.com/lica-video/9a20416f-1ad7-41fe-bf79-a0fe04bcaf65_img.svg',
          from: 0,
        },
        {
          type: 'IMAGE',
          style: {
            position: 'absolute',
            top: '300px',
            left: '78px',
            borderRadius: '50%',
            width: '166px',
            height: '166px',
            objectFit: 'cover',
          },
          src: 'https://storage.googleapis.com/lica-video/bb7d7686-b0b1-4d93-8302-e3efd78d579b.jpeg',
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Content Moderation',
          style: {
            position: 'absolute',
            top: 'calc(50% - 232px)',
            left: 'calc(50% - 146px)',
            lineHeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '292px',
            height: '119px',
            fontSize: '50px',
          },
          from: 0,
        },
        {
          type: 'TEXT',
          text: 'Navigating digital content ethics.',
          style: {
            position: 'absolute',
            top: '200px',
            left: 'calc(50% - 146px)',
            fontSize: '16px',
            lineHeight: 'normal',
            fontWeight: '300',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '292px',
            height: '21px',
          },
          from: 0,
        },
      ],
      duration: 9.699999999999996,
      style: {
        width: '100%',
        position: 'relative',
        height: '576px',
        overflow: 'hidden',
        textAlign: 'center',
        fontSize: '50px',
        color: '#000000',
        fontFamily: 'DM Mono',
      },
    },
  ],
  duration: 40.54,
  audios: [],
};

export const TURING_INTERACTIVE = {
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          type: 'TEXT',
          componentStyle: 'TITLE_POPUP',
          text: 'SplitText makes it easy to break apart the text in an HTML element so that each character, word, and/or line is wrapped in its own div tag.',
          style: {
            width: '100%',
            position: 'absolute',
            height: '576px',
          },
          itemStyles: {
            fontSize: '30px',
            background: '#A1B3F9',
            borderRadius: '100px',
            padding: '10px',
            color: 'black',
          },
          animation: {
            attributes: {
              divider: 'lines',
              duration: 1,
              stagger: 0.6,
            },
          },
        },
      ],
      duration: 10,
      style: {
        width: '100%',
        position: 'relative',
        height: '576px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '16px',
        color: '#FFF4EA',
        backgroundColor: 'gray',
        fontFamily: 'Work Sans',
      },
    },
    {
      components: [
        {
          type: 'MINIMAL_LISTICLE',
          data: [
            {
              itemTexts: [
                {
                  title: 'Magic',
                  subtitle: 'Spellbinding World',
                },
              ],
              itemStyles: [
                {
                  titleStyle: {
                    color: 'white',
                    fontWeight: 500,
                  },
                  subtitleStyle: {
                    color: 'white',
                  },
                  lineStyle: {
                    backgroundColor: 'white',
                  },
                },
                {
                  titleStyle: {
                    color: 'white',
                    fontWeight: 500,
                  },
                  subtitleStyle: {
                    color: 'white',
                  },
                  lineStyle: {
                    backgroundColor: 'white',
                  },
                },
                {
                  titleStyle: {
                    color: 'white',
                    fontWeight: 500,
                  },
                  subtitleStyle: {
                    color: 'white',
                  },
                  lineStyle: {
                    backgroundColor: 'white',
                  },
                },
              ],
            },
          ],
          id: '0-0',
        },
        {
          type: 'IMAGE',
          src: 'https://storage.googleapis.com/lica-video/96ad5e8a-0f4f-4f9a-b569-b601fe4c93ae.jpeg',
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '480.2px',
            height: '620.4px',
            objectFit: 'cover',
            objectPosition: 'center',
            zIndex: '-10',
          },
          from: 0,
        },
      ],
      duration: 5,
      style: {
        width: '324px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        boxSizing: 'border-box',
        letterSpacing: 'normal',
        position: 'relative',
      },
    },
  ],
};

export const TURING_INTERACTIVE_1 = {
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          type: 'GROUP',
          components: [
            {
              type: 'TEXT',
              text: 'TuringPost',
              style: {
                alignSelf: 'stretch',
                position: 'relative',
                zIndex: '1',
                fontSize: '16px',
                color: '#50006c',
                fontFamily: 'Inter',
                textAlign: 'left',
                padding: '0px',
                lineHeight: 'normal',
              },
              from: 0,
            },
            {
              type: 'GROUP',
              components: [
                {
                  type: 'TEXT',
                  text: 'Exciting Research Papers',
                  style: {
                    margin: '0',
                    flex: '1',
                    position: 'relative',
                    fontSize: '36px',
                    fontWeight: '400',
                    fontFamily: "'Libre Baskerville'",
                    // "display": "flex",
                    alignItems: 'center',
                    zIndex: '1',
                    textAlign: 'left',
                    width: '228px',
                    height: '256px',
                  },
                  animation: {
                    type: 'typewriter',
                    attributes: {
                      divider: 'chars',
                      duration: 1,
                      factorDuration: 2,
                    },
                  },
                  from: 0,
                },
              ],
              style: {
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: '0px',
                fontSize: '36px',
                fontFamily: "'Libre Baskerville'",
                color: '#50006c',
                textAlign: 'left',
              },
              from: 0,
            },
            {
              type: 'TEXT',
              text: 'April 3',
              style: {
                alignSelf: 'stretch',
                position: 'relative',
                zIndex: '1',
                fontSize: '16px',
                color: '#50006c',
                fontFamily: 'Inter',
                textAlign: 'left',
                padding: '0px',
                lineHeight: 'normal',
              },
              from: 0,
              animation: {
                type: 'rise',
              },
            },
          ],
          style: {
            flex: '1',
            borderRadius: '16px',
            backgroundColor: '#f1ead0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '42px 24px 44px',
            gap: '62px',
            textAlign: 'left',
            fontSize: '16px',
            color: '#50006c',
            fontFamily: 'Inter',
            overflow: 'hidden',
          },
          from: 0,
        },
      ],
      duration: '4',
      style: {
        width: '324px',
        backgroundColor: '#50006c',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '24px',
        boxSizing: 'border-box',
        letterSpacing: 'normal',
        position: 'relative',
      },
      audios: [
        {
          src: 'https://storage.googleapis.com/lica-video/test/turing-1.mp3',
          volume: 1,
        },
      ],
    },
    {
      components: [
        {
          type: 'GROUP',
          style: {
            flex: '1',
            borderRadius: '16px',
            backgroundColor: '#f1ead0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '35px 21px 81px',
            gap: '28px',
            textAlign: 'left',
            fontSize: '14px',
            color: '#50006c',
            fontFamily: "'Libre Baskerville'",
          },
          components: [
            {
              type: 'GROUP',
              style: {
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: '0px 5px 9px',
                height: '44px',
                width: '228px',
              },
              components: [
                {
                  type: 'TEXT',
                  text: 'Our Top',
                  style: {
                    margin: '0',
                    flex: '1',
                    position: 'relative',
                    fontSize: '36px',
                    lineHeight: '40px',
                    fontWeight: '400',
                    fontFamily: 'inherit',
                    zIndex: '1',
                  },
                },
              ],
            },
            {
              type: 'GROUP',
              animation: {
                type: 'rise',
              },
              style: {
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: '0px 0px 15px',
              },
              components: [
                {
                  type: 'TEXT',
                  text: 'BioMedLM: A 2.7B Parameter Language Model Trained On Biomedical Text',
                  style: {
                    alignSelf: 'stretch',
                    position: 'relative',
                    lineHeight: '24px',
                    zIndex: '1',
                  },
                },
                {
                  type: 'TEXT',
                  text: 'Read More',
                  link: 'https://arxiv.org/pdf/2403.18421.pdf',
                  style: {
                    position: 'relative',
                    textDecoration: 'underline',
                    lineHeight: '24px',
                    display: 'flex',
                    minWidth: '67px',
                    zIndex: '1',
                    fontSize: '11px',
                    alignSelf: 'stretch',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                  },
                },
              ],
            },
            {
              type: 'GROUP',
              animation: {
                type: 'rise',
                attributes: {
                  delay: 15,
                },
              },
              style: {
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: '0px 0px 15px',
              },
              components: [
                {
                  type: 'TEXT',
                  text: 'AutoBNN: Probabilistic Time Series Forecasting with Compositional Bayesian Neural Networks',
                  style: {
                    alignSelf: 'stretch',
                    position: 'relative',
                    lineHeight: '24px',
                    zIndex: '1',
                  },
                },
                {
                  type: 'TEXT',
                  text: 'Read More',
                  link: 'https://blog.research.google/2024/03/autobnn-probabilistic-time-series.html',
                  style: {
                    position: 'relative',
                    textDecoration: 'underline',
                    lineHeight: '24px',
                    display: 'flex',
                    minWidth: '67px',
                    zIndex: '1',
                    fontSize: '11px',
                    alignSelf: 'stretch',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                  },
                },
              ],
            },
            {
              type: 'GROUP',
              animation: {
                type: 'rise',
                attributes: {
                  delay: 28,
                },
              },
              style: {
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              },
              components: [
                {
                  type: 'TEXT',
                  text: 'Learning from interaction with Microsoft Copilot (web)',
                  style: {
                    alignSelf: 'stretch',
                    height: '60px',
                    position: 'relative',
                    lineHeight: '20px',
                    display: 'inline-block',
                    flexShrink: '0',
                    zIndex: '1',
                  },
                },
                {
                  type: 'TEXT',
                  text: 'Read More',
                  link: 'https://www.microsoft.com/en-us/research/blog/learning-from-interaction-with-microsoft-copilot-web/',
                  style: {
                    position: 'relative',
                    textDecoration: 'underline',
                    lineHeight: '24px',
                    display: 'flex',
                    minWidth: '67px',
                    zIndex: '1',
                    fontSize: '11px',
                    alignSelf: 'stretch',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    marginTop: '-11px',
                  },
                },
              ],
            },
          ],
          from: 0,
        },
      ],
      duration: '39',
      style: {
        width: '324px',
        backgroundColor: '#50006c',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '24px',
        boxSizing: 'border-box',
        letterSpacing: 'normal',
      },
      audios: [
        {
          src: 'https://storage.googleapis.com/lica-video/test/turing-2.mp3',
          volume: 1,
        },
      ],
    },
    {
      components: [
        {
          type: 'GROUP',
          style: {
            width: '324px',
            backgroundColor: '#50006c',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '24px',
            boxSizing: 'border-box',
            letterSpacing: 'normal',
          },
          components: [
            {
              type: 'GROUP',
              style: {
                height: '528px',
                flex: '1',
                borderRadius: '16px',
                backgroundColor: '#f1ead0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: '35px 17px 32px',
                gap: '32px',
                textAlign: 'left',
                fontSize: '12px',
                color: '#50006c',
                fontFamily: "'Libre Baskerville'",
              },
              components: [
                {
                  type: 'TEXT',
                  text: 'Large Language Model (LLM) Innovations',
                  style: {
                    alignSelf: 'stretch',
                    fontSize: '16px',
                    fontWeight: '700',
                    lineHeight: '24px',
                    padding: '0px 5px 0px 4px',
                  },
                },
                {
                  type: 'GROUP',
                  animation: {
                    type: 'rise',
                  },
                  style: {
                    alignSelf: 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    padding: '0px 0px 15px',
                  },
                  components: [
                    {
                      type: 'TEXT',
                      text: 'Gecko: Versatile Text Embeddings Distilled from Large Language Models',
                      style: {
                        flex: '1',
                        lineHeight: '20px',
                      },
                    },
                    {
                      type: 'TEXT',
                      text: 'Read More',
                      link: 'https://arxiv.org/pdf/2403.20327.pdf',
                      style: {
                        position: 'relative',
                        textDecoration: 'underline',
                        lineHeight: '24px',
                        display: 'flex',
                        minWidth: '67px',
                        zIndex: '1',
                        fontSize: '11px',
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                      },
                    },
                  ],
                },
                {
                  type: 'GROUP',
                  animation: {
                    type: 'rise',
                    attributes: {
                      delay: 9,
                    },
                  },
                  style: {
                    width: '237px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    padding: '0px 0px 3px',
                    boxSizing: 'border-box',
                  },
                  components: [
                    {
                      type: 'TEXT',
                      text: 'Updating Large Language Models by Directly Editing Network Layers',
                      style: {
                        lineHeight: '20px',
                      },
                    },
                    {
                      type: 'TEXT',
                      text: 'Read More',
                      link: 'https://www.amazon.science/blog/updating-large-language-models-by-directly-editing-network-layers',
                      style: {
                        position: 'relative',
                        textDecoration: 'underline',
                        lineHeight: '24px',
                        display: 'flex',
                        minWidth: '67px',
                        zIndex: '1',
                        fontSize: '11px',
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                      },
                    },
                  ],
                },
                {
                  type: 'GROUP',
                  animation: {
                    type: 'rise',
                    attributes: {
                      delay: 15,
                    },
                  },
                  style: {
                    width: '237px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    padding: '0px 0px 4px',
                    boxSizing: 'border-box',
                  },
                  components: [
                    {
                      type: 'TEXT',
                      text: 'AIOS: LLM Agent Operating System',
                      style: {
                        lineHeight: '20px',
                      },
                    },
                    {
                      type: 'TEXT',
                      text: 'Read More',
                      link: 'https://arxiv.org/pdf/2403.16971.pdf',
                      style: {
                        position: 'relative',
                        textDecoration: 'underline',
                        lineHeight: '24px',
                        display: 'flex',
                        minWidth: '67px',
                        zIndex: '1',
                        fontSize: '11px',
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                      },
                    },
                  ],
                },
              ],
            },
          ],
          from: 0,
        },
      ],
      duration: '20',
      style: {},
      audios: [
        {
          src: 'https://storage.googleapis.com/lica-video/test/turing-3.mp3',
          volume: 1,
        },
      ],
    },
    {
      components: [
        {
          type: 'GROUP',
          style: {
            width: '324px',
            backgroundColor: '#50006c',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '24px',
            boxSizing: 'border-box',
            letterSpacing: 'normal',
          },
          components: [
            {
              type: 'GROUP',
              style: {
                height: '528px',
                flex: '1',
                borderRadius: '16px',
                backgroundColor: '#f1ead0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: '35px 17px 32px',
                gap: '40px',
                textAlign: 'left',
                fontSize: '12px',
                color: '#50006c',
                fontFamily: "'Libre Baskerville'",
              },
              components: [
                {
                  type: 'TEXT',
                  text: 'Large Language Model (LLM) Innovations',
                  style: {
                    alignSelf: 'stretch',
                    fontSize: '16px',
                    fontWeight: '700',
                    lineHeight: '24px',
                    padding: '0px 5px 0px 4px',
                  },
                },
                {
                  type: 'GROUP',
                  animation: {
                    type: 'rise',
                  },
                  style: {
                    width: '237px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  },
                  components: [
                    {
                      type: 'TEXT',
                      text: 'Long-form Factuality in Large Language Models',
                      style: {
                        lineHeight: '20px',
                      },
                    },
                    {
                      type: 'TEXT',
                      text: 'Read More',
                      link: 'https://arxiv.org/pdf/2403.18802.pdf',
                      style: {
                        position: 'relative',
                        textDecoration: 'underline',
                        lineHeight: '24px',
                        display: 'flex',
                        minWidth: '67px',
                        zIndex: '1',
                        fontSize: '11px',
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                      },
                    },
                  ],
                },
                {
                  type: 'GROUP',
                  animation: {
                    type: 'rise',
                    attributes: {
                      delay: 5,
                    },
                  },
                  style: {
                    alignSelf: 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    padding: '0px 0px 15px',
                  },
                  components: [
                    {
                      type: 'TEXT',
                      text: 'sDPO: Don’t Use Your Data All at Once',
                      style: {
                        flex: '1',
                        lineHeight: '20px',
                      },
                    },
                    {
                      type: 'TEXT',
                      text: 'Read More',
                      link: 'https://arxiv.org/pdf/2403.19270.pdf',
                      style: {
                        position: 'relative',
                        textDecoration: 'underline',
                        lineHeight: '24px',
                        display: 'flex',
                        minWidth: '67px',
                        zIndex: '1',
                        fontSize: '11px',
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                      },
                    },
                  ],
                },
                {
                  type: 'GROUP',
                  animation: {
                    type: 'rise',
                    attributes: {
                      delay: 11,
                    },
                  },
                  style: {
                    width: '237px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  },
                  components: [
                    {
                      type: 'TEXT',
                      text: 'Can Large Language Models Explore In-Context?',
                      style: {
                        lineHeight: '20px',
                      },
                    },
                    {
                      type: 'TEXT',
                      text: 'Read More',
                      link: 'https://arxiv.org/pdf/2403.15371.pdf',
                      style: {
                        position: 'relative',
                        textDecoration: 'underline',
                        lineHeight: '24px',
                        display: 'flex',
                        minWidth: '67px',
                        zIndex: '1',
                        fontSize: '11px',
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                      },
                    },
                  ],
                },
              ],
            },
          ],
          from: 0,
        },
      ],
      duration: '15',
      style: {},
      audios: [
        {
          src: 'https://storage.googleapis.com/lica-video/test/turing-4.mp3',
          volume: 1,
        },
      ],
    },
  ],
};

export const TAGS_LISTICLE = {

  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          type: 'TAGS_LISTICLE',
          data:
            [{
              itemTexts: [
                {
                  text: 'Sift, streamlined payments worldwide',
                },
                {
                  text: 'Onboarding Platform: A new era of hiring',
                },
                {
                  text: 'Master local compliance effortlessly',
                },
                {
                  text: 'Simplify your HR workflow',
                },
              ],
              itemStyles: [
                {
                  textStyle: {
                    fontSize: '20px',
                    color: 'black',
                  },
                  backgroundStyle: {
                    width: '263.4px',
                    backgroundColor: '#FFFF00',
                    shape: 'oval',
                  },
                },
                {
                  textStyle: {
                    fontSize: '20px',
                  },
                  backgroundStyle: {
                    width: '163.4px',
                    backgroundColor: '#D6B4FC',
                    shape: 'rectangle',
                  },
                },
                {
                  textStyle: {
                    fontSize: '20px',
                  },
                  backgroundStyle: {
                    width: '263.4px',
                    backgroundColor: '#FFA500',
                    shape: 'oval',
                  },
                },
                {
                  textStyle: {
                    fontSize: '20px',
                  },
                  backgroundStyle: {
                    width: '263.4px',
                    backgroundColor: 'green',
                    shape: 'rectangle',

                  },
                },
              ],
              itemAnimations: [
                {
                  animations: {
                    text: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    background: {
                      type: 'expand',
                      attributes: {
                        position: 'center',
                      },
                    },
                  },
                },
                {
                  animations: {
                    text: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    background: {
                      type: 'expand',
                      attributes: {
                        position: 'right',
                      },
                    },
                  },
                },
                {
                  animations: {
                    text: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    background: {
                      type: 'expand',
                      attributes: {
                        position: 'center',
                      },
                    },
                  },
                },
                {
                  animations: {
                    text: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    background: {
                      type: 'expand',
                      attributes: {
                        position: 'left',
                      },
                    },
                  },
                },
              ],
            }],
          style: {
            position: 'absolute',
            top: '16px',
            left: '16px',
            width: '292px',
            height: '444px',
          },
          from: 0,
        },
      ],
      duration: 10,
      style: {
        width: '100%',
        position: 'relative',
        height: '576px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '16px',
        color: '#FFF4EA',
        backgroundColor: 'gray',
        fontFamily: 'Work Sans',
      },
    },
  ],
};

export const TAGS_BULLETS = {
  fps: 30,
  width: 324,
  height: 576,
  slides: [
    {
      components: [
        {
          type: 'TAGS_BULLETS',
          data:
            [{
              itemTexts: [
                {
                  text: 'Sift & streamlined',
                },
                {
                  text: 'A new era of hiring',
                },
                {
                  text: 'Master local compliance effortlessly',
                },

              ],
              itemStyles: [
                {
                  textStyle: {
                    fontSize: '20px',
                    color: 'black',
                  },
                  backgroundStyle: {
                    backgroundColor: '#FFFF00',
                    height: '50px',
                    width: '50px',
                    borderRadius: '100px',
                  },
                  imageStyle: {
                    borderRadius: '418px',
                    width: '908.8px',
                    height: '50px',
                    objectFit: 'cover',
                  },
                },
                {
                  textStyle: {
                    fontSize: '20px',
                  },
                  backgroundStyle: {
                    backgroundColor: '#D6B4FC',
                    height: '50px',
                    width: '50px',
                    borderRadius: '15px',
                  },
                  imageStyle: {
                    borderRadius: '418px',
                    width: '908.8px',
                    height: '50px',
                    objectFit: 'cover',
                  },
                },
                {
                  textStyle: {
                    fontSize: '20px',
                  },
                  backgroundStyle: {
                    backgroundColor: '#FFA500',
                    height: '100px',
                    width: '50px',
                    borderRadius: '100px',
                  },
                  imageStyle: {
                    borderRadius: '418px',
                    width: '908.8px',
                    height: '50px',
                    objectFit: 'cover',
                  },
                },

              ],
              itemAnimations: [
                {
                  animations: {
                    text: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    background: {
                      type: 'expand',
                      attributes: {
                        position: 'center',
                      },
                    },
                  },
                },
                {
                  animations: {
                    text: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    background: {
                      type: 'expand',
                      attributes: {
                        position: 'center',
                      },
                    },
                  },
                },
                {
                  animations: {
                    text: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    background: {
                      type: 'expand',
                      attributes: {
                        position: 'center',
                      },
                    },
                  },
                },

              ],
            }],
          style: {
            position: 'absolute',
            top: '16px',
            left: '16px',
            width: '292px',
            height: '544px',
          },
          from: 0,
        },
      ],
      duration: 8,
      style: {
        width: '100%',
        position: 'relative',
        height: '576px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '16px',
        color: '#FFF4EA',
        backgroundColor: 'gray',
        fontFamily: 'Work Sans',
      },
    },
  ],
};

export const IMAGE_LISTICLE = {
  fps: 30,
  width: 1920,
  height: 1080,
  slides: [
    {
      components: [
        {
          type: 'GROUP',
          style: {
            position: 'absolute',
            top: '490.3px',
            left: '0px',
            width: '1920px',
            height: '590px',
          },
          components: [
            {
              type: 'VIDEO',
              src: 'https://storage.googleapis.com/lica-video/067ab23c-42b8-423e-8ad1-a12d4af60030.mp4',
              style: {
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '1920px',
                height: '590px',
                objectFit: 'cover',
              },
              animation: {
                type: 'wipe',
                attributes: {
                  direction: 'right',
                  duration: 2,
                  stagger: 0.1,
                },
              },
              id: '2-0-0',
            },
            {
              type: 'GROUP',
              components: [],
              style: {
                position: 'absolute',
                top: '0px',
                left: '0px',
                backgroundColor: 'rgba(53, 53, 53, 0.5)',
                backgroundImage: 'linear-gradient(180deg, rgba(53, 53, 53, 0.5) 30.08%)',
                width: '1920px',
                height: '590px',
              },
              id: '2-0-1',
            },
          ],
          from: 0,
          id: '2-0',
        },
        {
          type: 'GROUP',
          components: [],
          style: {
            position: 'absolute',
            top: '486.3px',
            left: '-4px',
            borderTop: '8px solid #474747',
            boxSizing: 'border-box',
            width: '1920px',
            height: '8px',
          },
          from: 0,
          animation: {
            type: 'panreverse',
            attributes: {
              animate: 'onEnterTwice',
              direction: 'right',
            },
          },
          id: '2-1',
        },
        {
          type: 'TEXT',
          text: 'Office Suite',
          style: {
            position: 'absolute',
            top: '80px',
            left: '80px',
            fontSize: '170px',
            fontFamily: 'Haffer',
            width: '1760px',
            height: '400px',
            display: 'inline',
          },
          from: 0,
          animation: {
            type: 'pan',
            attributes: {
              divider: 'lines',
              duration: 2,
              stagger: 0.1,
            },
          },
          id: '2-2',
        },
      ],
      duration: 3,
      style: {
        width: '100%',
        position: 'relative',
        backgroundColor: '#FFFFFF',
        height: '1080px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '170px',
        color: 'color_1',
        fontFamily: 'Haffer',
      },
      layout_id: '82',
      voiceover: 'Microsoft Office Suite, launched in 1990, redefined productivity tools for businesses and individuals.',
    },
    {
      components: [
        {
          type: 'IMAGE_MINIMAL_LISTICLE',
          data: [
            {
              itemTexts: [
                {
                  title: 'Azure',
                  subtitle: 'Leading cloud services.',
                  image: 'https://storage.googleapis.com/lica-video/4560cb21-12db-4ce7-b4be-95b266232942.mp4',
                },
                {
                  title: 'LinkedIn',
                  subtitle: 'Professional networking site.',
                  voiceover: 'With the acquisition of LinkedIn, Microsoft expanded its influence in the professional networking space.',
                  image: 'https://storage.googleapis.com/lica-video/5034e5dc-d637-417c-8927-e6a4d680a072.mp4',
                },
              ],
              itemStyles: [
                {
                  titleStyle: {
                    fontSize: '60px',
                    color: '#FFFFFF',
                    fontFamily: 'Haffer',
                  },
                  subtitleStyle: {
                    fontSize: '40px',
                    color: '#FFFFFF',
                    fontFamily: 'Haffer',
                  },
                  lineStyle: {
                    width: '204.8px',
                    backgroundColor: '#FFFFFF',
                    height: '5px',
                  },
                },
                {
                  titleStyle: {
                    fontSize: '60px',
                    color: '#FFFFFF',
                    fontFamily: 'Haffer',
                  },
                  subtitleStyle: {
                    fontSize: '40px',
                    color: '#FFFFFF',
                    fontFamily: 'Haffer',
                  },
                  lineStyle: {
                    width: '204.8px',
                    backgroundColor: '#FFFFFF',
                    height: '5px',
                  },

                },
              ],
              itemAnimations: [
                {
                  animations: {
                    title: {
                      type: 'pan',
                      attributes: {
                        direction: 'left',
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    subtitle: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    line: {
                      type: 'pan',
                      attributes: {
                        direction: 'right',
                        stagger: 0.1,
                      },
                    },
                  },
                },
                {
                  animations: {
                    title: {
                      type: 'pan',
                      attributes: {
                        direction: 'left',
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    subtitle: {
                      type: 'rise',
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                    },
                    line: {
                      type: 'pan',
                      attributes: {
                        direction: 'right',
                        stagger: 0.1,
                      },
                    },
                  },
                },
              ],
            },
          ],
          style: {
            width: '724px',
            height: '724px',
            overflow: 'hidden',
            display: 'flex',
            backgroundColor: 'rgb(56,69,234)',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            boxSizing: 'border-box',
            letterSpacing: 'normal',
            position: 'relative',
          },
          id: '3-0',
        },
      ],
      duration: 3,
      style: {
        position: 'relative',
        backgroundColor: '#474747',
        height: '1080px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '40px',
        color: '#FFFFFF',
        fontFamily: 'Haffer',
        width: '1920px',
      },
      layout_id: '95',
      voiceover: "Microsoft's ventures include Azure, GitHub, and LinkedIn, showcasing their diverse offerings in technology.",
    },
  ],
  duration: 12,
  audios: [],
};

export const PODCAST_TAGS_TEMPLATE = {
  fps: 30,
  width: 1920,
  height: 1080,
  slides: [
    {
      components: [
        {
          type: 'GROUP',
          components: [
            {
              type: 'SUBTITLES',
              src: 'https://storage.googleapis.com/lica-video/761cfdfe-d05c-4b51-b54c-fc55e665fb75_subtitles.srt',
              style: {
                padding: '0 16px',
                textAlign: 'center',
              },
              customStyle: {
                backgroundColor: '#fffffff',
                subtitleColor: '#00000030',
                activeSubtitleColor: '#000000',
              },
            },
            {
              type: 'SOUND_WAVE',
              src: 'https://storage.googleapis.com/lica-video/ai_companions_podcast.mp3',
              waveColor: '#027840',
              waveLines: 5,
              style: {
                flex: '1',
                marginTop: '20px',
                marginBottom: '20px',
              },
              innerStyle: {
                flex: '1',
              },
            },
            {
              type: 'GROUP',
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              },
              components: [
                {
                  type: 'TEXT',
                  text: 'Lica',
                  style: {
                    padding: '8px 16px',
                    textAlign: 'center',
                    color: '#000',
                    backgroundColor: '#fff',
                    width: 'auto',
                    borderRadius: '100px',
                  },
                },
              ],
            },
          ],
          style: {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url({image_keyword}) no-repeat center center / cover',
            backgroundColor: '#fff',
            width: '270px',
            height: '480px',
            display: 'flex',
            flexDirection: 'column',
            padding: '50px 0',
          },
        },
      ],
      duration: 300,
    },
  ],
};

export const VIDEO_DISORT = {
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-video/assets/yann-music.mp3',
      volume: 0.5,
    },
  ],
  duration: 26,
  fps: 30,
  width: 600,
  height: 600,
  slides: [
    {
      components: [
        {
          from: 0,
          id: '1-0',
          src: 'https://storage.googleapis.com/lica-video/assets/Alfred-Yann-4.mp4',
          style: {
            height: '300px',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '1920px',
          },
          type: 'VIDEO',
        },
        {
          animation: {
            attributes: {
              direction: 'right',
              duration: 1,
            },
            type: 'wipe',
          },
          components: [
            {
              id: '1-1-0',
              style: {
                color: '#F8D0FF',
                fontFamily: 'Haffer',
                fontSize: '140px',
                textAlign: 'center',
              },
              text: 'Can AI be as smart as us?',
              type: 'TEXT',
            },
          ],
          from: 0,
          id: '1-1',
          style: {
            alignItems: 'center',
            backgroundColor: '#000000',
            display: 'flex',
            height: '450px',
            justifyContent: 'center',
            left: '0px',
            position: 'absolute',
            top: '290px',
            width: '1920px',
          },
          type: 'GROUP',
        },
        {
          components: [],
          from: 0,
          id: '1-2',
          src: 'https://storage.googleapis.com/lica-video/assets/Alfred-Yann-3.mp4',
          style: {
            height: '380px',
            left: '0',
            position: 'absolute',
            top: '730px',
            width: '1920px',
          },
          type: 'VIDEO',
        },
      ],
      duration: 4,
      layout_id: '80',
      style: {
        backgroundColor: '#202020',
        height: '1080px',
        width: '1920px',
      },
      voiceover: 'Microsoft continues to be at the forefront of new technological advancements and innovations.',
    },
    {
      components: [
        {
          components: [],
          from: 0,
          id: '2-0',
          src: 'https://storage.googleapis.com/lica-video/assets/Alfred-Yann-1.mp4',
          style: {
            height: '1080px',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '1920px',
            zIndex: 10000,
          },
          type: 'VIDEO',
        },
      ],
      duration: 3,
      layout_id: '80',
      voiceover: 'Microsoft continues to be at the forefront of new technological advancements and innovations.',
    },
    {
      components: [
        {
          animation: {
            attributes: {
              animate: 'onEnterTwice',
              direction: 'right',
            },
            type: 'panreverse',
          },
          components: [],
          from: 0,
          id: '3-0',
          style: {
            borderTopColor: '#DBCDEC',
            borderTopStyle: 'solid',
            borderTopWidth: '8px',
            boxSizing: 'border-box',
            height: '8px',
            left: '-4px',
            position: 'absolute',
            top: '936.3px',
            width: '1928px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            attributes: {
              divider: 'chars',
              duration: 3,
              stagger: 0.1,
            },
            type: 'typewriter',
          },
          from: 0,
          id: '3-1',
          style: {
            color: '#DBCDEC',
            display: 'inline-block',
            fontFamily: 'Haffer',
            fontSize: '170px',
            height: '820px',
            left: '80px',
            position: 'absolute',
            top: '240px',
            width: '1760px',
          },
          text: 'Glasses can be way smarter',
          type: 'TEXT',
        },
      ],
      duration: 5,
      layout_id: '80',
      style: {
        backgroundColor: '#3845EB',
        color: '#474747',
        fontFamily: 'Haffer',
        fontSize: '170px',
        height: '1080px',
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'left',
        width: '100%',
      },
      voiceover: 'Microsoft has played a pivotal role in shaping the tech industry as we know it today.',
    },
    {
      components: [
        {
          components: [],
          from: 0,
          id: '4-0',
          src: 'https://storage.googleapis.com/lica-video/assets/Alfred-Yann-2.mp4',
          style: {
            height: '1080px',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '1920px',
          },
          type: 'VIDEO',
        },
      ],
      duration: 6,
      layout_id: '80',
      style: {},
      voiceover: 'Microsoft continues to be at the forefront of new technological advancements and innovations.',
    },
    {
      components: [
        {
          animation: {
            attributes: {
              animate: 'onEnterTwice',
              direction: 'right',
            },
            type: 'panreverse',
          },
          components: [],
          from: 0,
          id: '5-0',
          style: {
            borderTopColor: '#007297',
            borderTopStyle: 'solid',
            borderTopWidth: '8px',
            boxSizing: 'border-box',
            height: '8px',
            left: '-4px',
            position: 'absolute',
            top: '936.3px',
            width: '1928px',
          },
          type: 'GROUP',
        },
        {
          animation: {
            attributes: {
              divider: 'lines',
              duration: 2,
              stagger: 0.1,
            },
            type: 'rise',
          },
          from: 0,
          id: '5-1',
          style: {
            color: '#007297',
            display: 'inline-block',
            fontFamily: 'Haffer',
            fontSize: '170px',
            height: '820px',
            left: '80px',
            position: 'absolute',
            top: '240px',
            width: '1760px',
          },
          text: 'AI should keep up with humans',
          type: 'TEXT',
        },
      ],
      duration: 4,
      layout_id: '80',
      style: {
        backgroundColor: '#DBCDEC',
        color: '#474747',
        fontFamily: 'Haffer',
        fontSize: '170px',
        height: '1080px',
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'left',
        width: '100%',
      },
      voiceover: 'Microsoft continues to be at the forefront of new technological advancements and innovations.',
    },
    {
      components: [
        {
          data: [
            {
              itemAnimations: [
                {
                  animations: {
                    line: {
                      attributes: {
                        direction: 'right',
                        stagger: 0.1,
                      },
                      type: 'pan',
                    },
                    subtitle: {
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                      type: 'rise',
                    },
                    title: {
                      attributes: {
                        direction: 'left',
                        divider: 'lines',
                        stagger: 0.1,
                      },
                      type: 'pan',
                    },
                  },
                },
                {
                  animations: {
                    line: {
                      attributes: {
                        direction: 'right',
                        stagger: 0.1,
                      },
                      type: 'pan',
                    },
                    subtitle: {
                      attributes: {
                        divider: 'lines',
                        stagger: 0.1,
                      },
                      type: 'rise',
                    },
                    title: {
                      attributes: {
                        direction: 'left',
                        divider: 'lines',
                        stagger: 0.1,
                      },
                      type: 'pan',
                    },
                  },
                },
              ],
              itemStyles: [
                {
                  imageStyle: {
                    height: '540px',
                    width: '1060px',
                  },
                  lineStyle: {
                    backgroundColor: '#007297',
                    height: '8px',
                    width: '204.8px',
                  },
                  titleStyle: {
                    color: '#007297',
                    fontFamily: 'Haffer',
                    fontSize: '90px',
                    textDecoration: 'line-through',
                  },
                },
                {
                  imageStyle: {
                    height: '540px',
                    width: '1060px',
                  },
                  lineStyle: {
                    backgroundColor: '#007297',
                    height: '8px',
                    width: '204.8px',
                  },
                  titleStyle: {
                    color: '#007297',
                    fontFamily: 'Haffer',
                    fontSize: '90px',
                    textDecoration: 'line-through',
                  },
                },
              ],
            },
          ],
        },

      ],
      duration: 4,

    },
  ],
};

export const PODCAST_MINIMAL = {
  fps: 30,
  width: 256,
  height: 256,
  slides: [
    {
      components: [
        {
          type: 'GROUP',
          components: [
            {
              type: 'SOUND_WAVE',
              src: 'https://storage.googleapis.com/lica-video/ai_companions_podcast.mp3',
              waveColor: '#F8D0FF',
              style: {
                flex: '1',
                marginBottom: '20px',
              },
            },
            {
              type: 'SUBTITLES',
              src: 'https://storage.googleapis.com/lica-video/761cfdfe-d05c-4b51-b54c-fc55e665fb75_subtitles.srt',
              style: {
                padding: '0 16px',
                textAlign: 'center',
              },
              customStyle: {
                backgroundColor: '#F8D0FF',
              },
            },
            {
              type: 'GROUP',
              style: {
                backgroundColor: '#F8D0FF',
                height: '1px',
                marginTop: '20px',
                marginBottom: '20px',
              },
            },
            {
              type: 'GROUP',
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              },
              components: [{
                type: 'TEXT',
                text: 'Lica World',
                style: {
                  padding: '8px 16px',
                  textAlign: 'center',
                  color: '#F8D0FF',
                  backgroundColor: 'black',
                  width: 'auto',
                  borderRadius: '100px',
                },
              }],
            },
          ],
          style: {
            backgroundColor: 'red',
            width: '256px',
            height: '256px',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 0',
          },
        },
      ],
      duration: 200,
      style: {},
    },
  ],
};

export const PODCAST_GRADIENT = {
  fps: 30,
  width: 256,
  height: 256,
  slides: [
    {
      components: [
        {
          type: 'SOUND_WAVE',
          src: 'https://storage.googleapis.com/lica-video/ai_companions_podcast.mp3',
          waveColor: '#014726',
          waveLines: 40,
          frequencyHeight: 500,
          style: {
            position: 'absolute',
            height: '256px',
            top: '-128px',
          },
          innerStyle: {
            width: '100%',
            // filter: 'blur(10px) brightness(1)',
            boxSizing: 'border-box',
            padding: '0px',
            position: 'relative',

          },
          outerStyle: {
            gap: 0,
            justifyContent: 'center',
            boxSizing: 'border-box',
            filter: 'blur(10px)',

          },
        },
        {
          type: 'GROUP',
          components: [
            {
              type: 'SUBTITLES',
              src: 'https://storage.googleapis.com/lica-video/761cfdfe-d05c-4b51-b54c-fc55e665fb75_subtitles.srt',
              customStyle: {
                wrapperStyle: {
                  justifyContent: 'flex-start',
                },
                backgroundColor: 'none',
                subtitleColor: '#00000030',
                activeSubtitleColor: '#000000',
              },
            },
            {
              type: 'TEXT',
              text: 'Lica World',
              style: {
                padding: '0px 16px',
                color: '#000000',
                fontSize: '15px',
              },
            },
          ],
          style: {
            backgroundColor: '#E8E8E8',
            width: '256px',
            height: '256px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '20px 0',
          },
        },
      ],
      duration: 200,
      style: {},
    },
  ],
};

export const PODCAST_TAGS = {
  fps: 30,
  width: 270,
  height: 480,
  slides: [
    {
      components: [
        {
          type: 'GROUP',
          components: [
            {
              type: 'SUBTITLES',
              src: 'https://storage.googleapis.com/lica-video/761cfdfe-d05c-4b51-b54c-fc55e665fb75_subtitles.srt',
              style: {
                padding: '0 16px',
                textAlign: 'center',
              },
              customStyle: {
                backgroundColor: '#F8D0FF',
              },
            },
            {
              type: 'SOUND_WAVE',
              src: 'https://storage.googleapis.com/lica-video/ai_companions_podcast.mp3',
              waveColor: '#F8D0FF',
              waveLines: 5,
              style: {
                flex: '1',
                marginTop: '20px',
                marginBottom: '20px',
              },
              innerStyle: {
                flex: '1',
              },
            },
            {
              type: 'GROUP',
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              },
              components: [{
                type: 'TEXT',
                text: 'Lica World',
                style: {
                  padding: '8px 16px',
                  textAlign: 'center',
                  color: '#F8D0FF',
                  backgroundColor: 'black',
                  width: 'auto',
                  borderRadius: '100px',
                },
              }],
            },
          ],
          style: {
            backgroundColor: 'red',
            width: '270px',
            height: '480px',
            display: 'flex',
            flexDirection: 'column',
            padding: '50px 0',
          },
        },
      ],
      duration: 4,
      style: {},
    },
  ],
};

const NIUM_NEW_SCENE = {
  duration: 10,
  components: [
    {
      components: [],
      style: {
        backgroundColor: '#000',
        top: '78.6px',
        borderRadius: '50px',
        left: '80px',
        width: '920px',
        position: 'absolute',
        height: '360px',
      },
      from: 0,
      type: 'GROUP',
      animation: {
        attributes: {
          position: 'center',
        },
        type: 'expand',
      },
    },
    {
      src: 'https://storage.googleapis.com/lica-assets/nium-3.mp4',
      style: {
        objectFit: 'cover',
        top: '438.6px',
        borderRadius: '450px',
        left: '80.2px',
        width: '920.4px',
        position: 'absolute',
        height: '1401.4px',
      },
      from: 0,
      type: 'VIDEO',
      volume: 0,
      animation: {
        attributes: {
          position: 'center',
        },
        type: 'expand',
      },
    },
    {
      style: {
        color: '#9eb6fc',
        top: '110.6px',
        left: '105px',
        display: 'inline-block',
        width: '870px',
        letterSpacing: '-0.05em',
        lineHeight: '98px',
        fontSize: '90px',
        position: 'absolute',
        fontFamily: 'Haffer',
      },
      from: 0,
      text: 'Great for capturing everyday moments hands-free',
      type: 'TEXT',
      animation: {
        attributes: {
          duration: 1.5,
          divider: 'lines',
          stagger: 0.8,
        },
        type: 'rise',
      },
    },
  ],
  style: {
    backgroundColor: '#9eb6fc',
    overflow: 'hidden',
    fontFamily: 'Geist',
    color: '#9eb6fc',
    textAlign: 'center',
    width: '100%',
    fontSize: '90px',
    position: 'relative',
    height: '1920px',
  },
};

const NIUM_NEW_SCENE_LISTICLE = {
  duration: 10,
  components: [{
    data: {
      itemAnimations: {
        background: { attributes: { position: 'left' }, type: 'expand' },
        title: { attributes: { divider: 'lines', stagger: 0.1 }, type: 'rise' },
      },
      itemStyles: {
        backgroundStyle: {
          backgroundColor: '#40EF7B', borderRadius: '100px', width: '170px', height: '200px',
        },
        titleStyle: { fontFamily: 'Haffer', color: '#000000', fontSize: '80px' },
      },
      items: [{ title: 'Capture Hands-Free' }, { title: 'Stay Connected text' }, { title: 'Enhanced AI, Stylish Designs, Easy Connectivity' }],
    },
    style: {
      top: '872px', left: '50px', display: 'flex', flexDirection: 'column', width: '950px', position: 'absolute', gap: '40px', height: '800px',
    },
    from: 0,
    type: 'TAGS_BULLETS',
  }, {
    src: 'https://storage.googleapis.com/lica-assets/nium-3.mp4',
    style: {
      objectFit: 'cover', top: '120.1px', borderRadius: '418.54px', left: '89.7px', width: '908.8px', position: 'absolute', height: '635.8px',
    },
    from: 0,
    type: 'VIDEO',
    animation: { attributes: { position: 'center' }, type: 'expand' },
  }],
  style: {
    backgroundColor: '#E8E8E8', overflow: 'hidden', fontFamily: 'Haffer', color: '#000000', textAlign: 'left', width: '100%', fontSize: '70px', position: 'relative', height: '1920px',
  },
};

export const NIUM_DEMO = {
  fps: 30,
  width: 1080,
  height: 1920,
  slides: [
    {
      duration: 7,
      components: [{
        componentStyle: 'TITLE_POPUP',
        itemStyles: {
          alignContent: 'center', padding: '20px 80px', backgroundColor: '#9EB6FC', borderRadius: '157.55px', mixBlendMode: 'normal', letterSpacing: '-0.05em', fontSize: '100px',
        },
        from: 0,
        style: {
          top: '80px', left: '80px', width: '920px', position: 'absolute',
        },
        text: 'Meta Unveils Smart Glasses & AI Updates',
        type: 'TEXT',
      }, {
        src: 'https://storage.googleapis.com/lica-assets/nium-4.mp4',
        style: {
          objectFit: 'cover', top: '1184.8px', borderRadius: '250px', left: '80px', width: '920px', position: 'absolute', height: '655.2px',
        },
        from: 0,
        type: 'VIDEO',
        loop: true,
        volume: 0,
      }],
      style: {
        backgroundColor: '#E8E8E8', overflow: 'hidden', fontFamily: 'Haffer', color: '#000000', textAlign: 'center', width: '100%', fontSize: '120px', position: 'relative', height: '1920px',
      },
    },
    {
      duration: 5,
      components: [{
        src: 'https://storage.googleapis.com/lica-assets/nium-1.mp4',
        volume: 0,
        style: {
          top: '0px', objectFit: 'cover', left: '0px', width: '1080px', position: 'absolute', height: '1920px',
        },
        from: 0,
        type: 'VIDEO',
      }],
    },
  ],
  audios: [
    {
      src: 'https://storage.googleapis.com/lica-assets/nium-audio.mp3',
      volume: 1,
    },
  ],
};
