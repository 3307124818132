import React from "react";
import { AbsoluteFill, OffthreadVideo } from "remotion";

const DemoTemplate4 = () => {
	return (
		<AbsoluteFill>
			<OffthreadVideo src="https://storage.googleapis.com/lica-video/test/demo-4.mp4" />
		</AbsoluteFill>
	);
};

export default DemoTemplate4;
