import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';
import { downloadContent } from '@/services/content';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import { getCurrentDeviceType } from '@/utils/common';

function Video({ contentId, videoData, responseData }: {
  contentId: string;
  videoData: any;
  responseData: any;
}) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);
    await downloadContent(videoData);
    setIsDownloading(false);
  };

  const handleEditClick = async () => {
    navigate(`/content/${contentId}/edit`);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="text-2xl font-medium mb-4 sm:text-medium">{responseData.output.title}</div>
      <div className="h-full flex flex-col items-center">
        <div className="flex flex-col flex-auto items-center w-full">
          <div
            style={{
              aspectRatio: videoData.width / videoData.height,
            }}
            className="rounded-xl flex-auto max-w-full max-h-full"
          >
            {(getCurrentDeviceType() === 'phone' && videoData.download_links?.media) ? (
              <div className=" flex items-center justify-center">
                <video src={videoData.download_links.media} controls />
              </div>
            ) : (
              <RemotionPlayer data={videoData} clickToPlay={false} />
            )}
          </div>
        </div>
        <Spacer y={4} />
        <div className="flex items-center justify-center gap-4">
          <Button
            radius="full"
            size="md"
            className="text-primary font-normal !bg-tertiary"
            onClick={handleDownloadClick}
            isLoading={isDownloading}
          >
            Download video
          </Button>
          {responseData.user_info.email === authContext.currentUser?.email ? (
            <Button
              radius="full"
              size="md"
              className="bg-tertiary text-primary"
              onClick={handleEditClick}
              startContent={<Icon icon="iconamoon:edit" />}
            >
              Edit
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Video;
