import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@nextui-org/react';

import { auth } from '@/auth/firebase';

function LogoutButton() {
  const navigate = useNavigate();

  const onClick = () => {
    auth.signOut().then(() => {
      navigate('/login');
    });
  };

  return (
    <Button
      color="primary"
      size="md"
      radius="full"
      className="text-primary bg-secondary hover:bg-tertiary duration-500 text-base"
      onClick={onClick}
    >
      Logout
    </Button>
  );
}

export default LogoutButton;
