import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from '@nextui-org/react';
import { useMediaQuery } from '@mui/material';

import AuthContext from '@/auth/context';
import LicaPage from '../../../components/LicaPage';
import useFetch from '../../../hooks/useFetch';
import Content from './content';

function PublicContent() {
  const [contentData, setContentData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isPhone = useMediaQuery('(max-width:500px)');
  const initialBatchSize = isPhone ? 1 : 4;
  const [renderedItemsCount, setRenderedItemsCount] = useState<number>(initialBatchSize);
  const [loadedVideos, setLoadedVideos] = useState<number>(0);

  useEffect(() => {
    fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/wall/public/`)
      .then((response) => response.json())
      .then((response) => {
        setContentData(response.data);
        setIsLoading(false);
      });
  }, []); // Empty dependency array ensures this runs only once on mount

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner color="white" size="lg" />
      </div>
    );
  }

  const handleVideoLoaded = () => {
    setLoadedVideos((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount % initialBatchSize === 0) {
        setRenderedItemsCount((prevCount) => prevCount + initialBatchSize);
      }
      return newCount;
    });
  };

  const itemsToRender = contentData.contents?.slice(0, renderedItemsCount);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-1 min-md:grid-cols-3 min-lg:grid-cols-4 gap-4 min-md:gap-5">
      {itemsToRender?.map((item: any, index: number) => (
        <Content key={index} item={item} onRendered={handleVideoLoaded} />
      ))}
    </div>
  );
}

function PersonalContent() {
  const [contentData, setContentData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isPhone = useMediaQuery('(max-width:500px)');
  const initialBatchSize = isPhone ? 1 : 4;
  const [renderedItemsCount, setRenderedItemsCount] = useState<number>(initialBatchSize);
  const [loadedVideos, setLoadedVideos] = useState<number>(0);

  const { data: getData, triggerFetch: triggerGetFetch } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/wall/personal/`,
    { method: 'GET' },
  );

  useEffect(() => {
    triggerGetFetch();
    if (!getData) return;
    setContentData(getData.data);
    setIsLoading(false);
  }, [getData]); // Empty dependency array ensures this runs only once on mount

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner color="white" size="lg" />
      </div>
    );
  }

  const handleVideoLoaded = () => {
    setLoadedVideos((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount % initialBatchSize === 0) {
        setRenderedItemsCount((prevCount) => prevCount + initialBatchSize);
      }
      return newCount;
    });
  };

  const itemsToRender = contentData.contents?.slice(0, renderedItemsCount);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-1 min-md:grid-cols-3 min-lg:grid-cols-4 gap-4 min-md:gap-5">
      {itemsToRender?.map((item: any, index: number) => (
        <Content key={index} item={item} onRendered={handleVideoLoaded} />
      ))}
    </div>
  );
}

function VideosContent() {
  const [contentData, setContentData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isPhone = useMediaQuery('(max-width:500px)');
  const initialBatchSize = isPhone ? 1 : 4;
  const [renderedItemsCount, setRenderedItemsCount] = useState<number>(initialBatchSize);
  const [loadedVideos, setLoadedVideos] = useState<number>(0);

  const { data: getData, triggerFetch: triggerGetFetch } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/wall/personal/videos/`,
    { method: 'GET' },
  );
  useEffect(() => {
    triggerGetFetch();
    if (!getData) return;
    setContentData(getData.data);
    setIsLoading(false);
  }, [getData]); // Empty dependency array ensures this runs only once on mount

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner color="white" size="lg" />
      </div>
    );
  }

  const handleVideoLoaded = () => {
    setLoadedVideos((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount % initialBatchSize === 0) {
        setRenderedItemsCount((prevCount) => prevCount + initialBatchSize);
      }
      return newCount;
    });
  };

  const itemsToRender = contentData.videos;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 min-md:grid-cols-1 min-lg:grid-cols-1 w-full gap-4 min-md:gap-5">
      {itemsToRender?.map((item: any, index: number) => (
        <Content key={index} item={{ ...item, index }} onRendered={handleVideoLoaded} />
      ))}
    </div>
  );
}

export default function Wall() {
  const authContext = useContext(AuthContext);
  const [activeMainTab, setActiveMainTab] = useState<string>('podcasts');
  const [activeSubTab, setActiveSubTab] = useState<string>('public');

  const toggleSwitch = () => {
    setActiveSubTab((prev) => (prev === 'public' ? 'personal' : 'public'));
  };

  return (
    <LicaPage>
      <div className="max-w-[1200px] px-5 py-20 flex flex-col gap-7 items-center m-auto">
        <div className="w-full">
          {authContext.currentUser && (
            <div className="flex flex-col gap-10 sm:gap-5 text-sm sm:items-center sm:mb-4 items-start font-medium">
              <div className="flex gap-3">
                <button
                  className={`${activeMainTab === 'videos' && 'bg-tertiary !scale-110'} duration-500 bg-secondary font-normal text-lg border text-primary py-1 px-5 rounded-full`}
                  onClick={() => {
                    setActiveMainTab('videos');
                  }}
                >
                  Videos
                </button>
                <button
                  className={`${activeMainTab === 'podcasts' && 'bg-tertiary !scale-110'} duration-500 bg-secondary font-normal text-lg border text-primary py-1 px-5 rounded-full`}
                  onClick={() => {
                    setActiveMainTab('podcasts');
                  }}
                >
                  Podcasts
                </button>
              </div>

              {activeMainTab === 'podcasts' && (
                <div className="flex flex-col gap-2 w-56 items-start">
                  <div className="flex w-full justify-between">
                    <span className={`${activeSubTab === 'public' ? 'text-secondary' : 'text-secondary'} font-normal text-sm`}>Public</span>
                    <span className={`${activeSubTab === 'personal' ? 'text-secondary' : 'text-secondary'} font-normal text-sm`}>Personal</span>
                  </div>
                  <label className="relative w-full inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={activeSubTab === 'personal'}
                      onChange={toggleSwitch}
                      className="sr-only peer"
                    />
                    <div className="w-full h-4 bg-tertiary outline-none rounded-full peer-checked:after:translate-x-[12.3rem] after:content-[''] after:absolute after:top-[2px] after:left-[3px] after:bg-primary after:rounded-full after:h-3 after:w-5 after:duration-500 after:transition-all" />
                  </label>
                </div>
              )}
            </div>
          )}
        </div>

        {activeMainTab === 'podcasts' && activeSubTab === 'public' && <PublicContent />}
        {activeMainTab === 'podcasts' && activeSubTab === 'personal' && <PersonalContent />}
        {activeMainTab === 'videos' && <VideosContent />}
      </div>
    </LicaPage>
  );
}
