import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button, Spacer, Spinner,
} from '@nextui-org/react';
import { Icon } from '@iconify/react';

import LicaPage from '@/components/LicaPage';
import useFetch from '@/hooks/useFetch';
import { getCurrentDeviceType } from '@/utils/common';

import BlogControls from './blog';
import VideoControls from './video';
import PodcastControls from './podcast';
import styles from './index.module.css';

function Generate() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [contentOptions, setContentOptions] = useState<any>({});
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(true);

  const [prompt, setPrompt] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const [showControl, setShowControl] = useState(true);

  const {
    data: getData,
    triggerFetch: triggerGetFetch,
  } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/content-options/`, { method: 'GET' });
  const { fetchData: postFetch } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/`, { method: 'POST' });

  const [
    blogControls, setBlogControls,
  ] = useState({ stylePrompt: '' });
  const [
    videoControls, setVideoControls,
  ] = useState({
    stylePrompt: '',
    voiceType: null,
    actorId: null,
    orientation: null,
    voiceover: true,
    music: true,
    videoType: 'voiceover',
    templateId: null,
    templateStyle: null,
    voiceId: null,
  });
  const [
    podcastControls, setPodcastControls,
  ] = useState({
    stylePrompt: '',
    podcastType: 'narrator',
    voiceType: null,
    music: true,
    textData: '',
    podcastStyle: null,
    voiceIds: null,
  });
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    triggerGetFetch();
    if (!getData) return;
    setContentOptions(getData.data);
    setIsLoadingOptions(false);
  }, [getData]); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    setPrompt(podcastControls.textData);
  }, [podcastControls.textData]);

  const contentType = searchParams.get('type') || 'page';
  const contentTypes = contentType.split(',');

  const handleFileChange = (event: any) => {
    const files = Array.from(event.target.files);
    const imageFiles = files.filter((file: any) => file.type.startsWith('image/')).slice(0, 5);
    setSelectedFiles(imageFiles);
  };

  const onClick = async () => {
    if (!prompt || prompt.length < 8) return;

    setIsGenerating(true);

    const data: any = {
      prompt,
      content_types: [],
    };

    if (contentOptions.PAGE.content_types.includes('VIDEO') && (contentTypes.includes('page') || contentTypes.includes('video'))) {
      data.content_types.push({
        type: 'VIDEO',
        params: {
          style_prompt: videoControls.stylePrompt,
          video_type: videoControls.videoType,
          voice_type: videoControls.voiceType,
          presenter_id: videoControls.actorId,
          music: videoControls.music,
          orientation: videoControls.orientation,
          template_style: videoControls.templateStyle,
          template_id: videoControls.templateId,
          voice_id: videoControls.voiceId,
          voiceover: videoControls.voiceover,
          device: getCurrentDeviceType(),
        },
      });
    }
    if (contentOptions.PAGE.content_types.includes('PODCAST') && (contentTypes.includes('page') || contentTypes.includes('podcast'))) {
      data.content_types.push({
        type: 'PODCAST',
        params: {
          style_prompt: podcastControls.stylePrompt,
          podcast_type: podcastControls.podcastType,
          voice_type: podcastControls.voiceType,
          music: podcastControls.music,
          podcast_style: podcastControls.podcastStyle,
          voice_ids: podcastControls.voiceIds,
        },
      });
    }
    if (contentOptions.PAGE.content_types.includes('BLOG') && (contentTypes.includes('page') || contentTypes.includes('blog'))) {
      data.content_types.push({
        type: 'BLOG',
        params: {
          style_prompt: blogControls.stylePrompt,
        },
      });
    }

    try {
      const postResponse = await postFetch({
        body: JSON.stringify(data),
      });
      if (postResponse) {
        navigate(`/pages/${postResponse.data.page_id}/`);
      }
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <LicaPage>
      {isLoadingOptions ? (
        <div className="flex items-center justify-center h-[calc(100vh-128px)]">
          <Spinner />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={`${styles.container} flex border border-secondary border-opacity-20 flex-col items-center justify-center max-w-none rounded-[3rem]`}>
            <Spacer y={3} />
            {(contentType !== 'podcast') && (
              <div className={`${styles.yourStoryContainer}`}>
                <textarea
                  value={prompt}
                  className={`${styles.yourStoryInput} bg-transparent w-full resize-none border border-secondary border-opacity-20 p-4 outline-none min-h-36 rounded-2xl                                                             `}
                  style={{
                    background: 'transparent',
                  }}
                  placeholder="Enter your story here or paste URL link"
                  onChange={(e) => setPrompt(e.target.value)}
                  id="your-story-input"
                />
                <div className={styles.controlAction}>
                  <Button
                    className="py-2 px-3 text-small bg-tertiary text-primary rounded-full"
                    startContent={<Icon icon="solar:tuning-bold" />}
                    onClick={() => {
                      setShowControl(!showControl);
                    }}
                  >
                    {showControl ? 'Hide Controls' : 'Show Controls'}
                  </Button>
                  <div>
                    <label htmlFor="file-upload" className={styles.imageUploadIcon}>
                      <Icon
                        icon="tdesign:attach"
                        className="text-secondary opacity-70"
                        style={{ fontSize: '1.25rem' }}
                      />
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      className={styles.imageUploadInput}
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>
                </div>
              </div>
            )}
            {showControl && (
              contentOptions.PAGE.content_types.map((type: string) => {
                if (type === 'BLOG') {
                  if (contentTypes.includes('page') || contentTypes.includes('blog')) {
                    return (
                      <BlogControls
                        key={type}
                        controls={blogControls}
                        setControls={setBlogControls}
                        showTitle={contentTypes.includes('page') || contentTypes.length > 1}
                      />
                    );
                  }
                }
                if (type === 'VIDEO') {
                  if (contentTypes.includes('page') || contentTypes.includes('video')) {
                    return (
                      <VideoControls
                        key={type}
                        controls={videoControls}
                        setControls={setVideoControls}
                        showTitle={contentTypes.includes('page') || contentTypes.length > 1}
                        contentOptions={contentOptions.VIDEO}
                      />
                    );
                  }
                }
                if (type === 'PODCAST') {
                  if (contentTypes.includes('page') || contentTypes.includes('podcast')) {
                    return (
                      <PodcastControls
                        key={type}
                        controls={podcastControls}
                        setControls={setPodcastControls}
                        contentOptions={contentOptions.PODCAST}
                      />
                    );
                  }
                }
                return null;
              })
            )}
            <Spacer y={3} />
            <Button
              radius="full"
              color="primary"
              className="w-36 h-12 mt-5 text-lg font-medium text-primary hover:bg-secondary bg-tertiary"
              onClick={onClick}
              isDisabled={!prompt || prompt.length < 8}
              isLoading={isGenerating}
            >
              Generate
            </Button>
          </div>
        </div>
      )}
    </LicaPage>
  );
}

export default Generate;
