import React, {
  Fragment, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import cloneDeep from 'lodash/cloneDeep';
import { Alert, Snackbar } from '@mui/material';

import useFetch from '@/hooks/useFetch';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import { downloadContent } from '@/services/content';

import EditAudioModal from './components/edit-audio-modal';
import EditSlideItem from './components/edit-slide-item';

import styles from './index.module.css';

const AudioType = {
  Music: 'MUSIC',
  VoiceOver: 'VOICEOVER',
};

function AddNewSlide(
  {
    index, updateVideo, fetchContent, isProcessing, setIsProcessing,
    slidesCount, setAddNewSlideIndex,
  }: any,
) {
  const [prompt, setPrompt] = useState('');

  const handleVideoUpdate = async () => {
    if (isProcessing) return;

    setIsProcessing(true);

    let updatedPrompt = `ADD a NEW slide between slide ${index + 1} and slide ${index + 2} with the following instructions - ${prompt}`;
    if (index === slidesCount - 1) {
      updatedPrompt = `ADD a NEW slide after slide ${index + 1} with the following instructions - ${prompt}`;
    }

    const payload = {
      prompt: updatedPrompt,
    };

    await updateVideo({
      body: JSON.stringify(payload),
    });

    await fetchContent();
  };

  return (
    <div className="border-1 border-solid border-[#eaecf049] p-4 rounded-2xl my-8">
      <textarea
        placeholder="Add details about the scene you want to add"
        className="p-1 border-1 rounded-[4px] outline-none border-[#eaecf049] mb-2 w-full bg-transparent h-20 resize-none"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <div className="flex justify-end gap-2">
        <Button
          radius="full"
          size="md"
          className="text-primary"
          onClick={() => { setAddNewSlideIndex(null); }}
        >
          Cancel
        </Button>
        <Button
          radius="full"
          size="md"
          className="text-primary !bg-tertiary"
          onClick={handleVideoUpdate}
          isLoading={isProcessing}
          isDisabled={prompt.trim() === ''}
        >
          {isProcessing ? 'Generating' : 'Generate'}
        </Button>
      </div>
    </div>
  );
}

export function EditVideoNew({ videoId, responseData }: {
  videoId: string;
  responseData: any
}) {
  const navigate = useNavigate();

  const [internalVideoData, setInternalVideoData] = useState<any>(responseData.output);
  const [openEditAudioModal, setOpenEditAudioModal] = useState(false);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [addNewSlideIndex, setAddNewSlideIndex] = useState<number | null>(null);
  const [editSlideIndex, setEditSlideIndex] = useState<number | null>(null);

  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);

  const { fetchData: updateVideo } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/update`,
    { method: 'POST' },
  );
  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/`,
    { method: 'GET' },
  );

  const fetchContent = async () => {
    try {
      const contentResponseData = await getContent();

      if (contentResponseData?.data?.update_status === 'completed') {
        setInternalVideoData(contentResponseData.data.output);
        setIsProcessing(false);

        if (addNewSlideIndex !== null) {
          setAddNewSlideIndex(null);
        }
        if (editSlideIndex !== null) {
          setEditSlideIndex(null);
        }
      } else if (contentResponseData?.data?.update_status === 'failed' || !contentResponseData?.data?.update_status) {
        setIsProcessing(false);
        setIsSnackbarVisible(true);

        if (addNewSlideIndex !== null) {
          setAddNewSlideIndex(null);
        }
        if (editSlideIndex !== null) {
          setEditSlideIndex(null);
        }
      } else if (['initiated', 'processing'].includes(contentResponseData?.data?.update_status)) {
        setTimeout(() => {
          fetchContent();
        }, 5000);
      }
    } catch (err) { /* empty */ }
  };

  useEffect(() => {
    if (['initiated', 'processing'].includes(responseData?.update_status)) {
      setIsProcessing(true);

      setTimeout(() => {
        fetchContent();
      }, 5000);
    }
  }, []);

  const handleCancel = () => {
    navigate(`/pages/${responseData.pageId}`);
  };

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);
    await downloadContent(internalVideoData.video_data);
    setIsDownloading(false);
  };

  const handleSaveClick = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const data = {
      screenplay_slides: internalVideoData.screenplay_slides,
      unhydrated_slides: internalVideoData.unhydrated_slides,
      subtitles_enabled: internalVideoData.subtitles_enabled,
      bg_music: internalVideoData.bg_music,
    };

    try {
      const saveResponse = await updateVideo({
        body: JSON.stringify(data),
      });

      if (['initiated', 'processing'].includes(saveResponse.data.update_status)) {
        await fetchContent();
      } else {
        setIsProcessing(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDuplicateSlide = (index: number) => {
    if (index >= internalVideoData.video_data.slides.length) {
      setInternalVideoData({
        ...internalVideoData,
        video_data: {
          ...internalVideoData.video_data,
          slides: [
            ...internalVideoData.video_data.slides,
            {
              ...internalVideoData.video_data.slides[-1],
            },
          ],
        },
        screenplay_slides: [
          ...internalVideoData.screenplay_slides,
          {
            ...internalVideoData.screenplay_slides[-1],
          },
        ],
        unhydrated_slides: [
          ...internalVideoData.unhydrated_slides,
          {
            ...internalVideoData.unhydrated_slides[-1],
          },
        ],
        presenter_data: {
          ...internalVideoData.presenter_data,
          slide_durations: [
            ...internalVideoData.presenter_data.slide_durations,
            internalVideoData.presenter_data.slide_durations[-1],
          ],
        },
      });
    } else {
      setInternalVideoData({
        ...internalVideoData,
        video_data: {
          ...internalVideoData.video_data,
          slides: [
            ...internalVideoData.video_data.slides.slice(0, index + 1),
            {
              ...internalVideoData.video_data.slides[index],
            },
            ...internalVideoData.video_data.slides.slice(index + 1),
          ],
        },
        screenplay_slides: [
          ...internalVideoData.screenplay_slides.slice(0, index + 1),
          {
            ...internalVideoData.screenplay_slides[index],
          },
          ...internalVideoData.screenplay_slides.slice(index + 1),
        ],
        unhydrated_slides: [
          ...internalVideoData.unhydrated_slides.slice(0, index + 1),
          {
            ...internalVideoData.unhydrated_slides[index],
          },
          ...internalVideoData.unhydrated_slides.slice(index + 1),
        ],
        presenter_data: {
          ...internalVideoData.presenter_data,
          slide_durations: [
            ...internalVideoData.presenter_data.slide_durations.slice(0, index + 1),
            internalVideoData.presenter_data.slide_durations[index],
            ...internalVideoData.presenter_data.slide_durations.slice(index + 1),
          ],
        },
      });
    }
  };

  const handleDeleteSlide = (index: number) => {
    setInternalVideoData({
      ...internalVideoData,
      video_data: {
        ...internalVideoData.video_data,
        slides: [
          ...internalVideoData.video_data.slides.slice(0, index),
          ...internalVideoData.video_data.slides.slice(index + 1),
        ],
      },
      screenplay_slides: [
        ...internalVideoData.screenplay_slides.slice(0, index),
        ...internalVideoData.screenplay_slides.slice(index + 1),
      ],
      unhydrated_slides: [
        ...internalVideoData.unhydrated_slides.slice(0, index),
        ...internalVideoData.unhydrated_slides.slice(index + 1),
      ],
    });
  };

  const handleSaveSlide = (slide: any, updatedScreenplaySlide: any, index: number) => {
    const cloneSlides = [...internalVideoData.video_data.slides];
    const cloneSlide = { ...cloneSlides[index] };
    cloneSlides[index] = {
      ...cloneSlide,
      ...slide,
    };

    setInternalVideoData((currentVal: any) => ({
      ...currentVal,
      video_data: {
        ...currentVal.video_data,
        slides: cloneSlides,
      },
      screenplay_slides: [
        ...currentVal.screenplay_slides.slice(0, index),
        updatedScreenplaySlide,
        ...currentVal.screenplay_slides.slice(index + 1),
      ],
    }));
  };

  const handleSaveAudio = (audios: any) => {
    const bgMusicAudio = audios.filter((audio: any) => audio.type === 'MUSIC')[0].src;

    setInternalVideoData({
      ...internalVideoData,
      video_data: {
        ...internalVideoData.video_data,
        audios,
      },
      bg_music: {
        ...internalVideoData.bg_music,
        src: bgMusicAudio,
      },
    });
  };

  const onSubtitleToggle = () => {
    let updatedComponents;
    if (internalVideoData.subtitles_enabled) {
      updatedComponents = internalVideoData.video_data.components.filter((component: any) => component.type !== 'SUBTITLES');
    } else {
      updatedComponents = cloneDeep(responseData.output.video_data.components);
    }

    setInternalVideoData({
      ...internalVideoData,
      subtitles_enabled: !internalVideoData.subtitles_enabled,
      video_data: {
        ...internalVideoData.video_data,
        components: updatedComponents,
      },
    });
  };

  return (
    <>
      <EditAudioModal
        audios={internalVideoData.video_data.audios}
        onClose={() => setOpenEditAudioModal(false)}
        onSave={handleSaveAudio}
        isOpen={openEditAudioModal}
        allowTypes={[AudioType.Music]}
      />
      <div className={styles.editContainer}>
        <div className={styles.videoContainer}>
          <div className="flex justify-between items-center w-full">
            <div
              className="text-secondary"
              style={{
                fontSize: '1.5rem',
                fontWeight: 800,
              }}
            >
              {responseData.output.title}
            </div>
            <div
              className="flex justify-end items-center gap-2"
              style={{
                width: '30%',
              }}
            >
              <Button
                onClick={() => setOpenEditAudioModal(true)}
                color="primary"
                className="text-white"
                isIconOnly
                style={{
                  borderRadius: '50%',
                  padding: '0',
                }}
              >
                <Icon icon="iconoir:tiktok" style={{ fontSize: '1.5rem' }} />
              </Button>
              <Button
                onClick={() => onSubtitleToggle()}
                color="primary"
                className="text-white"
                isIconOnly
                style={{
                  borderRadius: '50%',
                  padding: '0',
                }}
              >
                <Icon icon={internalVideoData.subtitles_enabled ? 'iconoir:closed-captions-tag-solid' : 'iconoir:closed-captions-tag'} style={{ fontSize: '1.5rem' }} />
              </Button>
            </div>
          </div>
          <Spacer y={3} />
          <div className="flex flex-col flex-auto items-center w-full">
            <div
              style={{
                aspectRatio:
                  internalVideoData.video_data.width / internalVideoData.video_data.height,
              }}
              className="rounded-xl flex-auto max-w-full max-h-full"
            >
              <RemotionPlayer
                data={internalVideoData.video_data}
                clickToPlay={false}
              />
            </div>
          </div>
          <Spacer y={6} />
          <div className="flex items-center justify-center gap-4">
            <Button
              radius="full"
              size="md"
              className="text-primary !bg-tertiary"
              onClick={handleDownloadClick}
              isLoading={isDownloading}
            >
              Download video
            </Button>
            <Button
              radius="full"
              size="md"
              className="text-primary !bg-tertiary"
              onClick={handleSaveClick}
              startContent={(isProcessing) ? null : (
                <Icon
                  icon="material-symbols:check-circle-outline"
                  style={{ fontSize: '1.5rem' }}
                />
              )}
              isLoading={(isProcessing)}
            >
              {(isProcessing) ? 'Updating' : 'Save Changes'}
            </Button>
            <Button
              radius="full"
              size="md"
              className="text-primary hover:!bg-tertiary duration-400 !bg-secondary"
              onClick={handleCancel}
            >
              Back
            </Button>
          </div>
        </div>
        <div className={styles.slideContainer}>
          <div className={styles.slides}>
            <div className={styles.slideItem}>
              {internalVideoData.video_data.slides.map((slide: any, index: number) => (
                <Fragment key={`${slide.duration}-${index}`}>
                  <EditSlideItem
                    slide={slide}
                    index={index}
                    onDelete={handleDeleteSlide}
                    onDuplicateSlide={handleDuplicateSlide}
                    onSave={handleSaveSlide}
                    updateVideo={updateVideo}
                    fetchContent={fetchContent}
                    isProcessing={isProcessing}
                    setIsProcessing={setIsProcessing}
                    unhydratedSlideLayout={internalVideoData.unhydrated_slides[index]}
                    screenplaySlide={internalVideoData.screenplay_slides[index]}
                    showSlideEditingModal={editSlideIndex === index}
                    setEditSlideIndex={setEditSlideIndex}
                  />
                  {index !== addNewSlideIndex ? (
                    <div className="flex justify-center items-center w-full opacity-10 hover:opacity-100 transition-opacity">
                      <Button
                        size="sm"
                        isIconOnly
                        className="rounded-full"
                        onClick={() => {
                          setAddNewSlideIndex(index);
                        }}
                      >
                        <Icon
                          icon="fa-solid:plus"
                          style={{ fontSize: '1rem' }}
                        />
                      </Button>
                    </div>
                  ) : null}
                  {index === addNewSlideIndex ? (
                    <AddNewSlide
                      index={index}
                      slidesCount={internalVideoData.video_data.slides.length}
                      updateVideo={updateVideo}
                      fetchContent={fetchContent}
                      isProcessing={isProcessing}
                      setIsProcessing={setIsProcessing}
                      setAddNewSlideIndex={setAddNewSlideIndex}
                    />
                  ) : null}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <Snackbar
          open={isSnackbarVisible}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={1500}
          onClose={() => {
            setIsSnackbarVisible(false);
          }}
        >
          <Alert severity="error">Couldn't update the video. Please try again.</Alert>
        </Snackbar>
      </div>
    </>
  );
}
