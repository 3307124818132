import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Spinner } from "@nextui-org/react";
import { Icon } from "@iconify/react";

import RemotionPlayer from "../../remotion/RemotionPlayer";
import AudioPlayer from "../../remotion/AudioPlayer";
import { TURING_INTERACTIVE } from "../../remotion/utils/templates";
import ProgressList from "../../components/ProgressList";

const GroupChild = styled.div`
    background-color: #f5f7fa;
    border-top: 1px solid #e9edf1;
    box-sizing: border-box;
    height: 56px;
`;
const AllRightsReserved = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    text-shadow: 1px 0 0 #e9edf1, 0 1px 0 #e9edf1, -1px 0 0 #e9edf1,
    0 -1px 0 #e9edf1;
`;
const RectangleParent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    font-size: 14px;
    color: #626262;
`;
const LicaWorld = styled.div`
    letter-spacing: -0.02em;
    line-height: 94px;
    font-weight: 600;
`;
const Share03Icon = styled.img`
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
`;
const Button2 = styled.div`
    position: relative;
    font-weight: 600;
`;
const Button1 = styled.div`
    width: 17.86%;
    top: calc(50% - 25px);
    right: 5.42%;
    left: 79.72%;
    border-radius: 1000px;
    background-color: #5046e5;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 9px 28px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
`;
const NavBar = styled.div`
    top: 0;
    left: calc(50% - 720px);
    text-align: center;
    color: #121212;
    background-color: #f5f7fa;
    border-bottom: 1px solid #e9edf1;
`;
const NavContainer = styled.div`
    width: 1278px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
`;
const WebinarParent = styled.div`
    position: absolute;
    top: 117px;
    left: calc(50% - 639px);
    width: 1278px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 137px;
    font-size: 24px;
`;
const AutoGeneratedTitle = styled.div`
    width: 810px;
    position: relative;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-weight: 500;
    display: none;
`;
const GenerativeAiAnd = styled.div`
    align-self: stretch;
    position: relative;
    font-size: 35px;
    line-height: 48px;
    font-weight: 600;
    color: #000;
`;
const AutoGeneratedTitleParent = styled.div`
    width: 810px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
`;
const FrameParent = styled.div`
    position: absolute;
    width: 1272px;
    top: 320px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 56px;
    text-align: center;
    font-size: 18px;
    color: #5046e5;
`;
const HighlightsReels = styled.div`
    width: 810px;
    position: relative;
    line-height: 125%;
    font-weight: 600;
    display: inline-block;
`;
const GroupChild1 = styled.div`
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    border-radius: 100px;
    background-color: #e9edf1;
    width: 1176px;
    height: 2px;
`;
const GroupChild2 = styled.div`
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    border-radius: 100px;
    background-color: #5046e5;
    width: 72px;
    height: 4px;
`;
const RectangleContainer = styled.div`
    width: 1176px;
    position: relative;
    height: 4px;
`;
const HighlightsReelsParent = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 18px;
`;
const HighlightReelVideo = styled.video`
    width: 376px;
    position: relative;
    height: 654px;
`;
const HighlightReelVideoLandscape = styled.video`
    width: 576px;
    position: relative;
    height: 324px;
`;
const RectangleParent1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;
const GroupContainer = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;
const SpinnerContainer = styled.div`
    width: 1176px;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
const FrameParent1 = styled.div`
    border-radius: 16px;
    background-color: #f5f7fa;
    border: 1px solid #e9edf1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 48px;
    gap: 36px;
`;
const FrameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 48px;
`;
const RectangleParent2 = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 36px;
`;
const FrameGroup = styled.div`
    position: absolute;
    top: 440px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 72px;
`;

const FrameChild1 = styled.img`
    flex: 1;
    position: relative;
    border-radius: 16px;
    max-width: 100%;
    overflow: hidden;
    height: 601px;
    object-fit: cover;
    mix-blend-mode: normal;
`;
const LightVersionUpdateRoot = styled.div`
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 3000px;
    overflow: hidden;
    text-align: left;
    font-size: 28px;
    color: #000;
    font-family: "Inter";
`;
const Subtitle = styled.div`
    font-size: 16px;
    text-align: left;
    color: rgb(51, 51, 51);
    font-style: italic;
`;

const Demo10: FunctionComponent = () => {
	const [reels, setReels] = useState(false);
    const [cards, setCards] = useState(false);
	const [content, setContent] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setReels(true);
		}, 1000);
        setTimeout(() => {
			setCards(true);
		}, 2000);
		setTimeout(() => {
			setContent(true);
		}, 1000);
	}, []);

	const handleDownloadClick = async () => {
		if (isDownloading) {
			return;
		}

		setIsDownloading(true);

		fetch("https://storage.googleapis.com/lica-video/test/turing-podcast.mp4")
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = "podcast";
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);

				setIsDownloading(false);
			});
	};

	return (
		<LightVersionUpdateRoot>
			<RectangleParent>
				<GroupChild/>
				<AllRightsReserved>
					©2024, All rights reserved by Lica World
				</AllRightsReserved>
			</RectangleParent>
			<NavBar>
				<NavContainer>
					<LicaWorld>Lica World</LicaWorld>
					<Button1>
						<Share03Icon alt="" src="/share-1.svg"/>
						<Button2>Share this project</Button2>
					</Button1>
				</NavContainer>
			</NavBar>
			<WebinarParent>
				<img style={{margin: "auto", height: "175px"}} src="https://storage.googleapis.com/lica-video/deepscribe_logo.png" />
			</WebinarParent>

			<FrameGroup>
				
                <FrameContainer>
					<FrameParent1>
						<HighlightsReelsParent>
							<HighlightsReels>Deepscribe deepens integration with Epic - <a href="https://www.deepscribe.ai/resources/deepscribe-deepens-integration-with-epic-laying-groundwork-for-future-ambient-solutions" style={{ color: 'blue', fontSize: '22px'  }}>Source</a></HighlightsReels>
							<RectangleContainer>
								<GroupChild1/>
								<GroupChild2/>
							</RectangleContainer>
						</HighlightsReelsParent>
						{reels ? (
							<GroupContainer>
								<HighlightReelVideoLandscape
									src="https://storage.googleapis.com/lica-video/DeepScribe_Epic.mp4"
									controls
								/>
								{/* <RectangleParent1>
									<HighlightReelVideo
										src="https://storage.googleapis.com/lica-video/turing-Post-Highlights-portrait.mp4"
										controls
									/>
								</RectangleParent1> */}
							</GroupContainer>
						) : (
							<SpinnerContainer>
								{/*<ProgressList*/}
								{/*	items={[*/}
								{/*		"Loading highlights"*/}
								{/*	]}*/}
								{/*	onFinish={() => {}}*/}
								{/*/>*/}
								<Spinner label="Loading Video" size="lg" color="primary"/>
							</SpinnerContainer>
						)}
					</FrameParent1>
					{/* <Button4>
						<CheckCircleIcon1 alt="" src="/checkcircle.svg"/>
						<Button>Download this report</Button>
						<Share03Icon alt="" src="/download-icon.svg"/>
					</Button4> */}
				</FrameContainer>

                
                <FrameContainer>
					<FrameParent1>
						<HighlightsReelsParent>
							<HighlightsReels>Deepscribe's Unveils Real Time Feature at HIMSS - <a href="https://www.deepscribe.ai/resources/deepscribe-unveils-real-time-feature-at-himss-ai-generated-clinical-notes-as-the-visit-unfolds" style={{ color: 'blue', fontSize: '22px'  }}>Source</a></HighlightsReels>
							<RectangleContainer>
								<GroupChild1/>
								<GroupChild2/>
							</RectangleContainer>
						</HighlightsReelsParent>
						{reels ? (
							<GroupContainer>
								<HighlightReelVideoLandscape
									src="https://storage.googleapis.com/lica-video/deepscribe_HIMMS.mp4"
									controls
								/>
								{/* <RectangleParent1>
									<HighlightReelVideo
										src="https://storage.googleapis.com/lica-video/turing-Post-Highlights-portrait.mp4"
										controls
									/>
								</RectangleParent1> */}
							</GroupContainer>
						) : (
							<SpinnerContainer>
								{/*<ProgressList*/}
								{/*	items={[*/}
								{/*		"Loading highlights"*/}
								{/*	]}*/}
								{/*	onFinish={() => {}}*/}
								{/*/>*/}
								<Spinner label="Loading Video" size="lg" color="primary"/>
							</SpinnerContainer>
						)}
					</FrameParent1>
					{/* <Button4>
						<CheckCircleIcon1 alt="" src="/checkcircle.svg"/>
						<Button>Download this report</Button>
						<Share03Icon alt="" src="/download-icon.svg"/>
					</Button4> */}
				</FrameContainer>
 
                <FrameContainer>
					<FrameParent1>
						<HighlightsReelsParent>
							<HighlightsReels>Deepscribe's Ambient AI for Clinical Documentation Now Available on AWS Marketplace - <a href="https://www.deepscribe.ai/resources/deepscribe-and-aws-to-scale-generative-ai-in-healthcare" style={{ color: 'blue', fontSize: '22px'  }}>Source</a></HighlightsReels>
							<RectangleContainer>
								<GroupChild1/>
								<GroupChild2/>
							</RectangleContainer>
						</HighlightsReelsParent>
						{reels ? (
							<GroupContainer>
								<HighlightReelVideoLandscape
									src="https://storage.googleapis.com/lica-video/Deepscribe_AWS.mp4"
									controls
								/>
								{/* <RectangleParent1>
									<HighlightReelVideo
										src="https://storage.googleapis.com/lica-video/turing-Post-Highlights-portrait.mp4"
										controls
									/>
								</RectangleParent1> */}
							</GroupContainer>
						) : (
							<SpinnerContainer>
								{/*<ProgressList*/}
								{/*	items={[*/}
								{/*		"Loading highlights"*/}
								{/*	]}*/}
								{/*	onFinish={() => {}}*/}
								{/*/>*/}
								<Spinner label="Loading Video" size="lg" color="primary"/>
							</SpinnerContainer>
						)}
					</FrameParent1>
					{/* <Button4>
						<CheckCircleIcon1 alt="" src="/checkcircle.svg"/>
						<Button>Download this report</Button>
						<Share03Icon alt="" src="/download-icon.svg"/>
					</Button4> */}
				</FrameContainer>

                <FrameContainer>
					<FrameParent1>
						<HighlightsReelsParent>
							<HighlightsReels>Deepscribe launches customization studio - <a href="https://www.deepscribe.ai/resources/deepscribe-adds-customization-studio-to-fully-automated-ai-scribe" style={{ color: 'blue', fontSize: '22px'  }}>Source</a></HighlightsReels>
							<RectangleContainer>
								<GroupChild1/>
								<GroupChild2/>
							</RectangleContainer>
						</HighlightsReelsParent>
						{reels ? (
							<GroupContainer>
								<HighlightReelVideoLandscape
									src="https://storage.googleapis.com/lica-video/deepscribe_Customization_Studio.mp4"
									controls
								/>
								{/* <RectangleParent1>
									<HighlightReelVideo
										src="https://storage.googleapis.com/lica-video/turing-Post-Highlights-portrait.mp4"
										controls
									/>
								</RectangleParent1> */}
							</GroupContainer>
						) : (
							<SpinnerContainer>
								{/*<ProgressList*/}
								{/*	items={[*/}
								{/*		"Loading highlights"*/}
								{/*	]}*/}
								{/*	onFinish={() => {}}*/}
								{/*/>*/}
								<Spinner label="Loading Video" size="lg" color="primary"/>
							</SpinnerContainer>
						)}
					</FrameParent1>
					{/* <Button4>
						<CheckCircleIcon1 alt="" src="/checkcircle.svg"/>
						<Button>Download this report</Button>
						<Share03Icon alt="" src="/download-icon.svg"/>
					</Button4> */}
				</FrameContainer>
                
			</FrameGroup>
		</LightVersionUpdateRoot>
	);
};

export default Demo10;
