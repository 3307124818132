import React, { useContext } from 'react';
import {
  Checkbox, Image, Select, SelectItem, Spacer,
} from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';

import styles from './index.module.css';

interface ContentOptions {
  presenters: any[];
  template_styles: any[];
  orientations?: string[];
  templates: any[];
  voice_options: any[];
  available_voices: any[];
  show_music_checkbox: boolean;
  show_voiceover_checkbox: boolean;
}

interface IProps {
  controls: any;
  setControls: React.Dispatch<React.SetStateAction<any>>;
  showTitle: boolean;
  contentOptions: ContentOptions;
}

function Video(
  {
    controls, setControls, showTitle, contentOptions,
}: IProps) {
  const authContext = useContext(AuthContext);

  const setStylePrompt = (stylePrompt: string) => {
    setControls({
      ...controls,
      stylePrompt,
    });
  };

  const setVoiceover = (voiceover: boolean) => {
    setControls({
      ...controls,
      voiceover,
    });
  };

  const setMusic = (music: boolean) => {
    setControls({
      ...controls,
      music,
    });
  };

  const setVoiceType = (voiceType: string) => {
    setControls({
      ...controls,
      voiceType,
    });
  };

  const setOrientation = (orientation: string) => {
    setControls({
      ...controls,
      orientation,
    });
  };

  const setTemplateStyle = (templateStyle: string) => {
    setControls({
      ...controls,
      templateStyle,
    });
  };

  const setTemplateId = (templateId: string) => {
    setControls({
      ...controls,
      templateId,
    });
  };

  const setVoiceId = (voiceId: string) => {
    setControls({
      ...controls,
      voiceId,
    });
  };

  return (
    <div className={styles.controlContainer}>
      {showTitle ? (
        <>
          <label className={styles.label} htmlFor="video-input">For Video</label>
          <Spacer y={3} />
        </>
      ) : null}
      <textarea
        value={controls.stylePrompt}
        className="resize-none w-full outline-none border border-secondary border-opacity-20 p-4 text-secondary rounded-2xl"
        style={{ background: 'transparent' }}
        placeholder="Input Prompt to indicate style, tone"
        onChange={(e) => setStylePrompt(e.target.value)}
      />
      {contentOptions.show_voiceover_checkbox ? (
        <>
          <Spacer y={4} />
          <Checkbox
            defaultSelected
            radius="sm"
            color="primary"
            classNames={{
              icon: ['text-secondary'],
              label: ['text-small', 'text-secondary text-opacity-80'],
            }}
            isSelected={controls.voiceover}
            onValueChange={setVoiceover}
          >
            Voiceover
          </Checkbox>
          <Spacer y={4} />
          <div className="w-full p-4 border border-secondary border-opacity-20 rounded-2xl">
            <div className="flex gap-2.5 overflow-auto">
              {contentOptions.voice_options.map((voice: any) => (
                <div
                  key={voice.id}
                  className={`${styles.genderButton} ${controls.voiceType === voice.id ? 'bg-tertiary text-primary' : ''}`}
                  onClick={() => {
                    setVoiceType(voice.id);
                  }}
                >
                  {voice.label}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
      {contentOptions.show_music_checkbox ? (
        <>
          <Spacer y={4} />
          <Checkbox
            defaultSelected
            radius="sm"
            classNames={{
              icon: ['text-secondary'],
              label: ['text-small', 'text-secondary text-opacity-80'],
            }}
            isSelected={controls.music}
            onValueChange={setMusic}
          >
            Add music
          </Checkbox>
        </>
      ) : null}
      {(contentOptions.orientations && contentOptions.orientations.length > 0) ? (
        <>
          <Spacer y={4} />
          <Select
            classNames={{
              label: ['!text-secondary !text-opacity-80'],
              selectorIcon: ['!text-primary'],
              value: ['!text-primary'],
            }}
            labelPlacement="outside"
            label="Orientation"
            placeholder="Select"
            className="max-w-xs"
            selectedKeys={new Set(controls.orientation ? [controls.orientation] : [])}
            onChange={(e) => setOrientation(e.target.value)}
            startContent={controls.orientation ? (
              <Icon
                icon={
                  controls.orientation === 'portrait' ? (
                    'tabler:rectangle-vertical'
                  ) : controls.orientation === 'landscape' ? (
                    'fluent:rectangle-landscape-12-regular'
                  ) : 'fluent:square-12-regular'
                }
                fontSize="1.5rem"
                color="#475467"
              />
            ) : null}
          >
            {contentOptions.orientations.map((orientation) => {
              const isComingSoon = orientation !== 'portrait';
              const label = orientation === 'portrait' ? 'Portrait (9:16)' : orientation === 'landscape' ? 'Landscape (16:9) - Coming Soon' : 'Square (1:1) - Coming Soon';
              const icon = orientation === 'portrait' ? 'tabler:rectangle-vertical' : orientation === 'landscape' ? 'fluent:rectangle-landscape-12-regular' : 'fluent:square-12-regular';
              const textColor = isComingSoon ? 'text-gray-400' : 'text-primary';
              const cursorStyle = isComingSoon ? 'cursor-not-allowed' : 'cursor-pointer';

              return (
                <SelectItem
                  classNames={{
                    title: [textColor],
                  }}
                  key={orientation}
                  value={isComingSoon ? '' : orientation}
                  startContent={<Icon icon={icon} color="#475467" fontSize={16} />}
                  style={{ pointerEvents: isComingSoon ? 'none' : 'auto', opacity: isComingSoon ? 0.75 : 1 }}
                  className={cursorStyle}
                >
                  {label}
                </SelectItem>
              );
            })}
          </Select>
        </>
      ) : null}
      <Spacer y={4} />
      {contentOptions.template_styles.length > 0 ? (
        <>
          <div className="text-secondary text-opacity-80 text-small">Choose Style</div>
          <Spacer y={1.5} />
          <div
            className="flex gap-3"
            style={{ overflowX: 'scroll', WebkitOverflowScrolling: 'touch', scrollBehavior: 'smooth' }}
          >
            {contentOptions.template_styles.map((tStyle: any) => (
              <div key={tStyle.id} className="flex-shrink-0 w-[100px]">
                <button
                  onClick={() => setTemplateStyle(tStyle.id)}
                  className={`${controls.templateStyle === tStyle.id ? 'border-4 border-tertiary' : ''} rounded-xl relative`}
                >
                  <Image
                    className="rounded-md"
                    width={120}
                    alt={tStyle.label}
                    src={tStyle.image}
                  />
                  <div
                    className="text-white text-small absolute rounded-lg flex items-center justify-center w-full h-full top-0 z-10"
                    style={{ backgroundColor: 'rgba(0,0,0,0.6)', overflowX: 'hidden', overflowY: 'hidden' }}
                  >
                    {tStyle.label}
                  </div>
                </button>
              </div>
            ))}
          </div>
        </>
      ) : null}
      {contentOptions.available_voices?.length > 0 ? (
        <>
          <Spacer y={6} />
          <Select
            classNames={{
              label: ['!text-secondary !text-opacity-80'],
              selectorIcon: ['!text-primary'],
              value: ['!text-primary'],
            }}
            labelPlacement="outside"
            label="Choose Voice"
            placeholder="Select"
            className="max-w-xs"
            selectedKeys={new Set(controls.voiceId ? [controls.voiceId] : [])}
            onChange={(e) => setVoiceId(e.target.value)}
          >
            {contentOptions.available_voices?.map((voice) => (
              <SelectItem
                classNames={{
                  title: ['text-primary'],
                }}
                key={voice.id}
                value={voice.id}
              >
                {voice.label}
              </SelectItem>
            ))}
          </Select>
        </>
      ) : null}
      {authContext.currentUser?.email && (authContext.currentUser.email.includes('@lica.world') || authContext.currentUser.email.includes('queencytabligan15@gmail.com') || authContext.currentUser.email.includes('anugyascontact@gmail.com') || (authContext.currentUser.email.includes('agnieszka.m.szefer@gmail.com'))) && (
        <>
          <Spacer y={6} />
          <Select
            classNames={{
              label: ['!text-secondary !text-opacity-80'],
              selectorIcon: ['!text-primary'],
              value: ['!text-primary'],
            }}
            labelPlacement="outside"
            label="Choose Template ID"
            placeholder="Select"
            className="max-w-xs"
            selectedKeys={new Set(controls.templateId ? [controls.templateId] : [])}
            onChange={(e) => setTemplateId(e.target.value)}
          >
            {contentOptions.templates.map((template) => (
              <SelectItem
                classNames={{
                  title: ['text-primary'],
                }}
                key={template}
                value={template}
              >
                {template}
              </SelectItem>
            ))}
          </Select>
        </>
      )}
    </div>
  );
}

export default Video;
