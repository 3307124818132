// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { forwardRef } from "react";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

const StyledSVG = styled(ReactSVG)`
  & div svg {
      width: 100%;
      height: 100%;
  }
`;

const SVG = forwardRef((props: any, ref) => {
	return (
		<div
			ref={ref}
			style={props.style}
			onClick={props.onClick}
		>
			<StyledSVG src={props.src} />
		</div>
	);
});

SVG.displayName = "SVG";

export default SVG;
