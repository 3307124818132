import { ForwardedRef, forwardRef } from "react";
import { Img } from "remotion";

const Image = forwardRef((props: any, ref: ForwardedRef<any>) => {
	return (
		<Img
			ref={ref}
			src={props.src}
			style={props.style}
			onClick={props.onClick}
		/>
	);
});

Image.displayName = "Image";

export default Image;
