import {
  ForwardedRef, forwardRef, useEffect, useState,
} from 'react';
import {
  cancelRender,
  continueRender,
  delayRender,
  getRemotionEnvironment, Loop,
  OffthreadVideo,
  useVideoConfig,
  Video,
} from 'remotion';
import { getVideoMetadata } from '@remotion/media-utils';

const LoopedOffthreadVideo = forwardRef((props: any, ref: ForwardedRef<any>) => {
  const [duration, setDuration] = useState<null | number>(null);
  const [handle] = useState(() => delayRender());
  const { fps } = useVideoConfig();

  useEffect(() => {
    getVideoMetadata(props.src)
      .then(({ durationInSeconds }) => {
        setDuration(durationInSeconds);
        continueRender(handle);
      })
      .catch(() => {
        cancelRender(handle);
      });
  }, [handle]);

  if (duration === null) {
    return null;
  }

  return (
    <Loop
      durationInFrames={Math.floor(fps * duration)}
      style={props.style}
    >
      <OffthreadVideo
        src={props.src}
        startFrom={props.startFrom}
        transparent={false}
        className="object-cover w-full"
        volume={props.volume || 0}
      />
    </Loop>
  );
});

const RemotionVideo = forwardRef((props: any, ref: ForwardedRef<any>) => {
  const { isPlayer } = getRemotionEnvironment();

  if (isPlayer) {
    return (
      <Video
        ref={ref}
        src={props.src}
        startFrom={props.startFrom}
        style={props.style}
        loop
        {...props}
      />
    );
  }

  if (props.loop) {
    return (
      <LoopedOffthreadVideo
        src={props.src}
        startFrom={props.startFrom}
        transparent={false}
        style={props.style}
        {...props}
      />
    );
  }

  return (
    <OffthreadVideo
      src={props.src}
      startFrom={props.startFrom}
      transparent={false}
      style={props.style}
      {...props}
    />
  );
});

RemotionVideo.displayName = 'Video';

export default RemotionVideo;
