import React, { useCallback, useMemo } from 'react';
import { Button } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { PlayerRef } from '@remotion/player';

import useWindowDimensions from '@/hooks/useWindowDimensions';

interface ControlBarProps {
  data: any;
  isPlaying: boolean;
  isDownloading: boolean;
  isProcessing: boolean;
  playerRef: React.RefObject<PlayerRef>;
  handleDownloadClick: () => void;
  handleSaveClick: () => void;
  isSaveButtonEnabled: () => boolean;
}

const ControlBar: React.FC<ControlBarProps> = ({
  data,
  isPlaying,
  isDownloading,
  isProcessing,
  playerRef,
  handleDownloadClick,
  handleSaveClick,
  isSaveButtonEnabled,
}) => {
  const { width } = useWindowDimensions();
  const durationMinutes = useMemo(
    () => Math.ceil(data.duration / 60),
    [data.duration],
  );
  const listenText = `${durationMinutes}-Minute Listen`;

  const playPodcast = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;

    if (player.isPlaying()) {
      player.pause();
    } else {
      player.play();
    }
  }, [playerRef]);

  return (
    <div className="w-full border border-secondary border-opacity-40 justify-between rounded-full px-2 pt-1 flex sm:gap-0 gap-2 items-center">
      <div className="p-2 flex items-center sm:gap-0 gap-3">
        <Icon
          icon={isPlaying ? 'solar:pause-circle-bold' : 'solar:play-circle-bold'}
          className="w-10 h-10 cursor-pointer text-tertiary"
          onClick={playPodcast}
          aria-label={isPlaying ? 'Pause Podcast' : 'Play Podcast'}
        />
        {width > 600 && <div className="p-2 font-normal sm:text-small text-secondary">{listenText}</div>}
      </div>
      <div className="p-2 flex gap-3">
        <Button
          className="text-small bg-tertiary text-primary"
          radius="full"
          startContent={!isDownloading ? <Icon icon="mdi:download" className="text-base" /> : null}
          isLoading={isDownloading}
          onClick={handleDownloadClick}
        >
          Download
        </Button>
        <Button
          radius="full"
          className="text-small bg-tertiary text-primary"
          onClick={handleSaveClick}
          startContent={isProcessing ? null
            : <Icon icon="material-symbols:check-circle-outline" style={{ fontSize: '1.5rem' }} />}
          isLoading={isProcessing}
          isDisabled={!isSaveButtonEnabled() || isProcessing}
        >
          {isProcessing ? 'Updating' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default ControlBar;
