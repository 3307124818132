import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { auth } from '@/auth/firebase';
import Button from '../components/Button';

function ChromeLogin() {
  globalThis.addEventListener('message', async ({ data }) => {
    if (data.initAuth) {
      await signInWithPopup(auth, new GoogleAuthProvider()).then((result) => {
        globalThis.parent.self.postMessage(
          JSON.stringify(result),
          document.location.ancestorOrigins[0],
        );
      });
    }
  });

  return (
    <div className="h-full w-full flex justify-center items-center">
      <Button onClick={() => {}}>Login</Button>
    </div>
  );
}

export default ChromeLogin;
