import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const prodFirebaseConfig = {
  apiKey: 'AIzaSyBAFBsFT6lkF-5XahiSQ-wr02kHG27vSJo',
  authDomain: 'beta.lica.world',
  projectId: 'lica-398204',
  storageBucket: 'lica-398204.appspot.com',
  messagingSenderId: '966626883797',
  appId: '1:966626883797:web:836487839dc5c2c1f6ca5c',
  measurementId: 'G-ZQ0K0TDMRS',
};

const stagingFirebaseConfig = {
  apiKey: 'AIzaSyDjfcMpQTcZEg38FyykJJ3g964T5mw6z-Q',
  authDomain: 'staging.lica.world',
  projectId: 'lica-staging',
  storageBucket: 'lica-staging.appspot.com',
  messagingSenderId: '181777934834',
  appId: '1:181777934834:web:f8668dbe164ec29f51a36b',
  measurementId: 'G-MT3PQKRJ9Q',
};

const app = initializeApp(
  import.meta.env.MODE === 'staging' ? stagingFirebaseConfig : prodFirebaseConfig,
);
const auth = getAuth(app);

export {
  auth,
};
