import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { loadFonts } from "../fonts";
import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import { useGsapTimeline } from "../animation/gsap";

gsap.registerPlugin(SplitText, MotionPathPlugin);

loadFonts();


const Circular = forwardRef((props: any, ref) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const [radius, setRadius] = useState(20);
    const { text, fontSize = 16, style = {} } = props;

    useImperativeHandle(ref, () => innerRef.current);

    useEffect(() => {
        const baseRadius = 20;
        const length = text.length;
        // Estimate the circumference needed for the text
        const estimatedTextWidth = length * parseInt(fontSize) * 0.5;
        const estimatedRadius = estimatedTextWidth / (2 * Math.PI);

        // Set radius, with a minimum radius of 45
        setRadius(Math.max(baseRadius, estimatedRadius));
    }, [text, fontSize]);

    useGsapTimeline(() => {
        const gsapTimeline = gsap.timeline({ repeat: -1 });
        gsapTimeline.to(svgRef.current, {
            rotation: 360,
            duration: 15,
            ease: "none",
            transformOrigin: "center center",
        });
        return gsapTimeline;
    });

    return (
        <div
            ref={innerRef}
            style={{
                lineHeight: "normal",
                ...style,
            }}
        >
            <svg
                ref={svgRef}
                version="1.1"
                height={250}
                width={200}
                className="testt"
                viewBox={`-${radius + 5} 0 ${radius * 2 + 10} ${radius * 2 + 10}`}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <defs>
                    <path
                        fill="none"
                        stroke="#FF9800"
                        id="textPath03"
                        d={`M1,${radius * 2} A${radius},${radius} 0 10 0 ${radius * 2},5`}
                    />
                </defs>
                <text
                    fontSize={fontSize}>
                    <textPath xlinkHref="#textPath03">
                        {text}
                    </textPath>
                </text>
            </svg>
        </div >
    );
});

Circular.displayName = "Circular";

export default Circular;

