import React, { useEffect, useState } from 'react';
import { Button, Spacer } from '@nextui-org/react';

import useFetch from '@/hooks/useFetch';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import { downloadContent } from '@/services/content';
import ChatBox from '@/pages/v3/edit-content/components/chatbox';

import styles from './index.module.css';

export function EditVideoChatNew({ videoId, responseData }: {
  videoId: string;
  responseData: any
}) {
  const [internalVideoData, setInternalVideoData] = useState(responseData.output);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [messages, setMessages] = useState<any>([
    {
      id: 1,
      image: '',
      receiver: 'Lica',
      text: `Hello, ${responseData.user_info?.name}. Let me know how you want the video to be edited, and I will make those edits for you.`,
    },
  ]);

  const { fetchData: updateVideo } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/update`,
    { method: 'POST' },
  );
  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/`,
    { method: 'GET' },
  );

  const fetchContent = async () => {
    try {
      const contentResponseData = await getContent();

      if (contentResponseData?.data?.update_status === 'completed') {
        setInternalVideoData(contentResponseData.data.output);
        setIsProcessing(false);

        setMessages((currentMessages: any) => ([
          ...currentMessages,
          {
            id: currentMessages.length + 1,
            image: '',
            receiver: 'Lica',
            text: 'The video is updated! Is there anything else you would like to change?',
          },
        ]));
      } else if (contentResponseData?.data?.update_status === 'failed' || !contentResponseData?.data?.update_status) {
        setIsProcessing(false);

        setMessages((currentMessages: any) => ([
          ...currentMessages,
          {
            id: currentMessages.length + 1,
            image: '',
            receiver: 'Lica',
            text: "I'm facing some issues while making the changes. Could you please try again?",
          },
        ]));
      } else if (['initiated', 'processing'].includes(contentResponseData?.data?.update_status)) {
        setTimeout(() => {
          fetchContent();
        }, 5000);
      }
    } catch (err) { /* empty */ }
  };

  useEffect(() => {
    if (['initiated', 'processing'].includes(responseData?.update_status)) {
      setIsProcessing(true);

      setTimeout(() => {
        fetchContent();
      }, 5000);
    }
  }, []);

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);
    await downloadContent(internalVideoData.video_data);
    setIsDownloading(false);
  };

  const handleVideoUpdate = async (prompt: string) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const payload = {
      prompt,
    };

    await updateVideo({
      body: JSON.stringify(payload),
    });

    await fetchContent();
  };

  const handleSend = async (inputValue: string) => {
    if (inputValue.trim() !== '') {
      setMessages((currentMessages: any) => ([
        ...currentMessages,
        {
          id: currentMessages.length + 1,
          image: '',
          sender: responseData.user_info?.name || 'User',
          text: inputValue,
        },
      ]));
      await handleVideoUpdate(inputValue);
    }
  };

  return (
    <div className={styles.editContainer}>
      <div className={styles.videoContainer}>
        <div className="flex items-center w-full">
          <div
            className="text-secondary"
            style={{
              fontSize: '1.5rem',
              fontWeight: 800,
            }}
          >
            {responseData.output.title}
          </div>
        </div>
        <Spacer y={3} />
        <div className="flex flex-col flex-auto items-center w-full">
          <div
            style={{
              aspectRatio:
                  internalVideoData.video_data.width / internalVideoData.video_data.height,
            }}
            className="rounded-xl flex-auto max-w-full max-h-full"
          >
            <RemotionPlayer
              data={internalVideoData.video_data}
              clickToPlay={false}
            />
          </div>
        </div>
        <Spacer y={6} />
        <div className="flex items-center justify-center gap-4">
          <Button
            radius="full"
            size="md"
            className="text-primary !bg-tertiary"
            onClick={handleDownloadClick}
            isLoading={isDownloading}
          >
            Download video
          </Button>
        </div>
      </div>
      <div className={styles.slideContainer}>
        <ChatBox
          messages={messages}
          handleSend={handleSend}
          isProcessing={isProcessing}
        />
      </div>
    </div>
  );
}
